import {apiUrl, _handleResponse, _getHeaders} from './apiUtils';

export const fetchBlendedDatasets = datasetId => {
	return fetch(`${apiUrl}${datasetId ? `/datasets/${datasetId}` : ''}/blendedDatasets`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export const generateBlendedDataset = (datasetId, snapshotDate) => {
	return fetch(`${apiUrl}/blendedDatasets`, {
		credentials: 'include',
		headers: _getHeaders(),
		method: 'post',
		body: JSON.stringify({
			datasetId,
			snapshotDate,
		}),
	}).then(_handleResponse);
};

export default {
	fetchBlendedDatasets,
	generateBlendedDataset,
};
