// Globals
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import _cloneDeep from 'lodash/cloneDeep';
import _set from 'lodash/set';
import _get from 'lodash/get';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _concat from 'lodash/concat';

// Project imports
import KiButton from 'components/KiButton';
import KiModal2 from 'components/KiModal2';
import {default as Select} from 'components/KiSelect';
import {useMergedState} from 'utils/customHooks';
import {fetchFundingVehicleSettings} from 'api/fundingVehiclesApi';
import expressionUtil from 'ki-common/utils/expressionUtil';
import debtOptions from 'ki-common/options/debt';
import debtUtils from 'ki-common/utils/debtUtils';
import {splitFundingVehicleSettingsByType} from 'ki-common/utils/fundingVehicleUtil';
import {columnServiceApi} from 'api';

// Local imports
import styles from './KiFormulaBuilder.theme.scss';
import CaseThenElseBlock from './components/CaseThenElseBlock';
import NumericBlock from './components/NumericBlock';
import FormulaBuilderContext from './FormulaBuilderContext';
import DateFormulaFields from 'components/ColumnPicker/forms/components/DateFormulaFields';

/*
isDebtFormula: PropTypes.boolean,
isConditional: PropTypes.boolean,
isWaterfall: PropTypes.boolean,
outputFormat: PropTypes.string,

formulaArray - List of the parts making up the actual formula,
formulaFVArray - List of FV ID's in use by this formula

allFundingVehicles - List of all the FV's available by dataset,
usedFundingVehicles - List of all the FV's in the dataset currently assigned formulas,

onCancel: PropTypes.func,
onApply: should have signature function(formulaFVArray, forumulaArray, formulaEntityList),
 */

/*
{type: 'column', value: columnA},
{type: 'operator', value: '+'},
{type: 'constant', value: '5'},
{type: 'min', value: [columnA, columnB, columnC]},
{type: 'max', value: [columnA, columnB]},
*/

/**
 * [KiFormulaBuilder description]
 * @param {boolean} options.isDate              is this a date calculation?
 * @param {[type]} options.dateFunctionType     dateDiff|dateParse|dateAdd
 * @param {[type]} options.isDebtFormula       [description]
 * @param {[type]} options.isConditional       [description]
 * @param {[type]} options.isWaterfall         [description]
 * @param {[type]} options.datasetId           [description]
 * @param {[type]} options.columnId            If this is an edit of an existing column, this will be populated
 * @param {[type]} options.outputFormat        [description]
 * @param {[type]} options.formulaArray        [description]
 * @param {[type]} options.formulaFVArray      [description]
 * @param {[type]} options.allFundingVehicles  [description]
 * @param {[type]} options.usedFundingVehicles [description]
 * @param {[type]} options.onCancel            [description]
 * @param {[type]} options.onApply             [description]
 * @param {[type]} options.                    [description]
 */
function KiFormulaBuilder({
	isDebtFormula,
	isDate,
	dateFunctionType,
	isConditional,
	isWaterfall,
	datasetId,
	columnId,
	outputFormat,
	formulaArray,
	formulaFVArray,
	allFundingVehicles,
	usedFundingVehicles,
	formulaEntityArray,
	calcEntityLevel,
	usedEntitiesByFV,
	onCancel,
	onApply,
}) {
	//const [builderContext, setBuilderContext] = useContext(FormulaBuilderContext);
	const [formula, setFormula] = useState(formulaArray);
	const [framework, setFramework] = useState([]);
	const [fvList, setFVList] = useState(formulaFVArray);
	const usableFVs = allFundingVehicles.filter(({_id}) => fvList.includes(_id) || !usedFundingVehicles.includes(_id));
	const [entityList, setEntityList] = useState(formulaEntityArray);
	const [errors, setErrors] = useState([]);

	// Fetched Values
	const [actualColumnList, setActualColumnList] = useState([]);
	const [systemColumnList, setSystemColumnList] = useState([]);

	const [entities, setEntities] = useMergedState({
		tranches: [],
		creditSupports: [],
		fees: [],
		// TODO trigger
	});
	const [usedEntities, setUsedEntities] = useState([]);

	const entityName = _get(
		debtOptions.entityOptions.find(opt => opt.value === calcEntityLevel),
		'label',
		calcEntityLevel
	);

	const setFrameworkAndFormula = () => {
		if (isDate) {
			setFormula(formula);
			setFramework(formula);
			return;
		}
		let mappedFormula = [];
		// If the array is length 0 then populate defaults
		if (formulaArray.length === 0) {
			if (isConditional) {
				// Default conditional
				mappedFormula = [
					{type: 'constant', value: ''},
					{type: 'operator', value: '=='},
					{type: 'constant', value: ''},
					{type: 'operator', value: '?'},
					{type: 'constant', value: ''},
					{type: 'operator', value: ':'},
					{type: 'constant', value: ''},
				];
			} else {
				// Default numeric
				mappedFormula = [{type: 'constant', value: ''}];
			}
		} else {
			mappedFormula = formula;
		}
		setFormula(mappedFormula);
		const framework = isConditional
			? expressionUtil.formulaToDisplayFramework(mappedFormula)
			: expressionUtil.numericFormulaToDisplayFramework(mappedFormula);
		setFramework(framework);
	};

	// On Mount
	useEffect(() => {
		setFrameworkAndFormula();
	}, []);

	// On prop.formulaArray change
	useEffect(
		() => {
			setFrameworkAndFormula();
		},
		[formulaArray]
	);

	// If the funding vehicle changes re-fetch Fees, Credit Supports, and Tranches (debt)
	useEffect(
		() => {
			async function fetchData() {
				const res = await fetchFundingVehicleSettings(fvList[0]);
				const splitSettings = splitFundingVehicleSettingsByType(res);

				const params = {
					sources: {
						includeTriggerColumns: true,
					},
					options: {
						circularCheckId: columnId,
					},
				};
				const triggers = await columnServiceApi.getColumnsFromService(datasetId, params);

				setEntities({
					tranches: splitSettings.debt,
					creditSupports: splitSettings.creditSupports,
					fees: splitSettings.fees,
					triggers,
				});

				// Only ever one FV
				setUsedEntities(_get(usedEntitiesByFV, fvList[0], []));
			}
			if (!_isEmpty(fvList)) {
				fetchData();
			}
		},
		[fvList]
	);

	// Get column list
	useEffect(
		() => {
			async function fetchData() {
				let params;
				if (isDate) {
					params = {
						sources: {
							includeDateColumns: true,
							includeFundingVehicle: true,
						},
						options: {
							convertAssetDateColumnsToFVDate: true,
						},
					};
				} else if (isWaterfall) {
					params = {
						sources: {
							includeWaterfallCalculations: true,
							includeWaterfallVariableColumns: true,
							includeDebtCalculations: {
								entityTypes: ['fundingVehicle'],
							},
							includeTriggerColumns: true,
							includeDebtInputs: {
								entityTypes: ['fundingVehicle'],
							},
							includeTrancheCalcAggregateColumns: true,
							includeCreditSupportCalcAggregateColumns: true,
							includeFeeCalcAggregateColumns: true,
							includeAbsIndexColumns: true,
						},
					};
				} else {
					params = debtUtils.getColServiceParamsForView(calcEntityLevel, {isFormula: true});
				}
				_set(params, 'options.circularCheckId', columnId);
				_set(params, 'options.debtInputsExcludeMappedColumns', true);
				const res = await columnServiceApi.getColumnsFromService(datasetId, params);
				setActualColumnList(res);
			}
			fetchData();
		},
		[datasetId]
	);

	useEffect(
		() => {
			async function fetchData() {
				// Fetch all the debt input columns
				const params = {
					sources: {
						includeWaterfallFormulaColumns: {
							fundingVehicleId: fvList[0],
						},
					},
					options: {
						circularCheckId: columnId,
					},
				};
				const waterfallSystemCols = await columnServiceApi.getColumnsFromService(datasetId, params);
				setSystemColumnList(waterfallSystemCols);
			}
			if (isWaterfall) {
				fetchData();
			}
		},
		[entities]
	);

	// Only used in conditional formulas
	const handleAddCaseClick = () => {
		const addCaseThenFormula = [
			{type: 'constant', value: ''},
			{type: 'operator', value: '=='},
			{type: 'constant', value: ''},
			{type: 'operator', value: '?'},
			{type: 'constant', value: ''},
		];
		const addCaseThenFramework = expressionUtil.formulaToDisplayFramework(addCaseThenFormula);
		const newFramework = _cloneDeep(framework); // Prevent mutation
		const elseElement = newFramework.pop();
		// Add the two elements into the framework
		addCaseThenFramework.forEach(entry => {
			newFramework.push(entry);
		});
		// Add the else element back on
		newFramework.push(elseElement);
		setFramework(newFramework);
	};

	// Funding Vehicle Helpers - Start
	const getFVValue = () => {
		return allFundingVehicles.filter(fv => fvList.includes(`${fv._id}`));
	};

	const getFVOptions = () => {
		let opts = [];
		if (isDebtFormula && calcEntityLevel !== 'fundingVehicle') {
			// Debt calcs that are not FV entities can have the same FV with different entities pairs
			opts.push(...allFundingVehicles);
		} else if (allFundingVehicles.some(x => !fvList.includes(x._id))) {
			// Waterfall and Debt FV entity can only have one formula per entity
			opts.push(...usableFVs);
		}

		if (isDebtFormula) {
			opts = opts.filter(fv => !fv.isUnencumbered && fv.fvName !== 'Unencumbered');
		}

		return opts;
	};

	const handleFVChange = fundingVehicle => {
		setFVList([`${fundingVehicle._id}`]);
	};
	// Funding Vehicle Helpers - End

	// Entity Helpers
	const getEntityOptions = () => {
		const opts = [];

		// No options for FV
		if (calcEntityLevel === 'fundingVehicle') {
			return [];
		}

		// If all was selected then make that the only option
		if (entityList.includes('all')) {
			return [{name: 'All', _id: 'all'}];
		}

		// If all is selected then there should be no options to select
		if (usedEntities.includes('all')) {
			return [];
		}

		// If no other entities are used and all or none are selected
		if (usedEntities.length === 0 && (entityList.length === 0 || entityList.includes('all'))) {
			opts.push({name: 'All', _id: 'all'});
		}

		// Get entity list by type, entries are not used by other formulas in the calc
		// but includes entries used in this formula
		let usableEntities = _get(entities, `[${calcEntityLevel}s]`, []);
		usableEntities = usableEntities.filter(({_id}) => entityList.includes(_id) || !usedEntities.includes(_id));

		opts.push(...usableEntities);
		return opts;
	};

	const getEntityValue = () => {
		const matches = getEntityOptions().filter(entity => entityList.includes(`${entity._id}`));
		return matches;
	};

	const handleEntityChange = entitySelections => {
		if (entitySelections) {
			setEntityList(entitySelections.map(entity => `${entity._id}`));
		} else {
			setEntityList([]);
		}
	};
	// Entity Helpers - End

	const handleConfirmClick = () => {
		if (isDate) {
			if (!fvList.length) {
				return setErrors(['You must select at least one fundingVehicle']);
			}
			return onApply(framework, fvList, entityList);
		}
		const formula = isConditional
			? expressionUtil.displayFrameworkToFormula(framework)
			: expressionUtil.displayFrameworkToNumericFormula(framework);
		const errors = expressionUtil.validateFramework(framework, isConditional);
		if (fvList.length < 1) {
			if (isConditional) {
				errors.main = ['You must select at least one funding vehicle.'];
			} else {
				errors.unshift('You must select at least one funding vehicle.');
			}
		}
		if (_isEmpty(errors)) {
			onApply(formula, fvList, entityList);
		} else {
			setErrors(errors);
		}
	};

	const handleCancelClick = () => {
		onCancel();
	};

	const updateFramework = (id, newValue) => {
		const index = framework.findIndex(entry => entry.id === id);
		if (index > -1) {
			const newFramework = _cloneDeep(framework);
			_set(newFramework, `[${index}].framework`, newValue);
			setFramework(newFramework);
		}
	};

	const deleteCase = id => {
		const index = framework.findIndex(entry => entry.id === id);
		if (index > -1) {
			const newFramework = _cloneDeep(framework);
			newFramework.splice(index, 2);
			setFramework(newFramework);
		}
	};

	const swapCases = (indexA, indexB) => {
		const newFramework = _cloneDeep(framework);
		// Swap the two cases statements
		let temp = newFramework[indexA];
		newFramework[indexA] = newFramework[indexB];
		newFramework[indexB] = temp;
		// Swap the two THEN statements
		temp = newFramework[indexA + 1];
		newFramework[indexA + 1] = newFramework[indexB + 1];
		newFramework[indexB + 1] = temp;
		setFramework(newFramework);
	};

	const getCaseList = () => {
		const caseList = [];
		framework.forEach((entry, index) => {
			if (entry.element === 'CASE') {
				caseList.push({index: index, id: entry.id});
			}
		});
		return caseList;
	};

	const moveCaseUp = id => {
		const caseList = getCaseList();
		const matchIndex = caseList.findIndex(entry => entry.id == id);
		if (matchIndex !== undefined && matchIndex > 0) {
			const indexA = caseList[matchIndex].index;
			const indexB = caseList[matchIndex - 1].index;
			swapCases(indexA, indexB);
		}
	};

	const moveCaseDown = id => {
		const caseList = getCaseList();
		const matchIndex = caseList.findIndex(entry => entry.id == id);
		if (matchIndex !== undefined && matchIndex < caseList.length - 1) {
			const indexA = caseList[matchIndex].index;
			const indexB = caseList[matchIndex + 1].index;
			swapCases(indexA, indexB);
		}
	};

	const renderFormulaElements = () => {
		if (isConditional) {
			const caseCount = _filter(framework, {element: 'CASE'}).length;
			return framework.map(entry => {
				switch (entry.element) {
					case 'CASE':
					case 'THEN':
					case 'ELSE':
						return (
							<CaseThenElseBlock
								key={entry.id}
								id={entry.id}
								element={entry.element}
								framework={entry.framework}
								onChange={newFramework => {
									updateFramework(entry.id, newFramework);
								}}
								caseCount={caseCount}
								onDeleteCase={() => deleteCase(entry.id)}
								errors={errors[entry.id]}
								moveCaseUp={() => moveCaseUp(entry.id)}
								moveCaseDown={() => moveCaseDown(entry.id)}
							/>
						);
					default:
						return <div>{JSON.stringify(entry, null, 2)}</div>;
				}
			});
		} else {
			return (
				<NumericBlock
					key={'only-numeric-block'}
					framework={framework}
					errors={errors}
					onChange={newFramework => setFramework(newFramework)}
				/>
			);
		}
	};

	const renderFormulaSection = () => {
		// If calcEntityLevel is fundingVehicle nothing required
		// else there must be an entityList entry to continue
		const requiresEntity = calcEntityLevel === 'fundingVehicle' ? false : _isEmpty(entityList);

		// Both debt and waterfall require an FV to be selected
		if ((isDebtFormula || isWaterfall) && _isEmpty(fvList)) {
			return <div className={styles.fvSelectToContinue}>Please select a Funding Vehicle to continue.</div>;
		}

		// Debt calcs require an entity to be selected if it is not fundingVehicle level
		if (isDebtFormula && requiresEntity) {
			return <div className={styles.fvSelectToContinue}>{`Please select a ${entityName} to continue.`}</div>;
		}

		if (isDate) {
			return (
				<div className={styles.worksheet} style={{padding: '1rem'}}>
					<DateFormulaFields
						onChange={({formula}) => setFramework(formula)}
						dateColumns={actualColumnList.filter(col => col.columnType === 'fvDate')}
						dateFunctionType={dateFunctionType}
						formula={formula}
						isDebt={true}
					/>
				</div>
			);
		}

		if (isWaterfall && !_isEmpty(fvList)) {
			return <div className={styles.worksheet}>{renderFormulaElements()}</div>;
		}

		if (isDebtFormula && !_isEmpty(fvList) && !requiresEntity) {
			return <div className={styles.worksheet}>{renderFormulaElements()}</div>;
		}
	};

	const allColumns = _concat(actualColumnList, systemColumnList);

	// Prevent the page from loading until the columns are returned
	// otherwise various checks will fail as things like getDataTypeFromFramework will be
	// called with an emtpy list and give back bad data
	if (allColumns.length === 0) {
		return null;
	}

	return (
		<KiModal2
			active={true}
			actions={[{label: 'Cancel', onClick: handleCancelClick}, {label: 'Apply', onClick: handleConfirmClick}]}
			panelStyles={{minHeight: '75%', maxHeight: '75%', minWidth: '80%', maxWidth: '80%'}}
			className={styles.modal}
			header={isDate ? 'Date Calculation' : isConditional ? 'Conditional Calculation' : 'Numeric Calculation'}
			onClose={handleCancelClick}
		>
			<FormulaBuilderContext.Provider
				value={{
					isDebtFormula,
					isDate,
					isConditional,
					isWaterfall,
					outputFormat,
					allColumns,
				}}
			>
				<section className={styles.main}>
					<div className={styles.controls}>
						{(isDebtFormula || isWaterfall) && (
							<div className={styles.fvControl}>
								<p>Funding Vehicle</p>
								<Select
									isDisabled={!_isEmpty(fvList)}
									isMulti={false}
									value={getFVValue()}
									options={getFVOptions()}
									getOptionLabel={opt => opt.name}
									getOptionValue={opt => opt._id}
									onChange={handleFVChange}
								/>
							</div>
						)}
						{isDebtFormula &&
							calcEntityLevel !== 'fundingVehicle' && (
								<div className={styles.fvControl}>
									<p>{entityName}</p>
									<Select
										isDisabled={_isEmpty(fvList)}
										isMulti={true}
										value={getEntityValue()}
										options={getEntityOptions()}
										getOptionLabel={opt => opt.name}
										getOptionValue={opt => opt._id}
										onChange={handleEntityChange}
									/>
								</div>
							)}
						{isConditional && (
							<KiButton className={styles.caseButton} raised primary onClick={handleAddCaseClick}>
								Add Case Statement
							</KiButton>
						)}
					</div>
					{errors.main && (
						<div className={styles.errors}>{errors.main.map((err, idx) => <p key={idx}>{err}</p>)}</div>
					)}
					{renderFormulaSection()}
				</section>
			</FormulaBuilderContext.Provider>
		</KiModal2>
	);
}

KiFormulaBuilder.propTypes = {
	isDebtFormula: PropTypes.bool,
	isDate: PropTypes.bool,
	isConditional: PropTypes.bool,
	isWaterfall: PropTypes.bool,
	datasetId: PropTypes.string.isRequired,
	columnId: PropTypes.string,
	dateFunctionType: PropTypes.oneOf(['dateDiff', 'dateParse', 'dateAdd']),
	outputFormat: PropTypes.oneOf(['boolean', 'date_long', 'numeric', 'string']),
	formulaArray: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
	formulaFVArray: PropTypes.array,
	allFundingVehicles: PropTypes.array,
	usedFundingVehicles: PropTypes.array,
	formulaEntityArray: PropTypes.array,
	calcEntityLevel: PropTypes.string,
	usedEntitiesByFV: PropTypes.object,
	onCancel: PropTypes.func.isRequired,
	onApply: PropTypes.func.isRequired,
};

KiFormulaBuilder.defaultProps = {
	isDebtFormula: false,
	isDate: false,
	dateFunctionType: 'dateDiff',
	isConditional: false,
	isWaterfall: false,
	outputFormat: 'boolean',
	formulaFVArray: [],
	allFundingVehicles: [],
	usedFundingVehicles: [],
	formulaEntityArray: [],
	usedEntitiesByFV: {},
	onCancel: {},
	onApply: {},
};

export default KiFormulaBuilder;
