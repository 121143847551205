import * as actionTypes from './actionTypes';
import {showSnackbar} from 'state/actions/Snackbar';
import {eligibilityViewsApi, datasetDatesApi} from 'api';
import {dateToShortDate} from 'ki-common/utils/dateHelpers';

const fetchEAViewsByDatasetId = datasetIdParam => (dispatch, getState) => {
	const {
		eligibilityAnalysis: {metadata},
	} = getState();
	return eligibilityViewsApi
		.fetchEAViewsByDatasetId(datasetIdParam || metadata.datasetId)
		.then(data => {
			dispatch({type: actionTypes.ELIGIBILITY_ANALYSIS_FETCH_VIEWS, data});
			return data;
		})
		.catch(error => {
			dispatch(showSnackbar(error.message));
		});
};

const createEligibilityView = eligibilityAnalysis => dispatch => {
	return eligibilityViewsApi
		.createEligibilityView(eligibilityAnalysis)
		.then(data => {
			dispatch({type: actionTypes.ELIGIBILITY_ANALYSIS_CREATE, data});
			dispatch(showSnackbar(`Saved Eligibility Analysis "${eligibilityAnalysis.name}" successfully`));
			dispatch(fetchEAViewsByDatasetId());
			return data;
		})
		.catch(error => {
			dispatch(showSnackbar(error.message));
		});
};

const updateEligibilityView = eligibilityAnalysis => dispatch => {
	return eligibilityViewsApi
		.updateEligibilityView(eligibilityAnalysis)
		.then(data => {
			dispatch({type: actionTypes.ELIGIBILITY_ANALYSIS_UPDATE, data});
			dispatch(showSnackbar(`Updated Eligibility Analysis "${eligibilityAnalysis.name}" successfully`));
			dispatch(fetchEAViewsByDatasetId());
			return data;
		})
		.catch(error => {
			dispatch(showSnackbar(error.message));
		});
};

const setNoDeleteReasons = value => dispatch => {
	dispatch({type: actionTypes.ELIGIBILITY_ANALYSIS_SET_NO_DELETE_REASONS, data: value});
};

const deleteEligibilityView = id => dispatch => {
	return eligibilityViewsApi
		.deleteEligibilityView(id)
		.then(() => {
			dispatch({type: actionTypes.ELIGIBILITY_ANALYSIS_DELETE, id});
			dispatch(showSnackbar(`Deleted Eligibility Analysis successfully`));
			dispatch(fetchEAViewsByDatasetId());
		})
		.catch(error => {
			dispatch(setNoDeleteReasons(error));
		});
};

const getCalculatedDateInfo = (dateContextList, dateContextId, statementDate, datasetId) => {
	const dateContext = dateContextList.find(d => d._id === dateContextId);
	const calculatedDatesRequest = [
		{
			groupId: dateContext.groupId,
			statementDate: statementDate,
		},
	];
	return datasetDatesApi.getCalculatedDates(calculatedDatesRequest, datasetId);
};

const fetchEAReportData = () => (dispatch, getState) => {
	const {eligibilityAnalysis} = getState();
	const statementDate = eligibilityAnalysis.metadata.statementDate || dateToShortDate(new Date());
	if (!eligibilityAnalysis.dateContextList.length) {
		datasetDatesApi.fetchPortfolioDates(eligibilityAnalysis.metadata.datasetId).then(dates => {
			dispatch({type: actionTypes.EA_SET_DATECONTEXT_LIST, data: dates});
			getCalculatedDateInfo(
				dates,
				eligibilityAnalysis.metadata.dateContext,
				statementDate,
				eligibilityAnalysis.metadata.datasetId
			).then(calculatedDates => {
				if (calculatedDates.length) {
					dispatch({type: actionTypes.EA_SET_CALCULATED_DATE, data: calculatedDates[0].calculatedDate});
					dispatch({
						type: actionTypes.EA_SET_DATECONTEXT_NAME,
						data: calculatedDates[0].name,
					});
				}
			});
		});
	} else {
		getCalculatedDateInfo(
			eligibilityAnalysis.dateContextList,
			eligibilityAnalysis.metadata.dateContext,
			statementDate
		).then(calculatedDates => {
			if (calculatedDates.length) {
				dispatch({type: actionTypes.EA_SET_CALCULATED_DATE, data: calculatedDates[0].calculatedDate});
				dispatch({
					type: actionTypes.EA_SET_DATECONTEXT_NAME,
					data: calculatedDates[0].name,
				});
			}
		});
	}
	dispatch({type: actionTypes.ELIGIBILITY_ANALYSIS_FETCH_REPORT_DATA, status: 'pending'});
	return eligibilityViewsApi
		.fetchEAReportData(eligibilityAnalysis.metadata)
		.then(data => {
			dispatch({type: actionTypes.ELIGIBILITY_ANALYSIS_FETCH_REPORT_DATA, status: 'success', data: data});
		})
		.catch(error =>
			dispatch({type: actionTypes.ELIGIBILITY_ANALYSIS_FETCH_REPORT_DATA, status: 'error', error: error})
		);
};

const setPageMetadata = eligibilityAnalysis => dispatch => {
	dispatch({type: actionTypes.ELIGIBILITY_ANALYSIS_SET_METADATA, data: eligibilityAnalysis});
	dispatch(fetchEAReportData());
};

const setMetadataStatementDate = value => dispatch => {
	const statementDate = dateToShortDate(new Date(value));
	dispatch({type: actionTypes.EA_SET_METADATA_STATEMENT_DATE, data: statementDate});
	dispatch(fetchEAReportData());
};

const setMetadataDateContext = value => dispatch => {
	dispatch({type: actionTypes.EA_SET_METADATA_DATE_CONTEXT, data: value});
	dispatch(fetchEAReportData());
};

const setMetadataCriteriaSources = value => dispatch => {
	dispatch({type: actionTypes.EA_SET_METADATA_CRITERIA_SOURCES, data: value});
	dispatch(fetchEAReportData());
};

const setMetadataAssetSources = value => dispatch => {
	dispatch({type: actionTypes.EA_SET_METADATA_ASSET_SOURCES, data: value});
	dispatch(fetchEAReportData());
};

export {
	fetchEAViewsByDatasetId,
	createEligibilityView,
	updateEligibilityView,
	deleteEligibilityView,
	fetchEAReportData,
	setPageMetadata,
	setNoDeleteReasons,
	setMetadataStatementDate,
	setMetadataDateContext,
	setMetadataCriteriaSources,
	setMetadataAssetSources,
};
