// Globals
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useHistory, useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import ReactTable from 'react-table-6';
import moment from 'moment';
import _ from 'lodash';

// Project imports
import KiAppBar from 'components/KiAppBar';
import KiTabs from 'components/KiTabs';
import KiTab from 'components/KiTabs/KiTab';
import ContextSidebar from 'components/ContextSidebar';
import ContextIcons from 'components/ContextSidebar/icons';
import FlyoutManageViews from 'components/FlyoutManageViews';
import FlyoutCalculations from 'components/FlyoutCalculations';
import {poolMaintenanceApi, datasetsApi} from 'api';

// Local imports
import './PoolMaintenance.scss';

function PoolsTable({pools, isLoading}) {
	const simpleTextFilter = (filter, row) => {
		const test = _.get(filter, 'value', '').toLowerCase();
		const rowValue = _.get(row, filter.id, '').toLowerCase();
		return rowValue.includes(test);
	};

	return (
		<ReactTable
			className="data-explorer-table debt-explorer-table funding-models-table"
			showPagination={true}
			sortable={true}
			filterable={true}
			loading={isLoading}
			data={pools}
			defaultSorted={[{id: 'poolStatus', desc: false}, {id: 'transferDate', desc: true}]}
			columns={[
				{Header: 'Pool Identifier', accessor: 'identifier', filterMethod: simpleTextFilter},
				{Header: 'Assignment', accessor: 'fundingVehicleName', filterMethod: simpleTextFilter},
				{
					Header: 'Discount Rate',
					accessor: 'discountRate',
					Cell: params => `${params.value * 100}%`,
				},
				{
					Header: 'Cut-Off Date',
					accessor: 'cutoffDate',
					Cell: params => moment(params.value).format('YYYY-MM-DD'),
					filterMethod: simpleTextFilter,
				},
				{
					Header: 'Transfer Date',
					accessor: 'transferDate',
					Cell: params => moment(params.value).format('YYYY-MM-DD'),
					filterMethod: simpleTextFilter,
					defaultSortDesc: true,
				},
				{
					Header: 'Effective Date',
					accessor: 'effectiveDate',
					Cell: params => moment(params.value).format('YYYY-MM-DD'),
					filterMethod: simpleTextFilter,
				},
				{
					Header: 'Pool Status',
					accessor: 'poolStatus',
					Cell: params => `${params.value[0].toUpperCase()}${params.value.substring(1)}`,
					filterMethod: simpleTextFilter,
				},
			]}
			minRows={1}
		/>
	);
}

PoolsTable.propTypes = {
	pools: PropTypes.array,
	isLoading: PropTypes.bool,
};

PoolsTable.defaultProps = {
	pools: [],
	isLoading: true,
};

function PoolMaintenance() {
	const history = useHistory();

	// Get values from redux store state
	const app = useSelector(state => state.app);
	const user = useSelector(state => state.user);

	const [activePendingPools, setActivePendingPools] = useState([]);
	const [inactivePools, setInactivePools] = useState([]);
	const [dataset, setDataset] = useState({});
	const [tabIndex, setTabIndex] = useState(0);
	const [isLoading, setIsLoading] = useState(true);

	const {datasetId} = useParams();

	// On mount
	useEffect(() => {
		document.title = `${app.kiVersion} - Pool Maintenance`;
	}, []);

	// On datasetId change
	useEffect(
		() => {
			async function fetchNewData() {
				const dataset = await datasetsApi.fetchDataset(datasetId);
				setDataset(dataset);
				const activePools = await poolMaintenanceApi.getActivePools(datasetId);
				const pendingPools = await poolMaintenanceApi.getPendingPools(datasetId);
				setActivePendingPools(activePools.concat(pendingPools));
				const inactivePools = await poolMaintenanceApi.getInactivePools(datasetId);
				setInactivePools(inactivePools);
				setIsLoading(false);
			}
			if (datasetId) {
				fetchNewData();
				setIsLoading(true);
			}
		},
		[datasetId]
	);

	const handleTabChange = index => {
		setTabIndex(index);
	};

	return (
		<div className="container-wrapper">
			<section className="pool-maintenance container-body">
				<KiAppBar className="top-bar">
					<div className="top-bar-breadcrumb">
						<h1 className="link" onClick={() => history.push('/datasets')}>
							Datasets &gt;{' '}
						</h1>
						<h1>{`${dataset.name} > Pool Maintenance`}</h1>
					</div>
				</KiAppBar>
				<div className="pool-maintenance-tab-wrapper">
					<KiTabs index={tabIndex} onChange={handleTabChange}>
						<KiTab title="Active/Pending Pools">
							<PoolsTable pools={activePendingPools} isLoading={isLoading} />
						</KiTab>
						<KiTab title="Inactive Pools">
							<PoolsTable pools={inactivePools} isLoading={isLoading} />
						</KiTab>
					</KiTabs>
				</div>
			</section>
			<ContextSidebar
				items={[
					{
						name: 'Manage Views',
						icon: <ContextIcons.ViewsIcon />,
						element: <FlyoutManageViews />,
					},
					{
						name: 'Calculations',
						icon: <ContextIcons.CalculationsIcon />,
						element: <FlyoutCalculations user={user} />,
					},
				]}
			/>
		</div>
	);
}

export default PoolMaintenance;
