import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import KiCheckbox from 'components/KiCheckbox';
import styles from './FunctionMappingListItem.theme.scss';
import Select from 'react-select';
import _set from 'lodash/set';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import bfData from 'ki-common/businessFunctions';
import calcColumnsUtils from 'ki-common/utils/calcColumns';
import {columnReferencesSelf} from 'ki-common/utils/columnSearchUtil';
import KiInput from 'components/KiInput';
import KiButton from 'components/KiIconButton';
import {KiTooltip} from 'components/KiTooltip';
import classnames from 'classnames';
import _isEqual from 'lodash/isEqual';
import camelCase from 'lodash/camelCase';

const smm = require('/static/images/SMM.png');
const abs = require('/static/images/ABS.png');
const cpr = require('/static/images/CPR.png');
const dpb1 = require('/static/images/DPB2_1.png');
const dpb2 = require('/static/images/DPB2_2.png');
const dpb3 = require('/static/images/DPB2_3.png');
const dpb4 = require('/static/images/DPB2_4.png');
const pymt = require('/static/images/payment-rate.png');
const anrt = require('/static/images/payment-rate.png');

export class FunctionMappingListItem extends Component {
	static propTypes = {
		isAdmin: PropTypes.bool.isRequired,
		bfColumn: PropTypes.object.isRequired,
		assetColumns: PropTypes.array.isRequired,
		saveColumn: PropTypes.func.isRequired,
		deleteColumn: PropTypes.func.isRequired,
		user: PropTypes.object.isRequired,
		bfDefaultMappings: PropTypes.array.isRequired,
		onVariableClick: PropTypes.func.isRequired,
	};

	static defaultProps = {
		bfColumn: {},
		assetColumns: [],
		user: {},
	};

	state = {};

	componentDidMount() {
		this.setFormValues(this.props.bfColumn);
	}

	componentDidUpdate(prevProps) {
		if (!_isEqual(prevProps, this.props)) {
			this.setFormValues(this.props.bfColumn);
		}
	}

	setFormValues = bfColumn => {
		const mappings = Object.keys(bfColumn.formula.left.value).reduce((accumulator, colName) => {
			accumulator[colName] = _get(bfColumn, `formula.left.value.${colName}.value`, '');
			return accumulator;
		}, {});

		this.updateState({
			isActive: false,
			isGlobal: false, // TODO
			displayName: bfColumn.displayName,
			hasPaymentFile: _get(bfColumn, 'formula.meta.hasPaymentFile'),
			isReadyForUse: _get(bfColumn, 'formula.meta.isReadyForUse'),
			mappings,
			isSaveDisabled: true,
		});
	};

	getImage = opName => {
		let imageSrc;
		switch (bfData.businessFunctionList.find(col => col.opType === opName).imageName) {
			case 'dpb':
				return (
					<table className={styles.bfDpbImageTable}>
						<tbody>
							<tr>
								<td>
									<img className={styles.bfImageDpb} src={dpb1} />
									<img className={styles.bfImageDpb} src={dpb2} />
								</td>
								<td>
									<KiTooltip
										className={styles.bfInfoContainer}
										message={'n = number of periods for the last Payment Amount'}
									>
										<i className={styles.bfInfo}>info</i>
									</KiTooltip>
								</td>
							</tr>
							<tr>
								<td colSpan={2}>
									<img className={styles.bfImageDpb} src={dpb3} />
								</td>
							</tr>
							<tr>
								<td colSpan={2}>
									<img className={styles.bfImageDpb} src={dpb4} />
								</td>
							</tr>
						</tbody>
					</table>
				);
			case 'smm':
				imageSrc = smm;
				break;
			case 'abs':
				imageSrc = abs;
				break;
			case 'cpr':
				imageSrc = cpr;
				break;
			case 'pymt':
				imageSrc = pymt;
				break;
			case 'anrt':
				imageSrc = anrt;
				break;
		}
		return <img className={styles.bfImage} src={imageSrc} />;
	};

	// Returns a string representation of the default mapping
	getDefaultMapping = variableDefinition => {
		const {assetColumns, bfDefaultMappings} = this.props;
		const mappedValue = _get(
			bfDefaultMappings.find(mapping => mapping.name === variableDefinition.name),
			'columnId',
			null
		);
		if (mappedValue) {
			if (variableDefinition.options) {
				return mappedValue.label;
			}
			return assetColumns.find(col => col._id === mappedValue).columnName;
		}
		return 'none';
	};

	handleCancel = () => {
		this.setFormValues(this.props.bfColumn);
	};

	getEditedColumn = tempState => {
		const stateObj = tempState ? tempState : this.state;
		const {isGlobal, displayName, mappings, hasPaymentFile} = stateObj;
		// Prevent accidental mapping by reference
		const newBfCol = _cloneDeep(this.props.bfColumn);
		newBfCol.isGlobal = isGlobal;
		newBfCol.displayName = displayName;

		Object.keys(mappings).forEach(variableName => {
			_set(newBfCol, `formula.left.value.${variableName}.value`, mappings[variableName]);
		});

		_set(newBfCol, 'formula.meta.hasPaymentFile', hasPaymentFile);

		return newBfCol;
	};

	handleSave = () => {
		this.props.saveColumn(this.getEditedColumn());
		this.setState({isActive: false});
	};

	updateState = stateChanges => {
		const {bfColumn} = this.props;
		const functionDefinition = bfData.businessFunctionsMap.get(_get(bfColumn, 'formula.op', ''));
		// Create the newly updated state and test the column generated from that before saving
		const newState = {...this.state, ...stateChanges};
		const newColumn = this.getEditedColumn(newState);
		const isReadyForUse = functionDefinition.isReadyForUse(newColumn);
		newState.isReadyForUse = isReadyForUse;
		// If the function started in the ready state then a user cannot save it in an unready state
		// this presents a similar problem to deleting a column that is in use
		newState.isSaveDisabled =
			_get(bfColumn, 'formula.meta.isReadyForUse', false) === true && isReadyForUse === false ? true : false;
		this.setState(newState);
	};

	renderSubTable = () => {
		const {isAdmin, bfColumn, assetColumns} = this.props;
		const editColumn = isAdmin ? this.getEditedColumn() : this.props.bfColumn;

		// Order list with required fields at the top in alphabetical order
		const variableList = Object.keys(bfColumn.formula.left.value).sort((firstVar, secondVar) => {
			const variableDefinition1 = bfData.businessVariablesMap.get(firstVar);
			const isRequired1 = variableDefinition1.isRequired(editColumn);
			const variableDefinition2 = bfData.businessVariablesMap.get(secondVar);
			const isRequired2 = variableDefinition2.isRequired(editColumn);
			if (isRequired1 && !isRequired2) {
				return -1;
			}
			if (!isRequired1 && isRequired2) {
				return 1;
			}

			if (firstVar.toLowerCase() < secondVar.toLowerCase()) {
				return -1;
			}

			if (firstVar.toLowerCase() > secondVar.toLowerCase()) {
				return 1;
			}
			return 0;
		});

		return (
			<table className={styles.mappingFunctionContents}>
				<thead>
					<tr className={styles.mappingFunctionContentsHeader}>
						<th width={'35%'}>
							<p>BUSINESS VARIABLES</p>
						</th>
						<th width={'35%'}>
							<p>MAPPING</p>
						</th>
						<th width={'14%'}>
							<p>DEFAULT</p>
						</th>
						<th width={'15%'}>
							<p>DATA TYPE</p>
						</th>
					</tr>
				</thead>
				<tbody>
					{variableList.map((variableName, index) => {
						const variableDefinition = bfData.businessVariablesMap.get(variableName);
						const isOptional = !variableDefinition.isRequired(editColumn);
						const isVisible = variableDefinition.isVisible(editColumn);
						if (!isVisible) {
							return null;
						}
						const selectColumns = assetColumns.filter(column => {
							return (
								column.dataType === variableDefinition.type &&
								!columnReferencesSelf(assetColumns, column, this.props.bfColumn._id)
							);
						});

						let selectObject;
						const selectedColumn = selectColumns.find(
							col => col._id === _get(bfColumn, `formula.left.value.${variableName}.value`, '')
						);
						if (isAdmin) {
							if (
								['interestAccrual', 'delinquencyBalanceTreatment', 'payaheadBalanceTreatment'].includes(
									variableDefinition.name
								)
							) {
								const options = variableDefinition.options.map(entry => {
									return {
										value: entry.value,
										label: entry.label,
									};
								});
								selectObject = (
									<Select
										classNamePrefix="aut-select"
										isClearable={true}
										options={options}
										className="ki-form-select-horizontal"
										onChange={val => {
											const mappings = Object.assign(this.state.mappings, {
												[variableName]: _get(val, 'value', ''),
											});
											this.updateState({mappings});
										}}
										value={options.find(option => {
											return option.value === this.state.mappings[variableName];
										})}
									/>
								);
							} else {
								selectObject = (
									<Select
										classNamePrefix="aut-select"
										default="Select..."
										getOptionLabel={option => option.displayName}
										getOptionValue={option => option._id}
										isClearable={true}
										options={selectColumns}
										className="ki-form-select-horizontal"
										onChange={val => {
											const mappings = Object.assign(this.state.mappings, {
												[variableName]: val ? val._id : '',
											});
											this.updateState({mappings});
										}}
										value={selectColumns.find(option => {
											return option._id === this.state.mappings[variableName];
										})}
									/>
								);
							}
						} else if (variableDefinition.type === 'option') {
							selectObject = <p>{selectedColumn && selectedColumn.displayName}</p>;
						} else {
							selectObject = <p>{_get(bfColumn, `formula.left.value.${variableName}.value`, '')}</p>;
						}

						return (
							<tr key={index} className={styles.mappingFunctionContentsItem}>
								<td>
									{isAdmin ? (
										<a
											className={styles.variableName}
											href="javascript:void(0)"
											onClick={() => {
												this.props.onVariableClick(variableName);
											}}
										>
											{_get(variableDefinition, 'displayName', variableName)}
											{isOptional && ' (optional)'}
										</a>
									) : (
										<p>{_get(variableDefinition, 'displayName', variableName)}</p>
									)}
								</td>
								<td>{selectObject}</td>
								<td>
									<p>{this.getDefaultMapping(variableDefinition)}</p>
								</td>
								<td>
									{/* interestAccrual has a type of 'option', not 'numeric', but we still want to display numeric */}
									{variableDefinition.name === 'interestAccrual' && <p>numeric</p>}
									{variableDefinition.name !== 'interestAccrual' && <p>{variableDefinition.type}</p>}
								</td>
							</tr>
						);
					})}
					<tr>
						<td />
						<td />
						<td colSpan={2} className={styles.controlsTd}>
							<KiButton
								label="Cancel"
								type="submit"
								iconButton={false}
								flat
								primary
								onClick={this.handleCancel}
							/>
							<KiButton
								label="Save"
								type="submit"
								iconButton={false}
								raised
								primary
								disabled={this.state.isSaveDisabled}
								onClick={this.handleSave}
							/>
						</td>
					</tr>
				</tbody>
			</table>
		);
	};

	render() {
		const {isAdmin, bfColumn, user} = this.props;
		const {isActive, isReadyForUse, hasPaymentFile} = this.state;

		const defaultColumnNames = bfData.businessFunctionList.map(c => camelCase(c.displayName));
		if (isAdmin) {
			return (
				<div>
					<div className={styles.mappingFunctionHeader}>
						<section>
							<i
								className={classnames('material-icons', `${styles.isCompleteIcon}`, {
									[styles.activeCheckmark]: isReadyForUse && !hasPaymentFile,
									[styles.activeOrangeCheckmark]: isReadyForUse && hasPaymentFile,
								})}
							>
								check_circle
							</i>
							{isActive ? (
								<KiInput
									className={styles.bfName}
									label="Name"
									value={this.state.displayName || bfColumn.displayName}
									disabled={!isActive}
									onChange={val => this.updateState({displayName: val})}
								/>
							) : (
								<p className={styles.bfName}>{this.state.displayName || bfColumn.displayName}</p>
							)}
							{this.getImage(bfColumn.formula.op)}
						</section>
						<section>
							{bfColumn.formula.op === 'bf_dpb' && (
								<Fragment>
									<KiCheckbox
										className={styles.checkbox}
										name="hasPaymentFile"
										checked={hasPaymentFile}
										disabled={!isActive}
										onChange={val => {
											this.updateState({hasPaymentFile: val});
										}}
									/>
									<span className={styles.paymentFileLabel}>
										Override Mapping<br /> with Payment File
									</span>
								</Fragment>
							)}
							<KiCheckbox
								className={styles.checkbox}
								name="isGlobal"
								checked={true}
								label="Global"
								disabled={true}
								onChange={val => {
									this.saveColumn('createdBy', val ? '' : user.userId);
								}}
							/>
							<i
								className="material-icons ki-icon-rollover"
								onClick={() => {
									const newCol = Object.assign({}, bfColumn);
									delete newCol._id;
									// TODO for now should always be global
									// newCol.createdBy = this.props.user.userId;
									newCol.displayName = `Copy of ${newCol.displayName}`;
									newCol.columnName = calcColumnsUtils.getNewColumnName(newCol.columnName);
									this.props.saveColumn(newCol);
								}}
							>
								content_copy
							</i>
							<i
								className="material-icons ki-icon-rollover"
								onClick={() => this.updateState({isActive: !this.state.isActive})}
							>
								mode_edit
							</i>
							{!defaultColumnNames.includes(bfColumn.columnName) && (
								<i
									className="material-icons ki-icon-rollover"
									onClick={() => this.props.deleteColumn(bfColumn._id)}
								>
									delete
								</i>
							)}
						</section>
					</div>
					{isActive ? this.renderSubTable() : null}
				</div>
			);
		} else {
			return (
				<div>
					<div className={styles.mappingFunctionHeader}>
						<section>
							{bfColumn.formula.meta.isReadyForUse ? (
								<i className={`material-icons ${styles.isCompleteIcon} ${styles.active}`}>
									check_circle
								</i>
							) : (
								<i className={`material-icons ${styles.isCompleteIcon}`}>check_circle</i>
							)}
							<p className={styles.bfDisplayName}>{bfColumn.displayName}</p>
							{this.getImage(bfColumn.formula.op)}
						</section>
						<section>
							<i
								className="material-icons ki-icon-rollover"
								onClick={() => this.updateState({isActive: !this.state.isActive})}
							>
								{isActive ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
							</i>
						</section>
					</div>
					{isActive ? this.renderSubTable(isAdmin) : null}
				</div>
			);
		}
	}
}

export default FunctionMappingListItem;
