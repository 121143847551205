import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {
	setMetadataCriteriaSources,
	setMetadataAssetSources,
	setMetadataStatementDate,
	setMetadataDateContext,
} from 'containers/eligibilityAnalysis/actions';
import KiSelect from 'components/KiSelect';
import styles from './EligibilityControls.theme.scss';
import KiDatePicker from 'components/KiDatePicker';
import Select from 'react-select';
import {dateToShortDate} from 'ki-common/utils/dateHelpers';

export class EligibilityHeader extends Component {
	static propTypes = {
		eligibilityAnalysis: PropTypes.object.isRequired,
		dataset: PropTypes.object.isRequired,
		fundingVehicleList: PropTypes.array.isRequired,
		setMetadataStatementDate: PropTypes.func,
		setMetadataDateContext: PropTypes.func,
		setMetadataCriteriaSources: PropTypes.func,
		setMetadataAssetSources: PropTypes.func,
		dateContextList: PropTypes.array,
		calculatedDate: PropTypes.string,
		dateContextName: PropTypes.string,
	};

	handleSetStatementDate = date => {
		this.props.setMetadataStatementDate(date);
	};

	handleSetDateContext = option => {
		this.props.setMetadataDateContext(option._id);
	};

	handleSetEligibilityCriteria = newValues => {
		this.props.setMetadataCriteriaSources(newValues);
	};

	handleSetAssetSources = newValues => {
		this.props.setMetadataAssetSources(newValues);
	};

	render() {
		const {eligibilityAnalysis, dataset, fundingVehicleList} = this.props;
		const metadata = eligibilityAnalysis.metadata;

		const eligiblityCriteriaOptions = [
			{
				label: 'Global',
				value: 'global',
			},
		];
		const assetSourceOptions = [
			{
				label: 'Unencumbered',
				value: 'unencumbered',
			},
		];
		fundingVehicleList.forEach(fv => {
			if (fv.datasetId === dataset.datasetId) {
				const option = {
					label: fv.name,
					value: fv._id,
				};
				eligiblityCriteriaOptions.push(option);
				assetSourceOptions.push(option);
			}
		});

		return (
			<div className="ki-panel">
				<div className={styles.root}>
					<section>
						<span className="theme-label">Statement Date:</span>
						<KiDatePicker
							onChange={this.handleSetStatementDate}
							value={metadata.statementDate || dateToShortDate(new Date())}
							className={styles.explorerDatePicker}
						/>
					</section>
					<section>
						<span className="theme-label">Eligibility Criteria:</span>
						<KiSelect
							options={eligiblityCriteriaOptions}
							getOptionValue={option => option.label}
							value={eligiblityCriteriaOptions.reduce((acc, curVal) => {
								if (metadata.criteriaSources.includes(curVal.value)) {
									acc.push(curVal);
								}
								return acc;
							}, [])}
							onChange={options => this.handleSetEligibilityCriteria(options.map(option => option.value))}
							isMulti={true}
							placeholder="All"
						/>
					</section>
					<section>
						<span className="theme-label">Date Context:</span>
						<Select
							classNamePrefix="aut-select"
							isClearable={false}
							value={this.props.dateContextList.find(dc => dc._id === metadata.dateContext)}
							options={this.props.dateContextList}
							onChange={val => this.handleSetDateContext(val)}
							getOptionLabel={option => option.name}
							getOptionValue={option => option._id}
						/>
					</section>
					<section>
						<span className="theme-label">Funding Vehicle:</span>
						<KiSelect
							options={assetSourceOptions}
							getOptionValue={option => option.label}
							value={assetSourceOptions.reduce((acc, curVal) => {
								if (metadata.assetSources.includes(curVal.value)) {
									acc.push(curVal);
								}
								return acc;
							}, [])}
							onChange={options => this.handleSetAssetSources(options.map(option => option.value))}
							isMulti={true}
							placeholder="All"
						/>
					</section>
				</div>
				{this.props.calculatedDate && (
					<div className={styles.statusBar}>
						Data results for {this.props.calculatedDate} using {this.props.dateContextName} date context
					</div>
				)}
			</div>
		);
	}
}

const mapDispatchToProps = () => ({
	setMetadataCriteriaSources,
	setMetadataAssetSources,
	setMetadataStatementDate,
	setMetadataDateContext,
});

const mapStateToProps = state => ({
	eligibilityAnalysis: state.eligibilityAnalysis,
	dataset: state.datasetList.selected,
	fundingVehicleList: state.fundingVehicleList.data,
	dateContextList: state.eligibilityAnalysis.dateContextList,
	calculatedDate: state.eligibilityAnalysis.calculatedDate,
	dateContextName: state.eligibilityAnalysis.dateContextName,
});

export default connect(mapStateToProps, mapDispatchToProps())(EligibilityHeader);
