// Globals
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import _get from 'lodash/get';

// Website imports
import KiInput from '../../../../components/KiInput';
import KiButton from '../../../../components/KiButton';
import KiCheckbox from '../../../../components/KiCheckbox';
import {useMergedState} from '../../../../utils/customHooks';
import {USER_GROUPS, userGroupsIntersect} from '../../../../utils/userGroupsUtil';

// Local imports
import styles from './SaveView.theme.scss';

export const DATA_TRANSFER_ASSET_LEVEL_VIEW_ID_MAP = new Map([
	['encumbranceAssetLevel', 'Encumbrance Asset Level'],
	['encumbrancePoolSummary', 'Encumbrance Pool Summary'],
	['encumbranceStratification', 'Encumbrance Stratification'],
]);

const SaveView = ({view = {}, cancel, save, copy, userBookmarks = []}) => {
	const user = useSelector(state => state.user);
	const isAdmin = userGroupsIntersect(user.groups, [USER_GROUPS.SYSTEM_ADMINS]);

	const isCopy = view.isGlobal && !isAdmin;

	const [formValues, setFormValues] = useMergedState({});
	const [nameError, setNameError] = useState('');

	const initializeForm = () =>
		setFormValues({
			name: `${view.name}${isCopy ? ' copy' : ''}`,
			tags: _get(view, 'tags', []).map(t => {
				return {value: t, label: t};
			}),
			isDefault: view.isDefault,
			isFavorite: view.isFavorite,
			isGlobal: view.isGlobal,
			isStratification: view.dataTransferViewId === 'encumbranceStratification',
		});

	useEffect(
		() => {
			initializeForm();
		},
		[view]
	);

	const setName = value => {
		setFormValues({name: value});
		setNameError(!value?.trim() ? 'Required' : '');
	};

	const getFormObject = () => {
		const toReturn = Object.assign(
			{},
			{name: formValues.name},
			{isDefault: formValues.isDefault},
			{tags: _get(formValues, 'tags', []).map(t => t.value)},
			{isFavorite: formValues.isFavorite},
			{isStratification: formValues.isStratification},
			{isGlobal: formValues.isGlobal}
		);
		return toReturn;
	};

	const handleCancel = () => {
		initializeForm();
		cancel();
	};

	const handleSave = () => {
		const {name} = formValues;

		if (name !== view.name && userBookmarks.find(v => v.name === name)) {
			setNameError('Name already exists');
		} else {
			save(getFormObject());
		}
	};

	const handleCopy = () => {
		const {name} = formValues;

		if (name === view.name || userBookmarks.find(v => v.name === name)) {
			setNameError('Name already exists');
		} else {
			copy(getFormObject());
		}
	};

	return (
		<div>
			<div className={styles.title}>{isCopy ? 'Copy' : 'Save'}</div>
			<div className={styles.form}>
				<KiInput
					name="name"
					label="View Name"
					type="text"
					value={formValues.name}
					error={nameError}
					onChange={setName}
				/>
				<CreatableSelect
					classNamePrefix="aut-select"
					isMulti={true}
					options={[]}
					onChange={value => setFormValues({tags: value})}
					placeholder={'Add Tags'}
					isClearable={true}
					value={formValues.tags}
				/>
			</div>
			<KiCheckbox
				name="isDefault"
				checked={formValues.isDefault}
				label="Use as Default For Dataset"
				onChange={value => setFormValues({isDefault: value})}
			/>
			<KiCheckbox
				name="isFavorite"
				checked={formValues.isFavorite}
				label="Favorite"
				onChange={value => setFormValues({isFavorite: value})}
			/>
			{isAdmin && (
				<KiCheckbox
					name="isGlobal"
					checked={formValues.isGlobal}
					label="Global"
					onChange={value => setFormValues({isGlobal: value})}
				/>
			)}
			{!view.viewColumns && (
				<KiCheckbox
					name="isStratification"
					disabled={
						!formValues.isGlobal ||
						(view.dataTransferViewId && view.dataTransferViewId !== 'encumbranceStratification')
					}
					checked={formValues.isStratification}
					label="Stratification"
					title={
						(view.dataTransferViewId &&
							view.dataTransferViewId !== 'encumbranceStratification' &&
							`View is already assigned to ${DATA_TRANSFER_ASSET_LEVEL_VIEW_ID_MAP.get(
								view.dataTransferViewId
							) || 'another view type.'}`) ||
						undefined
					}
					style={{
						cursor:
							(view.dataTransferViewId &&
								view.dataTransferViewId !== 'encumbranceStratification' &&
								'not-allowed') ||
							undefined,
					}}
					onChange={value => setFormValues({isStratification: value})}
				/>
			)}
			{!view.isDefault &&
				formValues.isDefault &&
				userBookmarks.find(v => v.isDefault == true) && (
					<div className={styles.warningText}>A default bookmark exists already and will be replaced.</div>
				)}
			<div className={styles.formButtons}>
				<KiButton flat primary onClick={handleCancel} label="Cancel" />
				<KiButton raised primary onClick={handleCopy} disabled={!!nameError} label="Save As" />
				<KiButton
					raised
					primary
					onClick={isCopy ? handleCopy : handleSave}
					label="Save"
					disabled={!!nameError}
				/>
			</div>
		</div>
	);
};

SaveView.propTypes = {
	view: PropTypes.object,
	cancel: PropTypes.func,
	save: PropTypes.func,
	copy: PropTypes.func,
	userBookmarks: PropTypes.array,
};

export default SaveView;
