import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../common/List.theme.scss';
import classNames from 'classnames';

const VectorListMainCell = ({vector}) => {
	const {name, description} = vector;

	return (
		<div className={classNames(styles.additionalDataCell, description && styles.oneLineData)}>
			<div className={styles.mainData}>{name}</div>
			{description && (
				<div className={styles.additionalData}>
					<div title={description}>Description: {description}</div>
				</div>
			)}
		</div>
	);
};

VectorListMainCell.propTypes = {
	vector: PropTypes.object.isRequired,
};

export default VectorListMainCell;
