// Globals
import React, {useContext, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

// Website components
import KiAppBar from '../../../components/KiAppBar';
import KiFontIcon from '../../../components/KiFontIcon';
import {useOutsideClick} from '../../../utils/customHooks';
import {KiIconMaxRecords, KiIconCsv} from '../../../components/KiIcons';
import ContextIcons from '../../../components/ContextSidebar/icons';
import {_getHeaders, apiUrl} from '../../../api/apiUtils';
import {openContextSidebarPanel} from '../../../state/actions/App';

// Local imports
import styles from './dataExplorerHeader.theme.scss';
import SaveView from './SaveView';
import MaxRecords from '../components/MaxRecords';
import {DataExplorerContext} from '../DataExplorerContext';
import {BookmarkApiContext} from '../contexts/BookmarkApiContext';

export const DataExplorerHeader = ({datasetBlock, datasetList, fetchBookmarkList, bookmarkList}) => {
	// Browser State
	const history = useHistory();

	// Redux State
	const dispatch = useDispatch();
	const dataset = useSelector(state => state.datasetList.selected);

	// Context State
	const dataExplorerContext = useContext(DataExplorerContext);
	const {
		bookmark,
		bookmark: {explorerData},
		hasUnsavedChanges,
	} = dataExplorerContext;
	const dataExplorer = explorerData;

	const bookmarkApiContext = useContext(BookmarkApiContext);

	// Local State
	const [showMaxRecordsDialog, setShowMaxRecordsDialog] = useState(false);
	const [showSaveDialog, setShowSaveDialog] = useState(false);

	const maxRecordsDialogRef = useRef(null);
	useOutsideClick(maxRecordsDialogRef, () => setShowMaxRecordsDialog(false));

	const saveDialogRef = useRef(null);
	useOutsideClick(saveDialogRef, () => setShowSaveDialog(false));

	// ---------
	// Functions
	// ---------
	const buildBookmarkName = () => {
		const iconTitle = bookmark.isGlobal ? 'language' : 'person';
		return (
			<h1>
				&gt;{' '}
				<KiFontIcon className="pager-icon">
					<i className="material-icons no-rollover">{iconTitle}</i>
				</KiFontIcon>{' '}
				{bookmark.name} {hasUnsavedChanges ? <span className={styles.saveWarning}>*</span> : ''}
			</h1>
		);
	};

	const handleSetMaxRecords = value => {
		dataExplorerContext.setExplorerDataAndFetch({maxRecords: value});
		setShowMaxRecordsDialog(false);
	};

	const onExportCurrentView = () => {
		const tempBookmark = {
			name: bookmark.name,
			datasetId: bookmark.datasetId,
			tags: [],
			isDefault: false,
			isFavorite: false,
			isStratification: bookmark.dataTransferViewId === 'encumbranceStratification',
			isGlobal: false,
			isReportOnly: true,
			explorerData: _cloneDeep(bookmark.explorerData),
		};

		const webFilename = encodeURIComponent(tempBookmark.name);
		const statementDate = _get(tempBookmark, 'explorerData.statementDate', '');
		return fetch(`${apiUrl}/bookmarks/exportCSV?filename=${webFilename}&statementDate=${statementDate}`, {
			credentials: 'include',
			method: 'POST',
			headers: _getHeaders('POST'),
			body: JSON.stringify(tempBookmark),
		})
			.then(res => {
				return res.blob();
			})
			.then(data => {
				const link = document.createElement('a');
				link.href = window.URL.createObjectURL(data);
				link.download = `${tempBookmark.name}_${new Date().getTime()}.csv`;
				link.click();
				link.parentNode.removeChild(link);
			});
	};

	const saveBookmark = overrides => {
		setShowSaveDialog(false);
		bookmarkApiContext.saveBookmark(overrides).then(result => {
			fetchBookmarkList();
			return result;
		});
	};

	const copyBookmark = overrides => {
		setShowSaveDialog(false);
		bookmarkApiContext.copyCurrentBookmark(overrides).then(result => {
			fetchBookmarkList();
			history.push(`/dataExplorer/${result.datasetId}?bookmarkId=${result._id}`);
			return result;
		});
	};

	return (
		<header>
			<KiAppBar className="top-bar">
				<div className="top-bar-breadcrumb">
					<h1 className="link" onClick={() => history.push('/datasets')}>
						Datasets{' '}
					</h1>
					<h1>{`> ${dataset && dataset.name}`} </h1>
					{bookmark && buildBookmarkName()}
				</div>
				{!dataExplorer.isLoading && (
					<React.Fragment>
						<div className={styles.datasetSelector}>
							<p>Dataset:</p>
							<Select
								value={datasetBlock.dataset._id ? datasetBlock.dataset : null}
								isClearable={false}
								options={datasetList}
								isLoading={datasetBlock.isLoading}
								onChange={val => {
									history.push(`/dataExplorer/${val.datasetId}`);
								}}
								getOptionLabel={option => option.name}
								getOptionValue={option => option._id}
							/>
						</div>
						<div className={styles.maxRecords} ref={maxRecordsDialogRef}>
							<div
								title={
									dataExplorer.maxRecords
										? `Top ${dataExplorer.maxRecords} rows shown`
										: `Set number of rows`
								}
								className={`${styles.maxRecordsIcon} ${
									dataExplorer.maxRecords ? styles.activeIcon : ''
								}`}
								onClick={() => setShowMaxRecordsDialog(show => !show)}
							>
								<KiIconMaxRecords />
							</div>
							<MaxRecords
								maxRecords={dataExplorer.maxRecords}
								save={handleSetMaxRecords}
								cancel={() => setShowMaxRecordsDialog(false)}
								visible={showMaxRecordsDialog}
							/>
						</div>
						<div
							className={styles.filterIndicator}
							onClick={() => dispatch(openContextSidebarPanel('View Settings', {tabIndex: 4}))}
							alt={`${dataExplorer.filters.length} Filters Applied`}
							title={`${dataExplorer.filters.length} Filters Applied`}
						>
							<div className={styles.svgWrapper}>
								<ContextIcons.FiltersIcon />
							</div>
							<div className={styles.filterCount}>{dataExplorer.filters.length}</div>
						</div>
						<div className={styles.saveIcon}>
							<div
								className={`material-icons ${styles.downloadCsvIcon}`}
								alt="Download CSV"
								title="Download CSV"
								onClick={onExportCurrentView}
							>
								<KiIconCsv />
							</div>
							<i
								className={`material-icons ${styles.explorerIcon}`}
								title="Click to save the view."
								onClick={() => setShowSaveDialog(true)}
							>
								save
							</i>
							{showSaveDialog && (
								<div className={styles.saveDialog} ref={saveDialogRef}>
									<SaveView
										view={bookmark}
										save={saveBookmark}
										copy={copyBookmark}
										cancel={() => setShowSaveDialog(false)}
										userBookmarks={bookmarkList}
									/>
								</div>
							)}
						</div>
					</React.Fragment>
				)}
			</KiAppBar>
		</header>
	);
};

DataExplorerHeader.propTypes = {
	datasetBlock: PropTypes.shape({
		isLoading: PropTypes.bool,
		dataset: PropTypes.object,
		datasetName: PropTypes.string,
	}),
	datasetList: PropTypes.array,
	bookmarkList: PropTypes.array,
	fetchBookmarkList: PropTypes.func,
};

export default DataExplorerHeader;
