// Globals
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {buildCalculation} from 'ki-common/utils/explorerUtils';
import options from 'ki-common/options';

// Website imports
import DialogChart from '../../components/DialogChart';
import ColumnMenu from '../../components/ColumnMenu';

export class ExplorerTableColumn extends Component {
	static propTypes = {
		handleColumnSortSelect: PropTypes.func,
		handleTimeSeriesColumnSelect: PropTypes.func,
		setGranularity: PropTypes.func,
		getSummaryColumnChartData: PropTypes.func,
		getTimeSeriesChartData: PropTypes.func,
		getTimeseriesAllChartData: PropTypes.func,
		showSummaryColumnVisualizationLink: PropTypes.bool,
		showTimeSeriesVisualizationLink: PropTypes.bool,
		tableType: PropTypes.string,
		col: PropTypes.object,
		bands: PropTypes.object,
		groupBy: PropTypes.any,
		snapshotType: PropTypes.string,
		idx: PropTypes.number,
		startInclusive: PropTypes.bool,
		timeSeriesColumn: PropTypes.object,
	};

	state = {
		summaryColumnChartActive: false,
		summaryColumnChartData: {},
		showColumnMenu: false,
	};

	toggleSummaryColumnChart = () => {
		if (!this.state.summaryColumnChartActive) {
			this.setState(
				{
					summaryColumnChartData: this.props.getSummaryColumnChartData(
						this.props.col.columnName,
						this.props.col.calculation
					),
				},
				() => {
					this.setState({summaryColumnChartActive: !this.state.summaryColumnChartActive});
				}
			);
		} else {
			this.setState({summaryColumnChartActive: !this.state.summaryColumnChartActive});
		}
	};

	toggleTimeSeriesChart = () => {
		if (!this.state.timeSeriesChartActive) {
			this.setState(
				{
					timeSeriesChartData:
						this.props.timeSeriesColumn._id === 'all'
							? this.props.getTimeseriesAllChartData()
							: this.props.getTimeSeriesChartData(this.props.col.id),
				},
				() => {
					this.setState({
						timeSeriesChartActive: !this.state.timeSeriesChartActive,
					});
				}
			);
		} else {
			this.setState({
				timeSeriesChartActive: !this.state.timeSeriesChartActive,
			});
		}
	};

	handleColumnMenuToggle = visible => {
		this.setState({showColumnMenu: visible});
	};

	render() {
		const {idx, tableType, col, groupBy, snapshotType} = this.props;

		const sortSelected = col => {
			let order = 'asc';
			let id = col.id;
			let calculation = col.calculation;

			if (col.sortOrder) {
				if (col.sortOrder === 'asc') {
					order = 'desc';
				} else {
					id = '';
					order = '';
					calculation = '';
				}
			}
			return this.props.handleColumnSortSelect(id, order, calculation);
		};

		return (
			<div className="table-header">
				<div className="table-header-title">
					<div className="title-wrapper">
						<div>
							<div
								className="display-name"
								onClick={() => this.setState({showColumnMenu: true})}
								title={col.displayName}
							>
								{col.displayName}
							</div>
							{idx === 0 &&
								tableType !== 'asset' && (
									<div style={{fontSize: '10px', lineHeight: '12px'}}>
										{col.dataType === 'numeric' &&
											(this.props.startInclusive ? (
												<span>Inclusive &mdash; Exclusive</span>
											) : (
												<span>Exclusive &mdash; Inclusive</span>
											))}
									</div>
								)}
						</div>
						<div className="calculation">{buildCalculation(col.calculation)}</div>
					</div>
					<div className={'sort-arrows'} style={{cursor: 'pointer'}} onClick={() => sortSelected(col)}>
						<i className={`material-icons ${col.sortOrder === 'asc' && 'active'}`}>arrow_drop_up</i>
						<i className={`material-icons ${col.sortOrder === 'desc' && 'active'}`}>arrow_drop_down</i>
					</div>
				</div>
				<DialogChart
					active={this.state.summaryColumnChartActive || false}
					closeDialogFunc={this.toggleSummaryColumnChart}
					chartData={this.state.summaryColumnChartData}
					supportedChartTypes={options.aggregateChartTypes}
				/>
				<DialogChart
					active={this.state.timeSeriesChartActive || false}
					closeDialogFunc={this.toggleTimeSeriesChart}
					chartData={this.state.timeSeriesChartData}
					supportedChartTypes={options.timeSeriesChartTypes}
				/>
				<ColumnMenu
					col={col}
					show={this.state.showColumnMenu}
					handleColumnMenuToggle={this.handleColumnMenuToggle}
					toggleSummaryColumnChart={this.toggleSummaryColumnChart}
					toggleTimeSeriesChart={this.toggleTimeSeriesChart}
					showSummaryColumnVisualizationLink={this.props.showSummaryColumnVisualizationLink}
					showTimeSeriesVisualizationLink={this.props.showTimeSeriesVisualizationLink}
					handleTimeSeriesColumnSelect={this.props.handleTimeSeriesColumnSelect}
					tableType={tableType}
					setGranularity={this.props.setGranularity}
					bands={this.props.bands}
					groupBy={groupBy}
					snapshotType={snapshotType}
				/>
			</div>
		);
	}
}

export const ExplorerTableColumns = props => {
	const {
		data = [],
		handleColumnSortSelect,
		handleTimeSeriesColumnSelect,
		setGranularity,
		getSummaryColumnChartData,
		getTimeSeriesChartData,
		tableType,
	} = props;

	return (
		<thead className="table-headers">
			<tr>
				{data.map((col, idx) => (
					<ExplorerTableColumn
						key={`${col.id}-${idx}`}
						col={col}
						handleColumnSortSelect={handleColumnSortSelect}
						handleTimeSeriesColumnSelect={handleTimeSeriesColumnSelect}
						getSummaryColumnChartData={getSummaryColumnChartData}
						getTimeSeriesChartData={getTimeSeriesChartData}
						showSummaryColumnVisualizationLink={
							col.dataType === 'numeric' && col.columnType === 'aggregate'
						}
						showTimeSeriesVisualizationLink={tableType === 'timeSeries' && col.columnType === 'cohort'}
						setGranularity={setGranularity}
						tableType={tableType}
					/>
				))}
				<th className="table-header expando" />
			</tr>
		</thead>
	);
};

ExplorerTableColumns.propTypes = {
	handleColumnSortSelect: PropTypes.func,
	handleTimeSeriesColumnSelect: PropTypes.func,
	data: PropTypes.arrayOf(
		PropTypes.shape({
			columnType: PropTypes.string,
			columnName: PropTypes.string,
			calculation: PropTypes.string,
			id: PropTypes.string,
			displayName: PropTypes.string,
			dataType: PropTypes.string,
			summary: PropTypes.array,
			sortOrder: PropTypes.string,
		})
	),
	setGranularity: PropTypes.func,
	getSummaryColumnChartData: PropTypes.func,
	getTimeSeriesChartData: PropTypes.func,
	tableType: PropTypes.string,
};

ExplorerTableColumns.contextTypes = {
	router: PropTypes.object,
};

ExplorerTableColumns.displayName = 'ExplorerTableColumns';

export default ExplorerTableColumns;
