import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import _get from 'lodash/get';
import {eligibilityViewsApi} from 'api';
import {closeContextSidebarPanel} from 'state/actions/App';
import {fetchAccounts} from 'containers/accounts/actions';
import {deleteEligibilityView, setNoDeleteReasons} from 'containers/eligibilityAnalysis/actions';
import TriggerFunctionSelector from 'components/TriggerFunctionSelector';
import FlyoutConfirmPanel from 'components/FlyoutConfirmPanel';
import flyoutManageViewStyles from 'components/FlyoutManageViews/FlyoutManageViews.theme.scss';
import ViewListItem from './ViewListItem';

export class ManageEligibilityViews extends Component {
	static propTypes = {
		history: PropTypes.object,
		user: PropTypes.object,
		userList: PropTypes.array,
		eligibilityViewList: PropTypes.array.isRequired,
		viewMetadata: PropTypes.object,
		fetchAccounts: PropTypes.func,
		deleteEligibilityView: PropTypes.func,
		closeContextSidebarPanel: PropTypes.func,
		setNoDeleteReasons: PropTypes.func,
		noDeleteReasons: PropTypes.object,
	};

	static defaultProps = {
		userList: [],
	};

	constructor(props) {
		super(props);
		props.fetchAccounts();
	}

	state = {
		searchTerm: '',
		showConfirmDelete: false,
		selectedView: null,
		selectedUserId: null,
	};

	handleDeleteClick = view => {
		this.setState({
			showConfirmDelete: true,
			selectedView: view,
		});
	};

	cancelNoDeleteReasons = () => {
		this.props.setNoDeleteReasons(null);
	};

	handleDeleteConfirm = () => {
		this.props.deleteEligibilityView(this.state.selectedView._id).then(() => {
			if (!this.props.noDeleteReasons) {
				// If the deleted item is the current view, get the default and navigate there
				if (`${this.state.selectedView._id}` === `${this.props.viewMetadata._id}`) {
					return eligibilityViewsApi
						.fetchEADefaultView(this.props.viewMetadata.datasetId)
						.then(defaultView => {
							this.props.history.push(`/eligibilityAnalysis/${defaultView._id}`);
							this.handleDeleteCancel();
						});
				}
				this.handleDeleteCancel();
			}
		});
	};

	handleDeleteCancel = () => {
		this.setState(
			{
				showConfirmDelete: false,
				selectedView: null,
			},
			() => {
				this.props.closeContextSidebarPanel();
			}
		);
	};

	render() {
		const {eligibilityViewList, viewMetadata} = this.props;
		const isAdmin = this.props.user.groups.findIndex(g => g === 'SystemAdmins') >= 0;
		const userListOptions = [
			{value: '', label: 'All Users'},
			{value: 'global', label: 'Global Only'},
			...this.props.userList.map(u => ({
				value: u.userId,
				label: `${u.firstName} ${u.lastName}`,
			})),
		];

		const {selectedUserId} = this.state;
		const searchTerm = this.state.searchTerm.toLowerCase();
		const filteredViews = this.props.eligibilityViewList
			.filter(curView => {
				if (selectedUserId) {
					if (selectedUserId === 'global') {
						return curView.isGlobal;
					}
					return curView.createdBy === selectedUserId;
				}
				return true;
			})
			.filter(curView => {
				if (searchTerm) {
					return (
						curView.name.toLowerCase().includes(searchTerm) ||
						_get(curView, 'tags', []).find(tag =>
							tag
								.toString()
								.toLowerCase()
								.includes(searchTerm)
						)
					);
				} else {
					return true;
				}
			});

		if (this.state.showConfirmDelete && !this.props.noDeleteReasons) {
			return (
				<FlyoutConfirmPanel
					header={'Delete'}
					message={`Are you sure you want to delete the following view? ${_get(
						this.state.selectedView,
						'name',
						''
					)}`}
					acceptFunc={this.handleDeleteConfirm}
					rejectFunc={() => this.setState({showConfirmDelete: false, selectedView: null})}
					acceptLabel={'DELETE'}
					rejectLabel={'CANCEL'}
				/>
			);
		}

		if (this.props.noDeleteReasons && this.props.noDeleteReasons.data) {
			return (
				<FlyoutConfirmPanel
					header={'Delete'}
					message={
						<span>
							Column <span className="bold">&quot;{this.state.selectedView.name}&quot;</span> cannot be
							deleted because it is associated with the following:
						</span>
					}
					rejectFunc={this.cancelNoDeleteReasons}
					rejectLabel={'OK'}
					hideAccept={true}
					reasonList={this.props.noDeleteReasons.data.dependencies}
				/>
			);
		}

		return (
			<div className="context-sidebar-panel-flex">
				<header className="flyout-panel-title">MANAGE ELIGIBILITY ANALYSIS</header>
				<div>
					<section className="flyout-panel-search">
						{isAdmin && (
							<div className="flyout-panel-user-search">
								<p>Filter</p>
								<TriggerFunctionSelector
									className={flyoutManageViewStyles.picker}
									options={userListOptions}
									targetFunction={option => this.setState({selectedUserId: option})}
									startingValue={selectedUserId}
									sortOrder=""
									disabled={false}
									classNamePrefix="aut-select"
								/>
							</div>
						)}
						<p>Search a View</p>
						<input
							placeholder="Name"
							value={this.state.searchTerm}
							onChange={e => this.setState({searchTerm: e.target.value})}
						/>
					</section>
					{!eligibilityViewList.length ? (
						<p>No Views Found</p>
					) : (
						<section className={flyoutManageViewStyles.listContainer}>
							{filteredViews.map(view => {
								return (
									<ViewListItem
										key={view._id.toString()}
										view={view}
										currentViewId={viewMetadata._id || ''}
										isAdmin={isAdmin}
										deleteView={this.handleDeleteClick}
										history={this.props.history}
										closeContextSidebarPanel={this.props.closeContextSidebarPanel}
									/>
								);
							})}
						</section>
					)}
				</div>
			</div>
		);
	}
}

const mapDispatchToProps = () => ({
	fetchAccounts,
	deleteEligibilityView,
	closeContextSidebarPanel,
	setNoDeleteReasons,
});

const mapStateToProps = state => ({
	user: state.user,
	userList: state.accounts.data,
	dataset: state.datasetList.selected,
	eligibilityViewList: state.eligibilityAnalysis.viewList,
	viewMetadata: state.eligibilityAnalysis.metadata,
	noDeleteReasons: state.eligibilityAnalysis.noDeleteReasons,
});

export default connect(mapStateToProps, mapDispatchToProps())(ManageEligibilityViews);
