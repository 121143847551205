import * as actionTypes from './actionTypes';

const initialState = {
	metadata: {
		name: null,
		datasetId: null,
		statementDate: null,
		groupBy: null,
		criteriaSources: [],
		assetSources: [],
		dateContext: null,
	},
	data: null,
	viewList: null,
	dateContextList: [],
	calculatedDate: '',
	dateContextName: '',
	noDeleteReasons: null,
};

export const EligibilityAnalysis = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.ELIGIBILITY_ANALYSIS_SET_METADATA:
			return {
				...state,
				metadata: Object.assign({}, initialState.metadata, action.data),
			};
		case actionTypes.ELIGIBILITY_ANALYSIS_FETCH_VIEWS:
			return {
				...state,
				viewList: action.data,
			};
		case actionTypes.ELIGIBILITY_ANALYSIS_SET_NO_DELETE_REASONS:
			return {
				...state,
				noDeleteReasons: action.data,
			};
		case actionTypes.ELIGIBILITY_ANALYSIS_CREATE:
			return {
				...state,
			};
		case actionTypes.ELIGIBILITY_ANALYSIS_UPDATE:
			return {
				...state,
				metadata: Object.assign({}, initialState.metadata, action.data),
			};
		case actionTypes.ELIGIBILITY_ANALYSIS_DELETE:
			return {
				...state,
			};
		case actionTypes.ELIGIBILITY_ANALYSIS_FETCH_REPORT_DATA:
			switch (action.status) {
				case 'pending':
					return {
						...state,
						error: null,
						isLoading: true,
					};
				case 'success':
					return {
						...state,
						data: action.data,
						error: null,
						isLoading: false,
					};

				case 'error':
					return {
						...state,
						error: action.error,
						isLoading: false,
					};
				default:
					return state;
			}
		case actionTypes.EA_SET_METADATA_STATEMENT_DATE:
			return {
				...state,
				metadata: {
					...state.metadata,
					statementDate: action.data,
				},
			};
		case actionTypes.EA_SET_METADATA_DATE_CONTEXT:
			return {
				...state,
				metadata: {
					...state.metadata,
					dateContext: action.data,
				},
			};
		case actionTypes.EA_SET_DATECONTEXT_LIST:
			return {
				...state,
				dateContextList: action.data,
			};
		case actionTypes.EA_SET_CALCULATED_DATE:
			return {
				...state,
				calculatedDate: action.data,
			};
		case actionTypes.EA_SET_DATECONTEXT_NAME:
			return {
				...state,
				dateContextName: action.data,
			};
		case actionTypes.EA_SET_METADATA_CRITERIA_SOURCES:
			return {
				...state,
				metadata: {
					...state.metadata,
					criteriaSources: action.data,
				},
			};
		case actionTypes.EA_SET_METADATA_ASSET_SOURCES:
			return {
				...state,
				metadata: {
					...state.metadata,
					assetSources: action.data,
				},
			};
		default:
			return state;
	}
};

export default EligibilityAnalysis;
