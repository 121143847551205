export const ELIGIBILITY_ANALYSIS_SET_METADATA = 'ELIGIBILITY_ANALYSIS_SET_METADATA';
export const ELIGIBILITY_ANALYSIS_FETCH_VIEWS = 'ELIGIBILITY_ANALYSIS_FETCH_VIEWS';
export const ELIGIBILITY_ANALYSIS_CREATE = 'ELIGIBILITY_ANALYSIS_CREATE';
export const ELIGIBILITY_ANALYSIS_UPDATE = 'ELIGIBILITY_ANALYSIS_UPDATE';
export const ELIGIBILITY_ANALYSIS_DELETE = 'ELIGIBILITY_ANALYSIS_DELETE';
export const ELIGIBILITY_ANALYSIS_FETCH_REPORT_DATA = 'ELIGIBILITY_ANALYSIS_FETCH_REPORT_DATA';
export const EA_SET_METADATA_CRITERIA_SOURCES = 'EA_SET_METADATA_CRITERIA_SOURCES';
export const EA_SET_METADATA_ASSET_SOURCES = 'EA_SET_METADATA_ASSET_SOURCES';
export const EA_SET_METADATA_STATEMENT_DATE = 'EA_SET_METADATA_STATEMENT_DATE';
export const EA_SET_METADATA_DATE_CONTEXT = 'EA_SET_METADATA_DATE_CONTEXT';
export const EA_SET_DATECONTEXT_LIST = 'EA_SET_DATECONTEXT_LIST';
export const EA_SET_CALCULATED_DATE = 'EA_SET_CALCULATED_DATE';
export const EA_SET_DATECONTEXT_NAME = 'EA_SET_DATECONTEXT_NAME';
export const ELIGIBILITY_ANALYSIS_SET_NO_DELETE_REASONS = 'ELIGIBILITY_ANALYSIS_SET_NO_DELETE_REASONS';
