/* eslint-disable no-use-before-define */
import * as actionTypes from './actionTypes';
import {dataBookmarksApi, explorerApi, datasetDatesApi} from 'api';
import {showSnackbar} from 'state/actions/Snackbar';
import _get from 'lodash/get';
import exploreRequestBuilder from 'ki-common/utils/exploreRequestBuilder';
// the following are used to sync the sidetray
import {
	updateCohortColumn as updateSidetrayCohortColumn,
	//updateSnapshotDate as updateSidetraySnapshotDate,
	//updateSnapshotGranularity as updateSidetraySnapshotGranularity,
	//updateShowMostRecentSnapshot as updateSidetrayShowMostRecentSnapshot,
	updateBucket as updateSidetrayBucket,
	updatePageNumber as updateSidetrayPageNumber,
	updateGroupBy as updateSidetrayGroupBy,
	updateMaxRecords as updateSidetrayMaxRecords,
	loadBreadcrumbs as loadSidetrayBreadcrumbs,
	setBreadcrumb as setSidetrayBreadcrumb,
	deleteBreadcrumb as deleteSidetrayBreadcrumb,
	clearBreadcrumbs as clearSidetrayBreadcrumbs,
	updateBreadcrumb as updateSidetrayBreadcrumb,
	updateSortCalculation as updateSidetraySortCalculation,
	updateSortColumn as updateSidetraySortColumn,
	updateSortOrder as updateSidetraySortOrder,
	updateTimeseriesColumn as updateSidetrayTimeseriesColumn,
	updateTimeseriesPeriod as updateSidetrayTimeseriesPeriod,
	updateTimeseriesRange as updateSidetrayTimeseriesRange,
	updateFundingVehicleId as updateSidetrayFundingVehicleId,
	updateHypoFundingVehicleId as updateSidetrayHypoFundingVehicleId,
	updateHypoPoolId as updateSidetrayHypoPoolId,
	updatePoolId as updateSidetrayPoolId,
	updateScenarioId as updateSidetrayScenarioId,
	updateScenarioType as updateSidetrayScenarioType,
	updateGranularity as updateSidetrayGranularity,
	updateTableType as updateSidetrayTableType,
} from 'components/FlyoutManageViews/components/ViewForms/actions';

const setSortColumn = (sortColumn = '', sortOrder = 'asc', sortCalculation = '') => dispatch => {
	dispatch({type: actionTypes.EXPLORER_SORT_COLUMN_SET, sortColumn, sortCalculation, sortOrder});
	dispatch(updateSidetraySortCalculation(sortCalculation));
	dispatch(updateSidetraySortColumn(sortColumn));
	dispatch(updateSidetraySortOrder(sortOrder));
};

const setStatementDate = statementDate => dispatch => {
	dispatch({type: actionTypes.EXPLORER_SET_STATEMENT_DATE, statementDate});
	dispatch(setPageNumber(1));
	dispatch(updateSidetrayPageNumber(1));
	//dispatch(updateSidetrayStatementDate(statementDate));
};

const setPageNumber = pageNumber => dispatch => {
	dispatch({type: actionTypes.EXPLORER_PAGE_NUMBER_SET, pageNumber});
	dispatch(updateSidetrayPageNumber(pageNumber));
};

const setPageSize = pageSize => dispatch => {
	dispatch({type: actionTypes.EXPLORER_PAGE_SIZE_SET, pageSize});
};

const setTableType = tableType => dispatch => {
	if (tableType !== 'timeSeries') {
		dispatch(setTimeSeriesColumn({id: null}));
	}
	dispatch({type: actionTypes.EXPLORER_TABLE_TYPE_SET, tableType});
	dispatch(updateSidetrayTableType(tableType));
};

const setDatasetId = datasetId => dispatch => {
	dispatch({type: actionTypes.EXPLORER_DATSETID_SET, datasetId});
};

const setCohortColumn = columnId => (dispatch, getState) => {
	const {
		datasetList: {selected},
	} = getState();
	const {
		dataExplorer: {tableType},
	} = getState();
	const cohortColumn = selected.columns.find(column => column._id === columnId);
	if (tableType !== 'timeSeries') {
		dispatch(clearBreadcrumbs());
		dispatch(setTableType('cohort'));
	}
	dispatch(setSortColumn(cohortColumn._id, 'asc', null));
	dispatch(setPageNumber(1));
	dispatch(updateSidetrayPageNumber(1));
	dispatch(setBucket('', '', ''));
	dispatch(setGranularity());
	dispatch({type: actionTypes.EXPLORER_SET_COHORT_COLUMN, cohortColumn});
	//sync sidetray
	dispatch(updateSidetrayCohortColumn(cohortColumn));
};

const setGroupBy = groupBy => dispatch => {
	dispatch({type: actionTypes.EXPLORER_SET_GROUPBY, groupBy});
	dispatch(updateSidetrayGroupBy(groupBy));
};

const setMaxRecords = maxRecords => dispatch => {
	dispatch({type: actionTypes.EXPLORER_SET_MAXRECORDS, maxRecords});
	dispatch(updateSidetrayMaxRecords(maxRecords));
};

const setBreadcrumb = breadcrumb => dispatch => {
	dispatch({type: actionTypes.EXPLORER_BREADCRUMBS_SET, breadcrumb});
	dispatch(setSidetrayBreadcrumb(breadcrumb));
};

const loadBreadcrumbs = breadcrumbList => dispatch => {
	dispatch({type: actionTypes.EXPLORER_BREADCRUMBS_LOAD, breadcrumbList});
	dispatch(loadSidetrayBreadcrumbs(breadcrumbList));
};

const clearBreadcrumbs = () => dispatch => {
	dispatch({type: actionTypes.EXPLORER_BREADCRUMBS_CLEAR});
	dispatch(clearSidetrayBreadcrumbs());
};

const updateBreadcrumb = breadcrumb => dispatch => {
	dispatch(setBucket(breadcrumb.bucket.min, breadcrumb.bucket.max, breadcrumb.bucket.value));
	dispatch({type: actionTypes.EXPLORER_BREADCRUMBS_UPDATE, breadcrumb});
	dispatch(updateSidetrayBreadcrumb(breadcrumb));
};

const deleteBreadcrumb = breadcrumb => (dispatch, getState) => {
	let bucket;
	//let granularity;
	const state = getState();
	const {id} = breadcrumb;
	const idx = state.dataExplorer.breadcrumbs.findIndex(b => b.id === id);
	// if we are dealing with the first breadcrumb
	if (idx === 0) {
		bucket = {
			min: '',
			max: '',
			value: '',
		};
	} else {
		const targetBreadcrumb = state.dataExplorer.breadcrumbs[idx - 1];
		bucket =
			(targetBreadcrumb.options[targetBreadcrumb.currValue] &&
				targetBreadcrumb.options[targetBreadcrumb.currValue].bucket) ||
			{}; //eslint-disable-line prefer-destructuring
		//granularity = targetBreadcrumb.options[targetBreadcrumb.currValue].granularity; //eslint-disable-line prefer-destructuring
	}
	dispatch(setTableType('cohort'));
	dispatch(setPageNumber(1));
	dispatch(updateSidetrayPageNumber(1));
	dispatch({type: actionTypes.EXPLORER_BREADCRUMBS_DELETE, id});
	dispatch(deleteSidetrayBreadcrumb(breadcrumb));
	dispatch(setBucket(bucket.min, bucket.max, bucket.value));
	if (breadcrumb.prevGranularity) {
		dispatch(setGranularity(breadcrumb.prevGranularity));
	}
};

const setBucket = (min, max, value) => (dispatch, getState) => {
	const granularityArray = [
		'daily',
		'weekly',
		'weeklyFixed',
		'biWeekly',
		'biWeeklyFixed',
		'fortnightly',
		'monthly',
		'quarterly',
		'semiAnnually',
		'annually',
	];
	const {dataExplorer} = getState();
	if (!value && dataExplorer.tableType === 'asset' && dataExplorer.columns[0].dataType === 'string') {
		// BE cannot handle empty string in this situation so pass pre-set value
		value = 'emptyString';
	}

	if (dataExplorer.tableType === 'asset' && granularityArray.includes(dataExplorer.columns[0].granularity)) {
		if (value.includes('Prior')) {
			value = '';
			min = '';
			max = dataExplorer.columns[0].dateRange.start;
		}
		if (value.includes('Post')) {
			value = '';
			min = dataExplorer.columns[0].dateRange.end;
			max = '';
		}
		// dispatch(setStartInclusive(false));
	}

	dispatch({type: actionTypes.EXPLORER_SET_BUCKET, min, max, value});
	dispatch(updateSidetrayBucket({min, max, value}));
};

const setGranularity = granularity => dispatch => {
	dispatch({type: actionTypes.EXPLORER_SET_GRANULARITY, granularity: granularity});
	dispatch(updateSidetrayGranularity(granularity));
	dispatch(setPageNumber(1));
	dispatch(updateSidetrayPageNumber(1));
};

const setBands = bands => dispatch => {
	dispatch({type: actionTypes.EXPLORER_SET_BANDS, bands: bands});
};

const clearData = () => dispatch => dispatch({type: actionTypes.EXPLORER_DATA_CLEAR});

const setTimeSeriesColumn = columnData => (dispatch, getState) => {
	const {
		dataExplorer: {columns},
	} = getState();
	const column = columns.find(column => column._id === columnData.id) || {_id: columnData.id};
	if (columnData.id === 'all') {
		dispatch(
			setCohortColumn(
				columnData.allColumns.find(column => _get(column, 'assetColumn.columnName') === 'kiSnapshotDate')._id
			)
		);
	}
	dispatch({type: actionTypes.EXPLORER_SET_TIMESERIES_COLUMN, column});
	dispatch(updateSidetrayTimeseriesColumn(columnData.id));
	dispatch(setPageNumber(1));
	dispatch(updateSidetrayPageNumber(1));
};

const setTimeSeriesRange = range => dispatch => {
	dispatch({type: actionTypes.EXPLORER_SET_TIMESERIES_RANGE, range});
	dispatch(updateSidetrayTimeseriesRange(range));
	dispatch(setPageNumber(1));
	dispatch(updateSidetrayPageNumber(1));
};

const setTimeSeriesPeriod = period => dispatch => {
	dispatch({type: actionTypes.EXPLORER_SET_TIMESERIES_PERIOD, period});
	dispatch(updateSidetrayTimeseriesPeriod(period));
	dispatch(setPageNumber(1));
	dispatch(updateSidetrayPageNumber(1));
};

const setDateContext = dateContext => dispatch => {
	dispatch({type: actionTypes.EXPLORER_SET_DATE_CONTEXT, dateContext});
};

const setIsFixedDate = isFixedDate => dispatch => {
	dispatch({type: actionTypes.EXPLORER_SET_IS_FIXED_DATE, isFixedDate});
};

const setSnapshotType = showBlended => dispatch => {
	const snapshotType = showBlended ? 'blended' : 'standard';
	dispatch({type: actionTypes.EXPLORER_SNAPSHOT_TYPE, snapshotType});
};

const loadTimeSeries = timeseries => dispatch => {
	dispatch({type: actionTypes.EXPLORER_LOAD_TIMESERIES, timeseries});
	dispatch(setPageNumber(1));
	dispatch(updateSidetrayPageNumber(1));
};

const setQuickFilterScenario = value => dispatch => {
	dispatch({type: actionTypes.EXPLORER_QUICKFILTERS_SCENARIO, value});
	dispatch(updateSidetrayScenarioId(value));
};

const setQuickFilterScenarioType = value => dispatch => {
	dispatch({type: actionTypes.EXPLORER_QUICKFILTERS_SCENARIO_TYPE, value});
	dispatch(updateSidetrayScenarioType(value));
};

const setQuickFilterFundingVehicle = value => dispatch => {
	dispatch({type: actionTypes.EXPLORER_QUICKFILTERS_FUNDINGVEHICLE, value});
	dispatch(updateSidetrayFundingVehicleId(value));
};

const setQuickFilterPool = value => dispatch => {
	dispatch({type: actionTypes.EXPLORER_QUICKFILTERS_POOL, value});
	dispatch(updateSidetrayPoolId(value));
};

const setQuickFilterHypoPool = value => dispatch => {
	dispatch({type: actionTypes.EXPLORER_QUICKFILTERS_HYPO_POOL, value});
	dispatch(updateSidetrayHypoPoolId(value));
};

const setQuickFilterHypoFundingVehicle = value => dispatch => {
	dispatch({type: actionTypes.EXPLORER_QUICKFILTERS_HYPO_FUNDINGVEHICLE, value});
	dispatch(updateSidetrayHypoFundingVehicleId(value));
};

const setColumns = (columnsToSet, columnType) => (dispatch, getState) => {
	const {
		dataExplorer: {columns},
	} = getState();
	const newColumns = exploreRequestBuilder.getNewColumnsByType(columns, columnType, columnsToSet);
	dispatch({type: actionTypes.EXPLORER_COLUMNS_SET, columns: newColumns});
};

const removeColumn = columnId => (dispatch, getState) => {
	const {dataExplorer} = getState();
	const newColumns = dataExplorer.columns.slice().filter(c => c._id !== columnId);
	dispatch({type: actionTypes.EXPLORER_COLUMNS_SET, columns: newColumns});
	datasetDatesApi.fetchPortfolioDates(dataExplorer.datasetId).then(dateContextList => {
		dispatch(fetchExplorerData(dateContextList));
	});
};

const updateColumn = column => (dispatch, getState) => {
	const {
		dataExplorer: {columns = []},
	} = getState();
	if (!column || !column._id) {
		return Promise.reject('No _id in provided column');
	}
	const matchingExploreDataColumn = columns.find(c => c._id === column._id);
	if (matchingExploreDataColumn) {
		dispatch({
			type: actionTypes.EXPLORER_COLUMNS_UPDATE_ONE,
			column: {
				...column,
				displayName: matchingExploreDataColumn.displayName,
				displayFormat: matchingExploreDataColumn.displayFormat,
				granularity: matchingExploreDataColumn.granularity,
			},
		});
		return Promise.resolve(true);
	}
	return Promise.resolve(false);
};

const setFilters = filters => dispatch => {
	dispatch({type: actionTypes.EXPLORER_FILTERS_SET, filters: filters});
};

const setRequestFromBookmark = bookmark => (dispatch, getState) => {
	const {dataExplorer} = getState();
	const newDataExplorer = exploreRequestBuilder.getExplorerStateFromBookmark(dataExplorer, bookmark);
	dispatch({type: actionTypes.EXPLORER_APPLY_BOOKMARK_TO_STATE, newDataExplorer});
};

const getCalculatedDateInfo = (
	dateContextId,
	dateContextList,
	statementDate,
	fundingVehicleId,
	isFixedDate,
	datasetId
) => {
	const dateContext = dateContextList.find(d => d._id === dateContextId);
	const calculatedDatesRequest = [
		{
			groupId: dateContext.groupId,
			statementDate: statementDate,
			fundingVehicleId: fundingVehicleId,
			isFixedDate: isFixedDate,
		},
	];
	return datasetDatesApi.getCalculatedDates(calculatedDatesRequest, datasetId).then(info => {
		if (info.length) return info;
	});
};

const fetchExplorerData = dateContextList => async (dispatch, getState) => {
	const {dataExplorer, datasetList} = getState();
	if (dataExplorer.tableType === 'timeSeries') {
		// catch missing timeseries columns and revert to cohort
		const match = dataExplorer.columns.find(c => c._id === dataExplorer.timeseries.column._id);
		if (!match && dataExplorer.timeseries.column._id != 'all') {
			dispatch(clearBreadcrumbs());
			dispatch(setTableType('cohort'));
		}
	}
	const dateInfo = await getCalculatedDateInfo(
		dataExplorer.dateContext,
		dateContextList,
		dataExplorer.statementDate,
		dataExplorer.quickFilters.fundinVehicleId,
		dataExplorer.isFixedDate,
		dataExplorer.datasetId
	);
	if (dateInfo.length) {
		dispatch({type: actionTypes.EXPLORER_DATECONTEXTNAME_SET, name: dateInfo[0].name});
		dispatch({type: actionTypes.EXPLORER_CALCULATEDDATE_SET, calculatedDate: dateInfo[0].calculatedDate});
	}
	dispatch({type: actionTypes.EXPLORER_DATA_FETCH, status: 'pending'});
	return explorerApi
		.fetchExplore(dataExplorer, datasetList.selected.columns)
		.then(data => {
			dispatch({type: actionTypes.EXPLORER_DATA_FETCH, status: 'success', data: data});
		})
		.catch(error => dispatch({type: actionTypes.EXPLORER_DATA_FETCH, status: 'error', error: error}));
};

const drillIntoAsset = (bucket, breadcrumb) => (dispatch, getState) => {
	const {dataExplorer} = getState();
	const existingAssetColumns = dataExplorer.columns.filter(col => col.columnType === 'asset');
	dispatch(setColumns(existingAssetColumns, 'asset'));
	const assetBreadcrumb = {
		id: 1,
		type: 'asset',
		currValue: 1,
		options: [],
	};
	dispatch(setBreadcrumb(breadcrumb));
	dispatch(setBreadcrumb(assetBreadcrumb));
	dispatch(setTableType('asset'));
	dispatch(setBucket(bucket.min, bucket.max, bucket.value));
	datasetDatesApi.fetchPortfolioDates(dataExplorer.datasetId).then(dateContextList => {
		dispatch(fetchExplorerData(dateContextList));
	});
};

const drillIntoBucket = (bucket, breadcrumb, granularity) => (dispatch, getState) => {
	const {dataExplorer} = getState();
	dispatch(setBreadcrumb(breadcrumb));
	dispatch(setBucket(bucket.min, bucket.max, bucket.value));
	dispatch(setGranularity(granularity));
	datasetDatesApi.fetchPortfolioDates(dataExplorer.datasetId).then(dateContextList => {
		dispatch(fetchExplorerData(dateContextList));
	});
};

const drillIntoTimeSeries = () => (dispatch, getState) => {
	const {dataExplorer} = getState();
	const timeSeriesBreadcrumb = {
		id: 1,
		type: 'timeSeries',
		currValue: 1,
		options: [],
	};
	dispatch(setBreadcrumb(timeSeriesBreadcrumb));
	dispatch(setTableType('timeSeries'));
	datasetDatesApi.fetchPortfolioDates(dataExplorer.datasetId).then(dateContextList => {
		dispatch(fetchExplorerData(dateContextList));
	});
};

const saveBookmark = bookmark => dispatch => {
	delete bookmark.explorerData.data;
	delete bookmark.explorerData.isLoading;
	delete bookmark.explorerData.error;
	delete bookmark.explorerData.calculatedDate;
	delete bookmark.explorerData.dateContextName;
	return dataBookmarksApi
		.upsertBookmark(bookmark)
		.then(result => {
			dispatch(showSnackbar(`Saved view "${bookmark.name}" successfully`));
			return result;
		})
		.catch(err => {
			dispatch(showSnackbar(err.message));
		});
};

const copyBookmark = bookmark => (dispatch, getState) => {
	const {dataExplorer} = getState();
	const {
		datasetList: {selected},
	} = getState();
	bookmark.datasetId = selected.datasetId;
	bookmark.createDate = new Date();
	bookmark.explorerData = Object.assign({}, dataExplorer);
	delete bookmark.explorerData.data;
	bookmark.isGadget = false;
	bookmark.tags = _get(bookmark, 'tags', []).filter(tag => tag.length !== 0);

	return dataBookmarksApi
		.upsertBookmark(bookmark)
		.then(result => {
			dispatch(showSnackbar(`Saved view "${bookmark.name}" successfully`));
			return result;
		})
		.catch(err => {
			dispatch(showSnackbar(err.message));
		});
};

const setStartInclusive = startInclusive => dispatch => {
	dispatch({type: actionTypes.VIEW_STARTINCLUSIVE_SET, startInclusive: startInclusive});
};

export {
	setBands,
	loadTimeSeries,
	setSortColumn,
	setStatementDate,
	setDateContext,
	setIsFixedDate,
	setPageNumber,
	setPageSize,
	setTableType,
	setDatasetId,
	setCohortColumn,
	clearBreadcrumbs,
	setBreadcrumb,
	updateBreadcrumb,
	deleteBreadcrumb,
	setBucket,
	setGranularity,
	clearData,
	setTimeSeriesColumn,
	setTimeSeriesRange,
	setTimeSeriesPeriod,
	setSnapshotType,
	setQuickFilterScenario,
	setQuickFilterScenarioType,
	setQuickFilterFundingVehicle,
	setQuickFilterPool,
	setQuickFilterHypoPool,
	setQuickFilterHypoFundingVehicle,
	setColumns,
	setFilters,
	fetchExplorerData,
	setRequestFromBookmark,
	drillIntoAsset,
	drillIntoBucket,
	drillIntoTimeSeries,
	saveBookmark,
	copyBookmark,
	loadBreadcrumbs,
	removeColumn,
	updateColumn,
	setGroupBy,
	setMaxRecords,
	setStartInclusive,
	getCalculatedDateInfo,
};
