import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import styles from '../common/List.theme.scss';
import ListUpdatedAtCell from '../common/ListUpdatedAtCell';
import ListActionsCell from '../common/ListActionsCell';
import {FORECASTING_FLYOUT_MODE} from '../common/FlyoutSwitch';
import _debounce from 'lodash/debounce';
import NewItemSection from '../common/NewItemSection';
import KiInput from '../../../../components/KiInput';
import KiConfirmModal from '../../../../components/KiConfirmModal';
import {getPricingAssumptions, deletePricingAssumption, getPricingAssumptionDeps} from '../../../../api/waterfallApi';
import {showSnackbar} from '../../../../state/actions/Snackbar';
import {connect} from 'react-redux';
/* eslint-disable react/display-name */

const PricingAssumptionList = ({setFlyoutMode, showSnackbar}) => {
	const [isLoading, setIsLoading] = useState(false);
	const [fullAssumptionList, setFullAssumptionList] = useState([]);
	const [currentAssumptions, setCurrentAssumptions] = useState([]);
	const [searchPhrase, setSearchPhrase] = useState('');
	const [deletionTarget, setDeletionTarget] = useState();
	const [deleteReferentialMsgActive, setDeleteReferentialMsgActive] = useState(false);
	const [assumptionDeps, setAssumptionDeps] = useState([]);

	const filterAssumptions = (phrase, fullList) =>
		setCurrentAssumptions(fullList.filter(item => item.name.toLowerCase().includes(phrase.toLowerCase())));

	const filterAssumptionsDebounce = useCallback(
		_debounce(phrase => filterAssumptions(phrase, fullAssumptionList), 500),
		[fullAssumptionList]
	);

	const loadAssumptions = (performSearch = false) => {
		setIsLoading(true);
		getPricingAssumptions().then(assumptions => {
			setFullAssumptionList(assumptions);
			if (performSearch && searchPhrase) {
				filterAssumptions(searchPhrase, assumptions);
			} else {
				setCurrentAssumptions(assumptions);
			}

			setIsLoading(false);
		});
	};

	const onSearch = phrase => {
		setSearchPhrase(phrase);
		filterAssumptionsDebounce(phrase);
	};

	useEffect(() => {
		loadAssumptions();
	}, []);

	const columns = React.useMemo(
		() => [
			{
				Header: 'Name',
				accessor: 'name',
				sortable: true,
			},
			{
				Header: 'Properties',
				sortable: false,
				Cell: row => {
					const {method, input, midpoint, stepSize} = row.original;
					const row1 = `${method}, ${input}`;
					const row2 = `${midpoint}, ${stepSize}`;
					return (
						<div>
							<div title={row1}>{row1}</div>
							<div title={row2}>{row2}</div>
						</div>
					);
				},
			},
			{
				Header: 'Updated',
				accessor: 'lastUpdated',
				sortable: true,
				Cell: row => <ListUpdatedAtCell value={row?.value} />,
			},
			{
				Header: '',
				Cell: row => (
					<ListActionsCell
						actions={[
							{
								title: 'Edit',
								icon: 'edit',
								callback: () =>
									setFlyoutMode(FORECASTING_FLYOUT_MODE.PRICING_ASSUMPTION_ADD, row.original._id),
							},
							{title: 'Delete', icon: 'delete', callback: () => setDeletionTarget(row.original)},
						]}
					/>
				),
				sortable: false,
				width: 76,
				className: styles.actionsColumn,
			},
		],
		[]
	);

	const onDeleteConfirmation = () => {
		getPricingAssumptionDeps(deletionTarget._id).then(result => {
			if (Array.isArray(result) && result.length > 0) {
				setAssumptionDeps(result);
				setDeleteReferentialMsgActive(true);
				setDeletionTarget(undefined);
			} else {
				deletePricingAssumption(deletionTarget._id).then(() => {
					setDeletionTarget(null);
					showSnackbar('Pricing Assumption deleted successfully');
					loadAssumptions(true);
				});
			}
		});
	};

	return (
		<>
			<NewItemSection
				label="Pricing Assumption"
				onClick={() => setFlyoutMode(FORECASTING_FLYOUT_MODE.PRICING_ASSUMPTION_ADD)}
			/>
			<section className={styles.filters}>
				<div>
					<KiInput label="Search Pricing Assumptions" onChange={onSearch} value={searchPhrase} />
				</div>
			</section>
			<ReactTable
				data={currentAssumptions}
				columns={columns}
				loading={isLoading}
				className={styles.table}
				showPagination={false}
				minRows={4}
				defaultSorted={[
					{
						id: 'name',
						desc: false,
					},
				]}
				defaultPageSize={1000}
			/>
			<KiConfirmModal
				id="AssumptionDeletionConfirmModal"
				header="Delete Pricing Assumption"
				message={`Are you sure you want to delete the "${deletionTarget?.name}"?`}
				acceptFunc={onDeleteConfirmation}
				rejectFunc={() => setDeletionTarget(null)}
				acceptLabel="Delete"
				rejectLabel="Cancel"
				active={!!deletionTarget}
			/>
			<KiConfirmModal
				active={deleteReferentialMsgActive}
				acceptDisabled={true}
				rejectFunc={() => setDeleteReferentialMsgActive(false)}
				rejectLabel="OK"
				header="Cannot delete"
				message="The pricing assumption could not be deleted because it is used in the following scenarios"
			>
				{assumptionDeps.map(c => {
					return <li key={c._id}>{c.name}</li>;
				})}
			</KiConfirmModal>
		</>
	);
};

PricingAssumptionList.propTypes = {
	setFlyoutMode: PropTypes.func.isRequired,
	showSnackbar: PropTypes.func.isRequired,
};

export default connect(null, {showSnackbar})(PricingAssumptionList);
