// Globals
import React, {useState, useContext, useEffect} from 'react';
import PropTypes from 'prop-types';

// Website imports
import KiList from 'components/KiList';

// Local imports
import {DataExplorerContext} from '../../DataExplorerContext';
import TimeSeriesLink from './TimeSeriesLink';
import SummaryLink from './SummaryLink';
import VisualizationLink from './VisualizationLink';
import GranularityMenu from './GranularityMenu';
import SummaryData from './SummaryData';
import styles from './ColumnMenu.theme.scss';

export const ColumnMenu = props => {
	const [showSummary, setShowSummary] = useState(false);
	const dataExplorerContext = useContext(DataExplorerContext);
	const {appliedBookmark, allColumns, calculatedDateInfo} = dataExplorerContext;

	const handleClick = e => {
		if (e.target.textContent === 'Summary') {
			return;
		}
		// Must be called here or handleClick will be redefined on render
		// thus preventing removeEventListener from matching old one
		document.removeEventListener('click', handleClick, false);
		props.handleColumnMenuToggle(false);
	};

	const toggleSummary = () => {
		setShowSummary(!showSummary);
	};

	useEffect(
		() => {
			if (props.show) {
				document.addEventListener('click', handleClick, false);
			}
		},
		[props.show]
	);

	if (!props.show) {
		return null;
	}

	return (
		<section className={styles.list}>
			<KiList selectable>
				<TimeSeriesLink
					className={styles.item}
					col={props.col}
					tableType={props.tableType}
					handleClick={props.handleTimeSeriesColumnSelect}
					groupBy={props.groupBy}
					snapshotType={props.snapshotType}
				/>
				<VisualizationLink
					className={styles.item}
					show={props.showSummaryColumnVisualizationLink}
					handleClick={props.toggleSummaryColumnChart}
				/>
				<VisualizationLink
					className={styles.item}
					show={props.showTimeSeriesVisualizationLink}
					handleClick={props.toggleTimeSeriesChart}
				/>
				<GranularityMenu
					className={`${styles.item} ${styles.granularityMenu}`}
					col={props.col}
					handleClick={props.setGranularity}
					bands={props.bands}
				/>
				<SummaryLink handleClick={toggleSummary} />
				{showSummary && (
					// TODO check how this should work for encumbrance (calculatedDate) BLD-21849 created
					<SummaryData
						columnId={props.col.id}
						columnType={props.col.columnType}
						dataExplorer={appliedBookmark.explorerData}
						columnList={allColumns}
						snapshotDate={calculatedDateInfo?.calculatedDate}
					/>
				)}
			</KiList>
		</section>
	);
};

ColumnMenu.propTypes = {
	col: PropTypes.object.isRequired,
	show: PropTypes.bool.isRequired,
	handleColumnMenuToggle: PropTypes.func.isRequired,
	toggleSummaryColumnChart: PropTypes.func.isRequired,
	toggleTimeSeriesChart: PropTypes.func.isRequired,
	showSummaryColumnVisualizationLink: PropTypes.bool,
	showTimeSeriesVisualizationLink: PropTypes.bool,
	handleTimeSeriesColumnSelect: PropTypes.func,
	setGranularity: PropTypes.func,
	tableType: PropTypes.string,
	bands: PropTypes.object,
	groupBy: PropTypes.any,
	snapshotType: PropTypes.string,
};

export default ColumnMenu;
