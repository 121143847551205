const displayFormatter = require('./displayFormatter');

const columnNameMap = new Map([
	['name', 'Funding Vehicle'],
	['condition', 'Condition'],
	['eligible_count', 'Eligible Count'],
	['eligible_count_percent', 'Eligible Count %'],
	['eligible_balance', 'Eligible Balance'],
	['eligible_balance_percent', 'Eligible Balance %'],
	['ineligible_count', 'Ineligible Count'],
	['ineligible_count_percent', 'Ineligible Count %'],
	['ineligible_balance', 'Ineligible Balance'],
	['ineligible_balance_percent', 'Ineligible Balance %'],
]);

const columnFormatMap = new Map([
	['name', row => row.value],
	['condition', row => row.value],
	['eligible_count', row => displayFormatter.format(row.value, 'number')],
	['eligible_count_percent', row => displayFormatter.format(row.value * 100, 'highPrecisionPercent')],
	['eligible_balance', row => displayFormatter.format(row.value, 'currency')],
	['eligible_balance_percent', row => displayFormatter.format(row.value * 100, 'highPrecisionPercent')],
	['ineligible_count', row => displayFormatter.format(row.value, 'number')],
	['ineligible_count_percent', row => displayFormatter.format(row.value * 100, 'highPrecisionPercent')],
	['ineligible_balance', row => displayFormatter.format(row.value, 'currency')],
	['ineligible_balance_percent', row => displayFormatter.format(row.value * 100, 'highPrecisionPercent')],
]);

const simpleReactTableColumns = [
	{
		accessor: 'name',
		Header: columnNameMap.get('name'),
		Cell: columnFormatMap.get('name'),
		sortMethod: (a, b) => {
			if (a === 'Global') {
				return a === b ? 0 : -1; // Global is always first, unless tied
			}
			const nameA = a.toUpperCase();
			const nameB = b.toUpperCase();
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}
			return 0;
		},
	},
	{
		accessor: 'condition',
		Header: columnNameMap.get('condition'),
		Cell: columnFormatMap.get('condition'),
	},
	{
		accessor: 'eligible_count',
		Header: columnNameMap.get('eligible_count'),
		Cell: columnFormatMap.get('eligible_count'),
	},
	{
		accessor: 'eligible_count_percent',
		Header: columnNameMap.get('eligible_count_percent'),
		Cell: columnFormatMap.get('eligible_count_percent'),
	},
	{
		accessor: 'eligible_balance',
		Header: columnNameMap.get('eligible_balance'),
		Cell: columnFormatMap.get('eligible_balance'),
	},
	{
		accessor: 'eligible_balance_percent',
		Header: columnNameMap.get('eligible_balance_percent'),
		Cell: columnFormatMap.get('eligible_balance_percent'),
	},
	{
		accessor: 'ineligible_count',
		Header: columnNameMap.get('ineligible_count'),
		Cell: columnFormatMap.get('ineligible_count'),
	},
	{
		accessor: 'ineligible_count_percent',
		Header: columnNameMap.get('ineligible_count_percent'),
		Cell: columnFormatMap.get('ineligible_count_percent'),
	},
	{
		accessor: 'ineligible_balance',
		Header: columnNameMap.get('ineligible_balance'),
		Cell: columnFormatMap.get('ineligible_balance'),
	},
	{
		accessor: 'ineligible_balance_percent',
		Header: columnNameMap.get('ineligible_balance_percent'),
		Cell: columnFormatMap.get('ineligible_balance_percent'),
	},
];

module.exports = {
	columnNameMap,
	columnFormatMap,
	simpleReactTableColumns,
};
