import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import ManageBlocks from './components/ManageBlocks';
import ManageTriggers from './components/ManageTriggers';
import StepItem from './components/StepItem';
import Scenarios from './components/Scenarios';
import TopBar from './components/TopBar';
import Select from 'react-select';
import {showSnackbar} from 'state/actions/Snackbar';
import KiProgressBar from 'components/KiProgressBar';
import ContextSidebar from 'components/ContextSidebar';
import FlyoutCalculations from 'components/FlyoutCalculations';
import ContextIcons from 'components/ContextSidebar/icons';
import FlyoutManageTracks from 'components/FlyoutManageTracks';
import {fetchFundingVehicleList} from 'containers/fundingVehicleList/actions';
import {fetchFundingVehicle, fetchBlocksForFundingVehicle} from 'containers/fundingVehicle/actions';
import {fetchDataset, requeryColumnList} from 'containers/datasetList/actions';
import {fetchTracks, fetchModels} from 'containers/dealStructures/actions';
import ModelForm from './components/forms/ModelForm';
import TrackForm from './components/forms/TrackForm';
import {
	saveModel,
	deleteModel,
	getWaterfallResults,
	runWaterfall,
	persistWaterfallResults,
	deleteWaterfallResult,
} from 'api/waterfallApi';
import {fetchDates} from 'api/datasetDatesApi';
import {dateToShortDate} from 'ki-common/utils/dateHelpers';
import {debtOutputOptions, payFromOptions} from 'ki-common/options/debt';
import {getSortFuncByField} from 'ki-common/utils/sorters';
import KiConfirmModal from 'components/KiConfirmModal';
import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';
import _get from 'lodash/get';
import _countBy from 'lodash/countBy';
import {openContextSidebarPanel} from 'state/actions/App';
import {fetchTriggers} from 'api/triggersApi';
import ReactTable from 'react-table-6';
import KiTabs from 'components/KiTabs';
import KiTab from 'components/KiTabs/KiTab';
import KiButton from 'components/KiButton';
import {KiIconCsv, KiIconLink} from 'components/KiIcons';
import {defaultMinimalFieldColumnSpecification} from 'api/columnServiceApi';
import _ from 'lodash';

export class DealStructures extends Component {
	static propTypes = {
		match: PropTypes.object,
		history: PropTypes.object,
		fetchDataset: PropTypes.func.isRequired,
		showSnackbar: PropTypes.func.isRequired,
		user: PropTypes.object,
		dataset: PropTypes.object,
		fundingVehicle: PropTypes.object,
		fetchFundingVehicleList: PropTypes.func,
		fetchFundingVehicle: PropTypes.func,
		fundingVehicles: PropTypes.array,
		fetchTracks: PropTypes.func.isRequired,
		fetchModels: PropTypes.func.isRequired,
		fetchBlocksForFundingVehicle: PropTypes.func.isRequired,
		tracks: PropTypes.array.isRequired,
		models: PropTypes.array.isRequired,
		allBlocks: PropTypes.array,
		openContextSidebarPanel: PropTypes.func.isRequired,
		fees: PropTypes.array,
		tranches: PropTypes.array,
		creditSupports: PropTypes.array,
		waterfallColumns: PropTypes.array,
		requeryColumnList: PropTypes.func.isRequired,
	};

	static defaultProps = {
		fundingVehicle: {},
		fees: [],
		tranches: [],
		creditSupports: [],
		generateError: '',
		waterfallColumns: [],
	};

	state = {
		currentFundingVehicle: null,
		modelToEdit: null,
		currentModelIndex: null,
		visibleTracks: [],
		modelToDelete: null,
		isDeleteConfirmActive: false,
		triggers: [],
		scenarios: [],
		resultsColumns: [],
		resultsRows: [],
		datasetDates: [],
		selectedEntity: null,
		selectedScenarios: null,
		tabIndex: 0,
		isLoadingDateOptions: true,
		snapshotDate: moment(new Date()).format('YYYY-MM-DD'),
		generateModel: null,
		selectedOutputType: debtOutputOptions[0].value,
		message: '',
	};

	componentDidMount() {
		fetchTriggers(this.props.match.params.id).then(triggers => {
			this.setState({
				triggers: triggers,
			});
		});
		this.props.fetchDataset(this.props.match.params.id);
		this.props.requeryColumnList(this.props.match.params.id, defaultMinimalFieldColumnSpecification);
		/*
		getColumnsFromService(this.props.match.params.id, {
			sources: {
				includeWaterfallCalculations: true,
				includeWaterfallVariableColumns: true,
			},
		}).then(cols => this.setState({waterfallColumns: cols}));
		*/
		this.props.fetchTracks(this.props.match.params.id);
		return this.props.fetchFundingVehicleList().then(res => {
			let currentFundingVehicle;
			res = res.filter(fundingVehicle => fundingVehicle.datasetId === this.props.match.params.id);
			if (res.length) {
				if (!this.props.match.params.fvId) {
					currentFundingVehicle = res[0];
					this.props.history.push(
						`/dealStructures/${this.props.match.params.id}/${currentFundingVehicle._id}`
					);
				}
				if (this.props.match.params.fvId) {
					const filteredVehicles = res.filter(vf => vf._id === this.props.match.params.fvId);
					if (filteredVehicles.length === 1) {
						currentFundingVehicle = filteredVehicles[0];
					}
				}

				this.setState({currentFundingVehicle: currentFundingVehicle});
				this.props.fetchBlocksForFundingVehicle(currentFundingVehicle._id);
				this.props.fetchModels(currentFundingVehicle._id);
				this.fetchWaterfallData(currentFundingVehicle._id);
				return Promise.all([
					fetchDates(this.props.match.params.id, true, false, true),
					this.props.fetchFundingVehicle(currentFundingVehicle._id),
				])
					.then(results => {
						this.setState({
							datasetDates: _.sortBy(results[0], datasetDate => datasetDate.name.toLowerCase()),
							assetDate: results[0].find(d => d.groupId === 'collectionEndDate'),
							debtDate: results[0].find(d => d.groupId === 'reportDate'),
							generateModel: this.props.models[0] || null,
						});
					})
					.finally(() => this.setState({isLoadingDateOptions: false}));
			}
		});
	}

	fetchWaterfallData = fundingVehicleId => {
		getWaterfallResults(fundingVehicleId).then(res => {
			this.setState({
				scenarios: res,
				entities: res[0] ? res[0].explorationResponses : null,
				result: res[0] ? res[0].explorationResponses[0] : null,
				selectedEntity: res[0] ? res[0].explorationResponses[0].tag : null,
			});
		});
	};

	deleteWaterfallEntity = row => {
		deleteWaterfallResult(row._original._id).then(() => {
			this.setState({deleteScenerioItem: null, showDeleteScenerioModal: false});
			this.fetchWaterfallData(this.state.currentFundingVehicle._id);
			this.props.showSnackbar('Result was deleted.');
		});
	};

	formatResultData = res => {
		if (res) {
			const columns = res.response.columns.map((item, index) => {
				return {
					Header: item.displayName,
					accessor: `col-${index}`,
				};
			});

			const rows = res.response.rows.map(item => {
				const row = {};
				item.data.forEach((val, index) => {
					row[`col-${index}`] = val;
				});

				return row;
			});

			return {
				columns: columns,
				rows: rows,
			};
		} else {
			return {
				columns: [],
				rows: [],
			};
		}
	};

	onFundingVehicleSelected = val => {
		this.props.history.push(`/dealStructures/${val.datasetId}/${val._id}`);
		this.setState({currentFundingVehicle: val, generateError: null});
		this.props.fetchFundingVehicle(val._id);
		this.props.fetchBlocksForFundingVehicle(val._id);
		this.fetchWaterfallData(val._id);
		return this.props.fetchModels(val._id).then(models => {
			this.setState({
				tabIndex: 1,
				selectedScenarios: null,
				resultsColumns: null,
				resultsRows: null,
				generateModel: models[0] || null,
			});
		});
	};

	onSubmit = model => {
		return saveModel(model).then(() => {
			this.props.showSnackbar('Model created successfully.');
			return this.props.fetchModels(this.props.fundingVehicle._id);
		});
	};

	onSubmitBlocks = track => {
		const modelForSaving = _cloneDeep(this.props.models[this.state.currentModelIndex]);
		const targetTrackIndex = modelForSaving.tracks.findIndex(trk => trk._id === track._id);
		modelForSaving.tracks[targetTrackIndex].blocks = track.blocks;
		modelForSaving.tracks.forEach(track => {
			delete track.datasetId;
			delete track.name;
			track.blocks = track.blocks.map(block => block._id.toString());
		});
		return saveModel(modelForSaving).then(() => {
			this.props.showSnackbar('Model saved successfully.');
			return this.props.fetchModels(this.props.fundingVehicle._id);
		});
	};

	showDeleteWarning = model => {
		this.setState({
			modelToDelete: model,
			isDeleteConfirmActive: true,
		});
	};

	onDelete = () => {
		return deleteModel(this.state.modelToDelete._id).then(() => {
			this.props.showSnackbar('Model deleted successfully.');
			this.setState({
				modelToDelete: null,
				isDeleteConfirmActive: false,
			});
			return this.props.fetchModels(this.props.fundingVehicle._id);
		});
	};

	closeForm = () => {
		this.setState({modelToEdit: null, trackToEdit: null});
	};

	addTrack = () => {
		this.setState({addTrackOpen: true});
		this.props.openContextSidebarPanel('Tracks');
		setTimeout(() => {
			this.setState({addTrackOpen: false});
		}, 100);
	};

	addTrigger = () => {
		this.setState({addTriggerOpen: true});
		this.props.openContextSidebarPanel('Triggers');
		setTimeout(() => {
			this.setState({addTriggerOpen: false});
		}, 100);
	};

	addBlock = e => {
		e.stopPropagation();
		this.setState({addBlockOpen: true});
		this.props.openContextSidebarPanel('Blocks');
		setTimeout(() => {
			this.setState({addBlockOpen: false});
		}, 100);
	};

	onTriggerAdded = triggers => {
		this.setState({
			triggers: triggers,
		});
	};

	findTrigger = id => {
		const trigger = this.state.triggers.find(trigger => trigger._id === id);
		if (trigger) {
			return trigger;
		}
		return {};
	};

	toggleTrack = (index, track, model) => {
		if (this.isTrackVisible(track, model)) {
			const visibleTracks = this.state.visibleTracks.filter(id => {
				return `${track._id}-${model._id}` !== id;
			});
			this.setState({
				visibleTracks: visibleTracks,
			});
		} else {
			const visibleTracks = this.state.visibleTracks;
			visibleTracks.push(`${track._id}-${model._id}`);
			this.setState({
				visibleTracks: visibleTracks,
			});
		}
	};

	isTrackVisible = (track, model) => {
		return this.state.visibleTracks.includes(`${track._id}-${model._id}`);
	};

	getToggleClass = (track, model) => {
		if (this.isTrackVisible(track, model)) {
			return 'keyboard_arrow_down';
		} else {
			return 'keyboard_arrow_right';
		}
	};

	getCalcCols = columns => {
		return columns
			.filter(col => col.isWaterfall && col.dataType === 'numeric')
			.sort(getSortFuncByField('displayName', true));
	};

	renderStepNumber = steps => {
		const stepGroups = _countBy(steps, 'group');
		return Object.keys(stepGroups).map((key, index) => {
			return (
				<div
					className="list-group-label"
					style={{height: `${88 * stepGroups[key] + 10 * (stepGroups[key] - 1)}px`}}
					key={key}
				>
					<span>{index + 1}</span>
				</div>
			);
		});
	};

	handleGenerateClick = () => {
		this.setState({generateInProgess: 'pending'});

		runWaterfall(
			this.state.currentFundingVehicle._id,
			this.state.snapshotDate,
			this.state.assetDate.groupId,
			this.state.debtDate.groupId,
			_get(this.state.generateModel, '_id', null)
		)
			.then(() => {
				this.setState({generateInProgess: 'finished'});
				this.fetchWaterfallData(this.state.currentFundingVehicle._id);
			})
			.catch(err => {
				this.setState({generateInProgess: 'failed'});
				this.setState({generateError: err.message});
			});
	};

	handleOutputType = val => {
		const entity = this.state.selectedScenarios.explorationResponses.filter(item => item.tag === val);
		const results = entity.length > 0 ? this.formatResultData(entity[0]) : [];
		this.setState({
			isResultSecondary: false,
			selectedOutputType: val,
			selectedSubOutputType: entity.length > 0 ? entity[0].name : null,
			resultEntitys: entity,
			resultsColumns: results.columns,
			resultsRows: results.rows,
		});

		return this.setState({results: []});
	};

	isSecondaryComboVisible = () => {
		return (
			(this.state.resultEntitys && this.state.resultEntitys.length > 0 && this.state.resultEntitys[0].name) ||
			this.state.isResultSecondary
		);
	};

	secondaryResultOptions = () => {
		const items = this.state.resultEntitys.map(item => {
			return {label: item.name, value: item.name};
		});

		items.unshift({label: 'Select...', value: ''});

		return items;
	};

	handleSubOutputType = val => {
		const entity = this.state.selectedScenarios.explorationResponses.filter(item => item.name === val);
		const results = this.formatResultData(entity[0]);

		this.setState({
			isResultSecondary: true,
			selectedSubOutputType: val,
			result: entity[0],
			resultsColumns: results.columns,
			resultsRows: results.rows,
		});

		return this.setState({results: []});
	};

	showScenerioDetail = (e, row) => {
		this.setState({publishError: null});
		const results = this.formatResultData(
			row._original.explorationResponses.filter(item => debtOutputOptions[0].value === item.tag)[0]
		);
		this.setState({
			resultEntitys: [], // to not pollute combos
			isResultSecondary: false,
			tabIndex: 2,
			selectedOutputType: debtOutputOptions[0].value,
			selectedSubOutputType: null,
			selectedScenarios: row._original,
			resultsColumns: results.columns,
			resultsRows: results.rows,
		});
	};

	getPayFromName = id => {
		const item = [...this.props.creditSupports, ...payFromOptions].find(opt => opt._id === id);
		if (item && item.nameId) {
			return item.nameId;
		}
		if (item && item.columnName) {
			return item.columnName;
		}
		return '';
	};

	handlePublishResults = () => {
		this.setState({publishError: null});
		return persistWaterfallResults(this.state.selectedScenarios._id)
			.then(() => {
				this.props.showSnackbar('Results published successfully.');
			})
			.catch(error => {
				this.props.showSnackbar('Error publishing results.');
				this.setState({publishError: error ? error.message || 'unspecified' : 'Error publishing results.'});
				this.props.showSnackbar(this.state.publishError);
			});
	};

	render() {
		const entities = [...this.props.fees, ...this.props.tranches, ...this.props.creditSupports];
		const {dataset, fundingVehicle, models} = this.props;
		const isAdmin =
			this.props.user.groups.includes('SystemAdmins') || this.props.user.groups.includes('UserAdmins');
		if (!dataset.snapshots || !fundingVehicle || fundingVehicle._id !== this.props.match.params.fvId) {
			return (
				<div className="ki-panel">
					<KiProgressBar />
				</div>
			);
		}
		return (
			<div className="container-wrapper">
				<article className="funding-vehicle-container container-body">
					<TopBar
						history={this.props.history}
						id={this.props.match.params.id}
						dataset={this.props.dataset}
						isEdit={this.state.trackToEdit}
						fundingVehicle={fundingVehicle}
						tabIndex={this.state.tabIndex}
						onNavigate={() => this.setState({tabIndex: this.state.tabIndex})}
						onEdit={() =>
							this.setState({
								modelToEdit: {},
								tabIndex: this.state.tabIndex,
							})
						}
					/>
					{this.state.isLoadingDateOptions && (
						<div className="ki-panel">
							<KiProgressBar />
						</div>
					)}
					{!this.state.isLoadingDateOptions && (
						<div className={'ki-panel'}>
							<section className="structure-content-controls">
								<div className={'select-wrapper structure-fv-select'}>
									<span className={'theme-label'}>Funding Vehicle:</span>
									<Select
										placeholder="Select a Funding Vehicle..."
										value={this.state.currentFundingVehicle}
										options={this.props.fundingVehicles}
										onChange={val => this.onFundingVehicleSelected(val)}
										getOptionLabel={option => option.name}
										getOptionValue={option => option._id}
									/>
								</div>
							</section>
							<KiTabs
								index={this.state.tabIndex}
								onChange={index => {
									this.setState({tabIndex: index});
								}}
							>
								<KiTab title="Models">
									{this.state.modelToEdit &&
										!this.state.modelToEdit.name && (
											<ModelForm
												isDefault={this.props.models.length === 0}
												dataset={this.props.dataset}
												fundingVehicle={this.props.fundingVehicle}
												modelToEdit={this.state.modelToEdit || {}}
												submitMethod={this.onSubmit}
												closeForm={this.closeForm}
												tracks={this.props.tracks}
												modelsList={this.props.models}
												addTrack={this.addTrack}
												addTrigger={this.addTrigger}
												triggers={this.state.triggers}
												isReadOnly={!isAdmin}
											/>
										)}
									{this.props.models.map((model, index) => {
										if (
											this.state.modelToEdit &&
											this.state.modelToEdit.name &&
											this.state.modelToEdit._id === model._id
										) {
											return (
												<ModelForm
													dataset={this.props.dataset}
													fundingVehicle={this.props.fundingVehicle}
													modelToEdit={this.state.modelToEdit || {}}
													submitMethod={this.onSubmit}
													closeForm={this.closeForm}
													tracks={this.props.tracks}
													modelsList={this.props.models}
													addTrack={this.addTrack}
													addTrigger={this.addTrigger}
													triggers={this.state.triggers}
													isReadOnly={!isAdmin}
												/>
											);
										}
										return (
											<section key={index} style={{marginTop: '30px'}}>
												<div className="structure-block-item">
													<header>
														<div>
															<span>
																<strong>Model: </strong>
																<span>
																	{model.isDefault
																		? `${model.name} (Default)`
																		: model.name}
																</span>
															</span>
														</div>
														<div>
															<span style={{marginLeft: '3rem'}}>{`Created By: ${
																model.createdBy
															}`}</span>
															<span
																style={{marginLeft: '3rem', marginRight: '3rem'}}
															>{`Last Updated: ${dateToShortDate(
																new Date(model.lastUpdated)
															)}`}</span>
															<i
																title="Edit"
																className={'material-icons'}
																onClick={() =>
																	this.setState({
																		modelToEdit: this.props.models[index],
																	})
																}
															>
																mode_edit
															</i>
															{isAdmin && (
																<i
																	title="Delete"
																	className={'material-icons'}
																	onClick={() => this.showDeleteWarning(model)}
																>
																	delete
																</i>
															)}
														</div>
													</header>
													<div className="structure-block-contents">
														{model.tracks.map((track, index2) => {
															if (
																this.state.trackToEdit &&
																_isEqual(this.state.trackToEdit, track)
															) {
																return (
																	<TrackForm
																		key={index2}
																		trackToEdit={this.state.trackToEdit || {}}
																		submitMethod={this.onSubmitBlocks}
																		closeForm={this.closeForm}
																		tracks={this.props.tracks}
																		allBlocks={this.props.allBlocks}
																		addBlock={this.addBlock}
																	/>
																);
															}
															return (
																<React.Fragment key={index2}>
																	<header>
																		<div className="track-header">
																			<i
																				title="Expand Blocks"
																				className={'material-icons'}
																				onClick={() =>
																					this.toggleTrack(
																						index,
																						track,
																						model
																					)
																				}
																			>
																				{this.getToggleClass(track, model)}
																			</i>
																			<div className="track-header-cell">
																				<strong>Track: </strong>
																				{`${track.name} ${
																					track.isDefault ? '(Default)' : ''
																				}`}
																			</div>
																			<div>
																				<strong>Trigger: </strong>
																				{this.findTrigger(track.triggerId).name}
																			</div>
																		</div>
																		{isAdmin && (
																			<div>
																				<i
																					title="Edit"
																					className={'material-icons'}
																					onClick={() =>
																						this.setState({
																							trackToEdit: track,
																							currentModelIndex: index,
																						})
																					}
																				>
																					mode_edit
																				</i>
																			</div>
																		)}
																	</header>
																	{track.blocks.map((block, index3) => {
																		return (
																			<React.Fragment key={index3}>
																				<section
																					className="track-block-row"
																					style={{
																						borderTop: '1px solid #ccc',
																						borderBottom: this.isTrackVisible(
																							track,
																							model
																						)
																							? '1px solid #ccc'
																							: 'none',
																					}}
																				>
																					<div className="track-title">
																						<strong>Block: </strong>
																						{block.name}
																					</div>
																					<p>
																						<strong>Pay From: </strong>
																						{block.payFrom.map(
																							(payFrom, index5) => {
																								return (
																									<span key={index5}>
																										{`${this.getPayFromName(
																											payFrom
																										)}${
																											index5 !==
																											block
																												.payFrom
																												.length -
																												1
																												? ','
																												: ''
																										}  `}
																									</span>
																								);
																							}
																						)}
																					</p>
																				</section>
																				{this.isTrackVisible(track, model) && (
																					<div className="steps-list">
																						<section
																							className="steps-dnd-list-numbers"
																							style={{paddingRight: '0'}}
																						>
																							{this.renderStepNumber(
																								block.steps
																							)}
																						</section>
																						<section className="steps-dnd-list">
																							{block.steps.map(
																								(step, index) => (
																									<StepItem
																										step={step}
																										dataset={
																											dataset
																										}
																										entities={
																											entities
																										}
																										tranches={
																											this.props
																												.tranches
																										}
																										fees={
																											this.props
																												.fees
																										}
																										creditSupports={
																											this.props
																												.creditSupports
																										}
																										waterfallColumns={
																											this.props
																												.waterfallColumns
																										}
																										key={index}
																									/>
																								)
																							)}
																						</section>
																					</div>
																				)}
																			</React.Fragment>
																		);
																	})}
																</React.Fragment>
															);
														})}
													</div>
												</div>
											</section>
										);
									})}
								</KiTab>
								<KiTab title="Scenarios">
									<Scenarios
										models={models}
										onModelChange={item => this.setState({generateModel: item})}
										modelValue={this.state.generateModel}
										isLoadingDateOptions={this.state.isLoadingDateOptions}
										datasetDates={this.state.datasetDates}
										onDebtDateChange={item => this.setState({debtDate: item})}
										debtDateValue={this.state.debtDate}
										onAssetDateChange={item => this.setState({assetDate: item})}
										assetDateValue={this.state.assetDate}
										onSnapshotDateChange={val =>
											this.setState({snapshotDate: moment(val).format('YYYY-MM-DD')})
										}
										snapshotDateValue={this.state.snapshotDate}
										generateDisabled={
											this.state.generateInProgess === 'pending' || !this.state.generateModel
										}
										onGenerate={this.handleGenerateClick}
									/>
									{this.state.generateInProgess === 'failed' &&
										this.state.generateError && (
											<div style={{textAlign: 'right', marginBottom: '10px'}}>
												<p className="text-error-red">{`Generate failed - ${
													this.state.generateError
												}`}</p>
											</div>
										)}
									<ReactTable
										className={`data-explorer-table data-explorer-card`}
										showPagination={false}
										sortable={true}
										data={this.state.scenarios}
										columns={[
											{Header: 'Model', accessor: 'modelName'},
											{
												Header: 'Statement Date',
												accessor: 'statementDate',
												Cell: props => moment(props.value).format('YYYY-MM-DD'),
											},
											{
												Header: 'Debt Date',
												accessor: 'debtDate',
												Cell: props => moment(props.value).format('YYYY-MM-DD'),
											},
											{
												Header: 'Asset Date',
												accessor: 'assetDate',
												Cell: props => moment(props.value).format('YYYY-MM-DD'),
											},
											{
												Header: 'Last Run',
												accessor: 'lastRun',
												Cell: props => `${moment(props.value).format('YYYY-MM-DD HH:mm')}`,
											},
											{
												Header: 'User',
												accessor: 'userName',
												Cell: props => props.value,
											},
											{
												id: 'click-me-button',
												Cell: ({row}) => (
													<React.Fragment>
														{(this.props.user.userId === row.userName || isAdmin) && (
															<button
																onClick={() =>
																	this.setState({
																		showDeleteScenerioModal: true,
																		deleteScenerioItem: row,
																	})
																}
															>
																Delete
															</button>
														)}
														<button onClick={e => this.showScenerioDetail(e, row)}>
															Details
														</button>
													</React.Fragment>
												),
											},
										]}
										minRows={1}
									/>
								</KiTab>
								<KiTab title="Results">
									{this.state.selectedScenarios && (
										<div className="structure-block-item">
											<header>
												<div>
													<span>
														<strong>Model: </strong>
														<span>{this.state.selectedScenarios.modelName} </span>
													</span>
													<span>
														<strong>Debt Date: </strong>
														<span>
															{moment(this.state.selectedScenarios.debtDate).format(
																'MM/DD/YYYY'
															)}{' '}
														</span>
													</span>
													<span>
														<strong>Asset Date: </strong>
														<span>
															{moment(this.state.selectedScenarios.assetDate).format(
																'MM/DD/YYYY'
															)}{' '}
														</span>
													</span>
													<span>
														<strong>Statement Date: </strong>
														<span>
															{moment(this.state.selectedScenarios.statementDate).format(
																'MM/DD/YYYY'
															)}{' '}
														</span>
													</span>
													<span>
														<strong>Last Run: </strong>
														<span>
															{moment(this.state.selectedScenarios.lastRun).format(
																'MM/DD/YYYY HH:mm'
															)}{' '}
														</span>
													</span>
												</div>
												<div>
													<a
														href={`web/waterfallResults/${
															this.state.selectedScenarios._id
														}?format=csv`}
														className="icon-link"
														title="Download CSV"
														download
													>
														<KiIconCsv />
													</a>
													<a
														href={`web/waterfallResults/${
															this.state.selectedScenarios._id
														}?format=json`}
														className="icon-link"
														title="Download JSON"
														download
													>
														<KiIconLink />
													</a>
												</div>
											</header>
											<div style={{margin: '1.5rem'}}>
												<div
													style={{
														display: 'flex',
														alignItems: 'center',
													}}
												>
													<div
														style={{
															marginBottom: '2rem',
															marginRight: '2rem',
															width: '250px',
														}}
													>
														<div className="param-label">Entity</div>
														<Select
															classNamePrefix="aut-select"
															value={debtOutputOptions.find(
																item => item.value === this.state.selectedOutputType
															)}
															onChange={val => this.handleOutputType(val.value)}
															options={debtOutputOptions}
														/>
													</div>
													{this.isSecondaryComboVisible() && (
														<div
															style={{
																marginBottom: '0.5rem',
																marginRight: '2rem',
																width: '250px',
															}}
														>
															<Select
																classNamePrefix="aut-select"
																value={this.secondaryResultOptions().find(
																	item =>
																		item.value === this.state.selectedSubOutputType
																)}
																onChange={val => this.handleSubOutputType(val.value)}
																options={this.secondaryResultOptions()}
															/>
														</div>
													)}
													<div style={{marginTop: '-1rem'}}>
														<KiButton
															raised
															primary
															onClick={() => this.handlePublishResults()}
														>
															Publish
														</KiButton>
													</div>
												</div>
												{this.state.publishError && (
													<div style={{textAlign: 'right', marginBottom: '10px'}}>
														<p className="text-error-red">{`Publish failed - ${
															this.state.publishError
														}`}</p>
													</div>
												)}
												<ReactTable
													className={`data-explorer-table data-explorer-card`}
													showPagination={false}
													sortable={true}
													data={this.state.resultsRows}
													columns={this.state.resultsColumns}
													pageSize={
														this.state.resultsRows ? this.state.resultsRows.length : 1
													}
													minRows={1}
												/>
											</div>
										</div>
									)}
								</KiTab>
							</KiTabs>
						</div>
					)}
				</article>
				<ContextSidebar
					items={[
						{
							name: 'Tracks',
							icon: <ContextIcons.Tracks />,
							element: <FlyoutManageTracks user={this.props.user} panelMode={this.state.addTrackOpen} />,
						},
						{
							name: 'Blocks',
							icon: <ContextIcons.MaterialIcon name="view_module" />,
							element: (
								<ManageBlocks
									user={this.props.user}
									fundingVehicle={this.props.fundingVehicle}
									addBlockOpen={this.state.addBlockOpen}
									waterfallColumns={this.props.waterfallColumns}
								/>
							),
						},
						{
							name: 'Calculations',
							icon: <ContextIcons.CalculationsIcon />,
							element: <FlyoutCalculations user={this.props.user} />,
						},
						{
							name: 'Triggers',
							icon: <ContextIcons.MaterialIcon name="playlist_add_check" />,
							element: (
								<ManageTriggers
									user={this.props.user}
									datasetId={this.props.dataset.datasetId}
									panelMode={this.state.addTriggerOpen}
									onTriggerAdded={this.onTriggerAdded}
								/>
							),
						},
					]}
					currentItem="Tracks"
				/>
				<KiConfirmModal
					header="Delete Scenario"
					message="Are you sure you want to delete this scenerio?"
					acceptFunc={() => this.deleteWaterfallEntity(this.state.deleteScenerioItem)}
					rejectFunc={() => this.setState({showDeleteScenerioModal: false, deleteScenerioItem: null})}
					acceptLabel="Delete"
					rejectLabel="Cancel"
					active={this.state.showDeleteScenerioModal}
				/>
				<KiConfirmModal
					header="Delete Model"
					message="Are you sure you want to delete this model?"
					acceptFunc={() => this.onDelete()}
					rejectFunc={() => this.setState({isDeleteConfirmActive: false, modelToDelete: null})}
					acceptLabel="Delete"
					rejectLabel="Cancel"
					active={this.state.isDeleteConfirmActive}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	fundingVehicles: state.fundingVehicleList.data.filter(
		fv => fv.datasetId === ownProps.match.params.id && fv.name !== 'Unencumbered'
	),
	user: state.user,
	dataset: state.datasetList.selected,
	fundingVehicle: state.fundingVehicle.selected,
	tracks: state.waterfall.tracks,
	models: state.waterfall.models,
	fees: state.fundingVehicle.selectedSettings.fees,
	tranches: state.fundingVehicle.selectedSettings.debt,
	creditSupports: state.fundingVehicle.selectedSettings.creditSupports,
	allBlocks: state.fundingVehicle.structures.allBlocks,
	waterfallColumns: state.datasetList.columnList.filter(c => c.isWaterfall),
});

const mapDispatchToProps = () => ({
	fetchTracks,
	fetchDataset,
	requeryColumnList,
	fetchFundingVehicle,
	fetchFundingVehicleList,
	fetchBlocksForFundingVehicle,
	showSnackbar,
	fetchModels,
	openContextSidebarPanel,
});

export default connect(
	mapStateToProps,
	mapDispatchToProps()
)(DealStructures);
