// Globals
import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useParams} from 'react-router';
import _ from 'lodash';
import moment from 'moment';
import ReactTable from 'react-table-6';
import Select from 'react-select';
import socketManager from 'socket';

// Project imports
import {apiUtils, reportsApi} from 'api';
const apiUrl = apiUtils.apiUrl;
import {PrevComponent, NextComponent} from 'containers/debt/components/DebtResultsTable/TableComponents';
import {fetchDatasetList, fetchDataset} from 'containers/datasetList/actions';
import {showSnackbar} from 'state/actions/Snackbar';
import {fetchMessages} from 'api/submissionsApi';
import KiAppBar from 'components/KiAppBar';
import KiFontIcon from 'components/KiFontIcon';
import KiButton from 'components/KiButton';
//import {dateToShortDate} from 'ki-common/utils/dateHelpers';

// Local imports
import {momentDateFilter, simpleTextFilter} from '../../utils/filteringUtil';
import styles from './logs.theme.scss';
import LogInformationOverlay from './LogInformationOverlay';
import LogStatusAvatar from './components/LogStatusAvatar';
import './tableStyles.scss';

/**
 * [Logs description]
 */
function Logs() {
	const history = useHistory();
	const dispatch = useDispatch();
	const app = useSelector(state => state.app);
	const {datasetId} = useParams();
	const datasetList = useSelector(state => state.datasetList.data);
	const dataset = useSelector(state => state.datasetList.selected);
	const datasetLoading = useSelector(state => state.datasetList.isLoading);
	// const isAdmin = useSelector(state => state.user.groups.includes('SystemAdmins'));
	const [informationOverlayActive, setInformationOverlayActive] = useState(false);
	const [informationOverlayStatusData, setInformationOverlayStatusData] = useState({});
	const [informationOverlayDetailData, setInformationOverlayDetailData] = useState({});
	const [messageData, setMessageData] = useState([]);
	const [endDate, setEndDate] = useState();
	const [tableLoading, setTableLoading] = useState(false);
	const [canceledReports, setCanceledReports] = useState([]);
	//const [localDataset, setLocalDataset] = useState({});

	const datePickerOptions = [
		{
			value: moment()
				.subtract(7, 'days')
				.format('YYYY-MM-DD'),
			label: 'Last 7 Days',
		},
		{
			value: moment()
				.subtract(30, 'days')
				.format('YYYY-MM-DD'),
			label: 'Last 30 Days',
		},
		{
			value: moment()
				.subtract(3, 'months')
				.format('YYYY-MM-DD'),
			label: 'Last 3 Months',
		},
		{
			value: moment()
				.subtract(6, 'months')
				.format('YYYY-MM-DD'),
			label: 'Last 6 Months',
		},
		{
			value: moment()
				.subtract(12, 'months')
				.format('YYYY-MM-DD'),
			label: 'Last 12 Months',
		},
	];

	useEffect(() => {
		async function updateListener(payload) {
			// Only reload if the message is about an update to the same dataset
			if (payload === datasetId) {
				setTableLoading(true);
				const data = await fetchMessages(datasetId, endDate || datePickerOptions[0].value);
				setMessageData(data);
				setTableLoading(false);
				//console.log(`socket listener - updated message data ${data.length} messages`);
			}
		}
		socketManager.on('message_log', updateListener, 'messages');

		return function cleanup() {
			socketManager.off('message_log');
		};
	}, []);

	// On Mount
	useEffect(() => {
		document.title = `${app.kiVersion} - Logs`;
		async function fetchDatasetsData() {
			await dispatch(fetchDatasetList());
		}
		async function fetchDatasetData() {
			await Promise.all([dispatch(fetchDataset(datasetId)) /*,
				dispatch(requeryColumnList(datasetId,{}))*/]);
		}
		async function fetchMessageData() {
			setTableLoading(true);
			const data = await fetchMessages(datasetId, endDate || datePickerOptions[0].value);
			setMessageData(data);
			setTableLoading(false);
		}
		/*
		 RJH 22.05.03: I think this was checking 'columns' to determine if the dataset was loaded already
		 I changed this to 'category' as columns are seemingly not used for any other reason in this form
		 */
		if (!datasetList.length || !dataset.category) {
			fetchDatasetsData();
		}
		fetchDatasetData();
		try {
			//setLocalDataset(dataset);
			fetchMessageData(datasetId);
		} catch (e) {
			setMessageData([]);
			setTableLoading(false);
		}

		setEndDate(datePickerOptions[0].value);
	}, []);

	useEffect(
		() => {
			async function fetchMessageData() {
				setTableLoading(true);
				const data = await fetchMessages(datasetId, endDate || datePickerOptions[0].value);
				setMessageData(data);
				setTableLoading(false);
			}
			// RJH 22.05.03 this was also previously looking at 'columns' (see comment in 'useEffect' above)
			if (dataset.category) {
				//setLocalDataset(dataset);
				fetchMessageData(dataset.datasetId);
			}
		},
		[dataset]
	);

	// const simpleTextFilter = (filter, row) => {
	// 	const test = _.get(filter, 'value', '').toLowerCase();
	// 	const rowValue = _.get(row, filter.id, '').toLowerCase();
	// 	return rowValue.includes(test);
	// };

	// const onFromDateChange = val => {

	// }

	const getStatusCell = params => {
		const {row} = params;
		return (
			<div className="submission-stages" style={{textAlign: 'left'}}>
				<LogStatusAvatar status={row._original.status} />
			</div>
		);
	};

	// simpleTextFilter didn't work with accessor set to '_original.status.name
	const statusFilter = (filter, row) => {
		const test = _.get(filter, 'value', '').toLowerCase();
		const rowValue = row?._original?.status?.name || '';
		return rowValue.toLowerCase().includes(test);
	};

	const statusSort = (rowA, rowB) => {
		return rowA.status.name.localeCompare(rowB.status.name);
	};

	const uploadLink = (processName, submissionId) => {
		if (processName === 'Submission') {
			return `/datasets/${dataset.datasetId}/submission/${submissionId}`;
		} else {
			return `/associatedDataList/${dataset.datasetId}`;
		}
	};

	const downloadLink = (processName, submissionId) => {
		if (processName === 'Submission') {
			return `${apiUrl}/submission/downloadSubmission/${submissionId}`;
		} else {
			return `${apiUrl}/associatedData/downloadAssociatedData/${submissionId}`;
		}
	};

	const cancelReport = individualReportId => {
		setCanceledReports(array => [...array, individualReportId]);
		return reportsApi.cancelIndividualReport(individualReportId).then(() => {
			dispatch(showSnackbar('Report generation successfully canceled.'));
		});
	};

	const renderRowControls = params => {
		const {row} = params;
		const infoIcon = (
			<div title="Information" style={{marginRight: '1rem'}}>
				<KiFontIcon
					className="list-icon-btn"
					value="info"
					onClick={() => {
						setInformationOverlayStatusData(row._original.status);
						setInformationOverlayDetailData(row._original.details);
						setInformationOverlayActive(true);
					}}
				/>
			</div>
		);

		let controls = '';
		const individualReportId = _.get(row, '_original.details.individualReportId', null);
		const errorMessage = _.get(row, '_original.status.errorMessage', '');
		const successMessage = _.get(row, '_original.status.successMessage', '');
		if (_.has(row, '_original.details.submissionId')) {
			controls = (
				<React.Fragment>
					<div title="Submission" style={{marginRight: '1rem'}}>
						<KiFontIcon
							className="list-icon-btn"
							value="cloud_upload"
							onClick={() => {
								history.push(uploadLink(row._original.processName, row._original.details.submissionId));
							}}
						/>
					</div>
					<div title="Download File">
						<a
							className={styles.iconLink}
							href={downloadLink(row._original.processName, row._original.details.submissionId)}
							title={'Download File'}
						>
							<KiFontIcon className="list-icon-btn" value="cloud_download" />
						</a>
					</div>
				</React.Fragment>
			);
		} else if (individualReportId) {
			// If this is a report row and it is not in a final state then add cancel button
			if (!errorMessage && !successMessage) {
				controls = (
					<KiButton
						className={styles.cancelReportButton}
						primary
						type="submit"
						label="Cancel"
						disabled={canceledReports.includes(individualReportId)}
						onClick={() => cancelReport(individualReportId)}
					/>
				);
			}
		}

		return (
			<div
				style={{
					display: 'flex',
					flexGrow: 1,
					flexDirection: 'row',
					alignItems: 'left',
					justifyContent: 'flex-start',
				}}
			>
				{infoIcon}
				{controls}
			</div>
		);
	};

	return (
		<div className="container-wrapper">
			<article className="funding-vehicle-container container-body">
				<header>
					<KiAppBar className="top-bar">
						<div className="top-bar-breadcrumb">
							<h1 className="link" onClick={() => history.push('/datasets')}>{`Datasets`}</h1>
							<h1>{` > ${!datasetLoading ? dataset.name : '...'}`}</h1>
							<h1>{` > Logs`}</h1>
						</div>
						<div className={styles.datasetSelector}>
							<p>Dataset:</p>
							<Select
								value={dataset._id ? dataset : null}
								isClearable={false}
								options={datasetList}
								isLoading={datasetLoading}
								onChange={val => {
									dispatch(fetchDataset(val.datasetId));
									history.push(`/datasets/${val.datasetId}/logs`);
								}}
								getOptionLabel={option => option.name}
								getOptionValue={option => option._id}
							/>
						</div>
					</KiAppBar>
				</header>
				<div className={'ki-panel'}>
					<React.Fragment>
						<div className={styles.stateWrapper}>
							<Select
								classNamePrefix="aut-select"
								isClearable={false}
								isSearchable={false}
								options={datePickerOptions}
								value={datePickerOptions.find(opt => opt.value === endDate)}
								onChange={async option => {
									setTableLoading(true);
									setEndDate(option.value);
									const data = await fetchMessages(dataset.datasetId, option.value);
									setMessageData(data);
									setTableLoading(false);
								}}
							/>
						</div>
						<ReactTable
							className="data-explorer-card data-explorer-table debt-explorer-table log-table"
							PreviousComponent={PrevComponent}
							NextComponent={NextComponent}
							showPagination={true}
							sortable={true}
							filterable={true}
							loading={tableLoading} // add loading var
							data={messageData}
							defaultSorted={[{id: 'actionDate', desc: true}]}
							columns={[
								{
									Header: 'Date',
									accessor: 'actionDate',
									Cell: params => moment(params.value).format('YYYY-MM-DD, h:mm:ss a'),
									defaultSortDesc: true,
									filterMethod: momentDateFilter('YYYY-MM-DD, h:mm:ss a'),
								},
								{Header: 'Process', accessor: 'processName', filterMethod: simpleTextFilter},
								{
									Header: 'Snapshot/Statement Date',
									accessor: 'snapshotDate',
									filterMethod: simpleTextFilter,
								},
								{Header: 'Submitted By', accessor: 'userName', filterMethod: simpleTextFilter},
								{
									Header: 'Status',
									accessor: '',
									minWidth: 150,
									maxWidth: 200,
									Cell: getStatusCell,
									defaultSortDesc: true,
									filterMethod: statusFilter,
									sortMethod: statusSort,
								},
								{
									Header: 'Details',
									id: 'settings-button',
									minWidth: 140,
									maxWidth: 140,
									filterable: false,
									Cell: renderRowControls,
								},
							]}
							minRows={5}
						/>
						<LogInformationOverlay
							isActive={informationOverlayActive}
							statusData={informationOverlayStatusData}
							detailData={informationOverlayDetailData}
							setInformationOverlayActive={setInformationOverlayActive}
						/>
					</React.Fragment>
				</div>
			</article>
		</div>
	);
}

Logs.propTypes = {};

Logs.defaultProps = {};

export default Logs;
