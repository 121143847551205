export const EXPLORER_SORT_COLUMN_SET = 'EXPLORER_SORT_COLUMN_SET';
export const EXPLORER_SET_STATEMENT_DATE = 'EXPLORER_SET_STATEMENT_DATE';
export const EXPLORER_SET_DATE_CONTEXT = 'EXPLORER_SET_DATE_CONTEXT';
export const EXPLORER_SET_IS_FIXED_DATE = 'EXPLORER_SET_IS_FIXED_DATE';
export const EXPLORER_PAGE_NUMBER_SET = 'EXPLORER_PAGE_NUMBER_SET';
export const EXPLORER_PAGE_SIZE_SET = 'EXPLORER_PAGE_SIZE_SET';
export const EXPLORER_TABLE_TYPE_SET = 'EXPLORER_TABLE_TYPE_SET';
export const EXPLORER_DATSETID_SET = 'EXPLORER_DATSETID_SET';
export const EXPLORER_SET_COHORT_COLUMN = 'EXPLORER_SET_COHORT_COLUMN';
export const EXPLORER_BREADCRUMBS_CLEAR = 'EXPLORER_BREADCRUMBS_CLEAR';
export const EXPLORER_BREADCRUMBS_SET = 'EXPLORER_BREADCRUMBS_SET';
export const EXPLORER_BREADCRUMBS_UPDATE = 'EXPLORER_BREADCRUMBS_UPDATE';
export const EXPLORER_BREADCRUMBS_DELETE = 'EXPLORER_BREADCRUMBS_DELETE';
export const EXPLORER_BREADCRUMBS_LOAD = 'EXPLORER_BREADCRUMBS_LOAD';
export const EXPLORER_SET_BUCKET = 'EXPLORER_SET_BUCKET';
export const EXPLORER_SET_GRANULARITY = 'EXPLORER_SET_GRANULARITY';
export const EXPLORER_DATA_CLEAR = 'EXPLORER_DATA_CLEAR';
export const EXPLORER_SET_TIMESERIES_COLUMN = 'EXPLORER_SET_TIMESERIES_COLUMN';
export const EXPLORER_SET_TIMESERIES_RANGE = 'EXPLORER_SET_TIMESERIES_RANGE';
export const EXPLORER_SET_TIMESERIES_PERIOD = 'EXPLORER_SET_TIMESERIES_PERIOD';
export const EXPLORER_COLUMNS_SET = 'EXPLORER_COLUMNS_SET';
export const EXPLORER_DATA_FETCH = 'EXPLORER_DATA_FETCH';
export const EXPLORER_LOAD_TIMESERIES = 'EXPLORER_LOAD_TIMESERIES';
export const EXPLORER_QUICKFILTERS_SCENARIO = 'EXPLORER_QUICKFILTERS_SCENARIO';
export const EXPLORER_QUICKFILTERS_SCENARIO_TYPE = 'EXPLORER_QUICKFILTERS_SCENARIO_TYPE';
export const EXPLORER_QUICKFILTERS_FUNDINGVEHICLE = 'EXPLORER_QUICKFILTERS_FUNDINGVEHICLE';
export const EXPLORER_QUICKFILTERS_POOL = 'EXPLORER_QUICKFILTERS_POOL';
export const EXPLORER_QUICKFILTERS_HYPO_POOL = 'EXPLORER_QUICKFILTERS_HYPO_POOL';
export const EXPLORER_QUICKFILTERS_HYPO_FUNDINGVEHICLE = 'EXPLORER_QUICKFILTERS_HYPO_FUNDINGVEHICLE';
export const EXPLORER_APPLY_BOOKMARK_TO_STATE = 'EXPLORER_APPLY_BOOKMARK_TO_STATE';
export const EXPLORER_SNAPSHOT_TYPE = 'EXPLORER_SNAPSHOT_TYPE';
export const EXPLORER_FILTERS_SET = 'EXPLORER_FILTERS_SET';
export const EXPLORER_COLUMNS_UPDATE_ONE = 'EXPLORER_COLUMNS_UPDATE_ONE';
export const EXPLORER_SET_BANDS = 'EXPLORER_SET_BANDS';
export const EXPLORER_SET_GROUPBY = 'EXPLORER_SET_GROUPBY';
export const EXPLORER_SET_MAXRECORDS = 'EXPLORER_SET_MAXRECORDS';
export const VIEW_STARTINCLUSIVE_SET = 'VIEW_STARTINCLUSIVE_SET';
export const EXPLORER_CALCULATEDDATE_SET = 'EXPLORER_CALCULATEDDATE_SET';
export const EXPLORER_DATECONTEXTNAME_SET = 'EXPLORER_DATECONTEXTNAME_SET';
