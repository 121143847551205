import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {format} from 'ki-common/utils/displayFormatter';
import options from 'ki-common/options';
import ReactTable from 'react-table';
import {ParsedErrorMessage, LoadingComponent, PrevComponent, NextComponent, EditableTableCell} from './TableComponents';
import {buildCalculation} from 'ki-common/utils/explorerUtils';

export class DebtResultsTable extends Component {
	static propTypes = {
		error: PropTypes.object,
		dataRows: PropTypes.array,
		dataColumns: PropTypes.array,
		settings: PropTypes.object,
		isLoading: PropTypes.bool,
		searchTerm: PropTypes.string,
		drillDownFundingVehicle: PropTypes.func.isRequired,
		onSortChange: PropTypes.func.isRequired,
		updateManulaInputDebtData: PropTypes.func.isRequired,
		allTemplates: PropTypes.array,
		tableDataDirty: PropTypes.bool,
	};

	static defaultProps = {
		dataRows: [],
		dataColumns: [],
		settings: {},
		isLoading: false,
		searchTerm: '',
	};

	filterRowsBySearch = row => {
		if (!this.props.searchTerm.length) {
			return true;
		}
		const textToMatch = this.props.searchTerm.toUpperCase();
		const isMatched = row.data.filter(cell => {
			return cell.toUpperCase().indexOf(textToMatch) !== -1;
		});
		return isMatched.length;
	};

	sortSelected = col => {
		let order = 'asc';
		let id = col.id || col._id;
		let calculation = col.calculation;
		if (col.sortOrder) {
			if (col.sortOrder === 'asc') {
				order = 'desc';
			} else {
				id = '';
				order = '';
				calculation = '';
			}
		}
		return this.props.onSortChange(id, order, calculation);
	};

	render() {
		const {error, dataRows = [], dataColumns = [], settings = {}, isLoading, drillDownFundingVehicle} = this.props;

		/*
		const dataColumnsAvailable = dataColumns.filter(col => {
			return col;
		});

		if (dataColumnsAvailable.length === 0) {
			return <div />;
		}
		*/

		if (error) {
			return (
				<ParsedErrorMessage
					stackTrace={_.get(error, 'data.error.error')}
					message={error.message}
					feRequestBody={_.get(error, 'data.requestBody')}
					feRequestURI={`${document.baseURI}web/debt/explore`}
					feRequestMethod="POST"
					beRequestBody={_.get(error, 'data.error.options.json')}
					beRequestURI={_.get(error, 'data.error.options.uri')}
					beRequestMethod="POST"
				/>
			);
		}

		return (
			<ReactTable
				className="-highlight data-explorer-table debt-explorer-table"
				sortable={false}
				minRows={1}
				loading={isLoading}
				PreviousComponent={PrevComponent}
				NextComponent={NextComponent}
				LoadingComponent={LoadingComponent}
				data={dataRows.filter(this.filterRowsBySearch)}
				noDataText={isLoading ? '' : 'No results found'}
				columns={dataColumns.map((col, idx) => {
					return {
						...col,
						// show: !(idx === 0 && settings.cohortColumn === 'debt' && !settings.fundingVehicle),
						minWidth: 120,
						maxWidth: 180,
						Header: () => {
							return (
								<div className="table-header" onClick={this.sortSelected.bind(this, col)}>
									<div className="table-header-title">
										<div className="title-wrapper">
											<div>
												<div
													className="display-name"
													style={{whiteSpace: 'pre'}}
													title={col.displayName}
												>
													{col.displayName}
												</div>
											</div>
											<div className="calculation">{buildCalculation(col.calculation)}</div>
										</div>
										<div className={'sort-arrows'} style={{cursor: 'pointer'}}>
											<i className={`material-icons ${col.sortOrder === 'asc' && 'active'}`}>
												arrow_drop_up
											</i>
											<i className={`material-icons ${col.sortOrder === 'desc' && 'active'}`}>
												arrow_drop_down
											</i>
										</div>
									</div>
								</div>
							);
						},
						id: `${col.id}-${idx}`,
						accessor: d => d.data[idx],
						Cell: cell => {
							if (col.displayName === 'Funding Vehicle Name') {
								if (settings.cohortColumn !== 'debt') {
									return (
										<div
											className="table-cell"
											onClick={() => drillDownFundingVehicle(cell.value)}
											style={{
												textDecoration: 'underline',
												color: 'blue',
												cursor: 'pointer',
											}}
										>
											{format(cell.value, col.displayFormat)}
										</div>
									);
								}
								return <div className="table-cell">{format(cell.value, col.displayFormat)}</div>;
							}

							const targetTemplate = this.props.allTemplates.find(t => t._id === col._id);
							const targetAccount = this.props.allTemplates
								.filter(t => t.beginningBalanceCol && t.endingBalanceCol)
								.find(t => t._id === col.accountId);
							const targetManual = this.props.allTemplates.find(t => t._id === col._id && !t.isAccount);
							let inputPermitted = true;
							if (targetAccount) {
								inputPermitted =
									targetAccount.isActive !== false &&
									!_.has(targetTemplate, 'sourceId') &&
									(targetAccount.fundingVehicles[0] === 'all' ||
										targetAccount.fundingVehicles.includes(
											this.props.settings.quickFilters.fundingVehicleId
										));
							}
							if (targetManual && targetManual.fundingVehicles) {
								inputPermitted =
									targetManual.fundingVehicles[0] === 'all' ||
									targetManual.fundingVehicles.includes(
										this.props.settings.quickFilters.fundingVehicleId
									);
							}
							if (
								settings.viewType === 'activity' &&
								!_.has(targetTemplate, 'sourceId') &&
								(col.columnType || '').match(/^debtInput(FV|Tranche|CreditSupport|Fee|Trigger)$/) &&
								(targetTemplate &&
									!targetTemplate.isAccountBalance &&
									targetTemplate.isActive !== false) &&
								inputPermitted
							) {
								return (
									<EditableTableCell
										columnName={col.columnName}
										index={cell.index}
										data={cell.value}
										updateManulaInputDebtData={this.props.updateManulaInputDebtData}
										tableDataDirty={this.props.tableDataDirty}
										dataType={col.dataType}
									/>
								);
							}

							if (col.columnType === 'debt' && col.columnName === 'accrualMethod') {
								const optionMatch = options.accrualMethodOptions.find(
									({value}) => value === cell.value
								);
								const label = (optionMatch && optionMatch.label) || cell.value;
								return <div className="table-cell">{label}</div>;
							}
							if (col.dataType === 'numeric') {
								return (
									<div className="table-cell">
										{format(parseFloat(cell.value), col.displayFormat)}
									</div>
								);
							}

							return <div className="table-cell">{format(cell.value, col.displayFormat)}</div>;
						},
					};
				})}
			/>
		);
	}
}

export default DebtResultsTable;
