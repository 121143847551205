import PropTypes from 'prop-types';
import React, {Component} from 'react';
import uuidV4 from 'uuid/v4';
import 'react-table-6/react-table.css';
import {ExplorerTableColumn} from './ExplorerTableColumns';
import {buildTitle} from 'ki-common/utils/explorerUtils';
import _isEqual from 'lodash/isEqual';
import ReactTable from 'react-table-6';
import {ExplorerTableCellRT} from './ExplorerTableCell';
import './ExplorerTable.scss';
import KiFontIcon from 'components/KiFontIcon';
import KiProgressBar from 'components/KiProgressBar';

export const LoadingComponent = ({loading}) => {
	if (!loading) {
		return false;
	}
	return (
		<div className="-loading -active" style={{zIndex: 0}}>
			<KiProgressBar className={'keep-flex'} />
		</div>
	);
};
LoadingComponent.propTypes = {
	loading: PropTypes.bool,
};

export class ExplorerTable extends Component {
	static propTypes = {
		data: PropTypes.shape({
			columns: PropTypes.array,
			rows: PropTypes.array,
			meta: PropTypes.object,
			selectedSummaryColumn: PropTypes.any,
			displayName: PropTypes.string,
			tableType: PropTypes.string,
		}),
		columnList: PropTypes.array,
		dataError: PropTypes.any,
		drillIntoAsset: PropTypes.func,
		drillIntoBucket: PropTypes.func,
		drillIntoTimeSeries: PropTypes.func,
		setSortColumn: PropTypes.func,
		setTimeSeriesColumn: PropTypes.func,
		setTimeSeriesPeriod: PropTypes.func,
		setTimeSeriesRange: PropTypes.func,
		handleGranularityUpdate: PropTypes.func,
		timeSeriesGranularity: PropTypes.array,
		assetCountColumn: PropTypes.object,
		isLoading: PropTypes.bool,
		totalRowCount: PropTypes.number,
		activePage: PropTypes.number,
		pageSize: PropTypes.number,
		onPageNumberChange: PropTypes.func.isRequired,
		onPageSizeChange: PropTypes.func.isRequired,
		bands: PropTypes.object,
		setViewHasChanges: PropTypes.func,
		maxRecords: PropTypes.string,
		groupBy: PropTypes.any,
		snapshotType: PropTypes.string,
		startInclusive: PropTypes.bool,
		timeSeriesColumn: PropTypes.object,
		calculatedDate: PropTypes.string,
		dateContextName: PropTypes.string,
		requestedStatementDate: PropTypes.string,
	};

	state = {
		drillClickCount: 0,
	};

	componentDidUpdate(prevProps) {
		// Only reset the drill clicks once the data has changed
		if (!_isEqual(this.props.data, prevProps.data)) {
			this.setState({drillClickCount: 0});
		}
	}

	findDrillableSummaryColumnIndex = (columns = [], tableType) =>
		// columns.findIndex(c => c.calculation === 'SUM' && c.columnType === 'aggregate' && tableType !== 'timeSeries')
		columns.findIndex(
			c =>
				c.assetColumnId === this.props.assetCountColumn._id &&
				c.columnType === 'aggregate' &&
				c.calculation === 'SUM' &&
				tableType !== 'timeSeries'
		);

	handleTimeSeriesColumnSelect = columnName => {
		const monthlyGranularityExists = this.props.timeSeriesGranularity.find(g => g.value === 'monthly');
		this.props.setTimeSeriesColumn({
			id: columnName,
			allColumns: this.props.columnList,
		});
		this.props.setTimeSeriesPeriod(
			monthlyGranularityExists ? 'monthly' : this.props.timeSeriesGranularity[0].value
		);
		this.props.setTimeSeriesRange('last_6_periods');
		this.props.drillIntoTimeSeries();
		this.props.setViewHasChanges(true);
	};

	setStateAsync(state) {
		return new Promise(resolve => {
			this.setState(state, resolve);
		});
	}

	handleDrillableCellClick = async (cellValue, rowMetaData, rowIndex) => {
		await this.setStateAsync({drillClickCount: this.state.drillClickCount + 1});
		if (this.state.drillClickCount > 1) {
			return null;
		}
		const id = uuidV4();
		const rows = this.props.data.rows || [];
		const buckets = rows.map((data, index) => ({
			id: id,
			type: 'drill',
			value: index,
			label:
				data.meta.bucket.value && data.meta.bucket.value.length
					? data.meta.bucket.value
					: `${data.meta.bucket.min} - ${data.meta.bucket.max}`,
			bucket: {
				min: data.meta.bucket.min,
				max: data.meta.bucket.max,
				value: data.meta.bucket.value,
			},
		}));
		const breadcrumb = {
			id: id,
			type: 'drill',
			currValue: rowIndex,
			options: buckets,
		};
		this.props.drillIntoAsset(rowMetaData.bucket, breadcrumb);
		this.props.setViewHasChanges(true);
	};

	handleBucketClick = async (cellValue, rowMetaData, rowIndex, cellIndex) => {
		await this.setStateAsync({drillClickCount: this.state.drillClickCount + 1});
		if (this.state.drillClickCount > 1) {
			return null;
		}
		const id = uuidV4();
		const rows = this.props.data.rows || [];
		const columns = this.props.data.columns || [];
		const clickedColumn = columns[cellIndex];
		const buckets = rows.map((data, index) => ({
			id: id,
			type: 'drill',
			value: index,
			label:
				data.meta.bucket.value && data.meta.bucket.value.length
					? data.meta.bucket.value
					: `${data.meta.bucket.min} - ${data.meta.bucket.max}`,
			bucket: {
				min: data.meta.bucket.min,
				max: data.meta.bucket.max,
				value: data.meta.bucket.value,
			},
			granularity: clickedColumn.nextGranularity,
		}));
		const breadcrumb = {
			id: id,
			type: 'drill',
			currValue: rowIndex,
			options: buckets,
			prevGranularity: clickedColumn.granularity,
		};
		this.props.drillIntoBucket(rowMetaData.bucket, breadcrumb, clickedColumn.nextGranularity);
		this.props.setViewHasChanges(true);
	};

	getSummaryColumnChartData = (columnName, columnCalculation) => {
		const rows = this.props.data.rows || [];
		const columns = this.props.data.columns || [];
		const columnIndex = columns.findIndex(
			col => col.columnName === columnName && col.calculation === columnCalculation
		);
		const cohortName = columns[0].displayName;
		const selectedSummaryColumn = columns[columnIndex];
		const summaryColumnName = buildTitle(
			selectedSummaryColumn.displayName,
			selectedSummaryColumn.calculation,
			selectedSummaryColumn.calculateColumn
		);
		const categories = rows.map(data => {
			const value = data.meta.bucket.value; //eslint-disable-line prefer-destructuring
			const bucket = `${data.meta.bucket.min} - ${data.meta.bucket.max}`;
			return value || bucket;
		});
		const seriesData = rows.map(row => Number(row.data[columnIndex]));

		return {
			chartType: 'column',
			categories,
			series: [
				{
					name: `${cohortName}`,
					data: seriesData,
				},
			],
			chartTitle: `${summaryColumnName} by ${cohortName}`,
			xTitle: '',
			yTitle: `${summaryColumnName}`,
		};
	};

	getTimeSeriesChartData = () => {
		const rows = this.props.data.rows || [];
		const columns = this.props.data.columns || [];
		const selectedSummaryColumn = this.props.data.selectedSummaryColumn || '';

		// Categories are dates
		let categories = columns.map(data => `${data.displayName}`);
		categories = categories.slice(1); // Remove cohort column

		// // Multiple series, one for each bucket
		const seriesData = rows.map(row => {
			let series = row.data.slice(1); // Remove cohort column
			series = series.map(entry => Number(entry));
			return {
				name: `${row.data[0]}`,
				data: series,
			};
		});

		let yTitle = '';

		if (selectedSummaryColumn[0]) {
			yTitle = buildTitle(
				selectedSummaryColumn[0].displayName,
				selectedSummaryColumn[0].calculation,
				selectedSummaryColumn[0].calculateColumn
			);
		}

		return {
			chartType: 'column',
			categories,
			series: seriesData,
			chartTitle: 'Time Series',
			xTitle: columns[0] ? columns[0].displayName : '',
			yTitle: yTitle,
			legend: {verticalAlign: 'bottom', floating: false},
		};
	};

	getTimeseriesAllChartData = () => {
		const rows = this.props.data.rows || [];
		const columns = this.props.data.columns || [];

		const yTitle = '';

		const categories = rows.map(data => {
			const value = data.meta.bucket.value; //eslint-disable-line prefer-destructuring
			const bucket = `${data.meta.bucket.min} - ${data.meta.bucket.max}`;
			return value || bucket;
		});

		const seriesData = columns.slice(1).map((col, index) => {
			return {
				id: col.id,
				name: col.displayName, //buildTitle(col.displayName, col.calculation, col.calculateColumn),
				data: rows.map(row => row.data[index + 1]),
			};
		});

		return {
			chartType: 'column',
			categories,
			series: seriesData,
			chartTitle: 'Time Series',
			xTitle: columns[0] ? columns[0].displayName : '',
			yTitle: yTitle,
			legend: {verticalAlign: 'bottom', floating: false},
		};
	};

	PrevComponent = () => {
		const isDisabled = this.props.activePage === 1;
		return (
			<div className={'pagination'}>
				<li className={isDisabled ? 'disabled' : ''}>
					<KiFontIcon
						value="first_page"
						className="pager-icon"
						onClick={() => (isDisabled ? null : this.props.onPageNumberChange(1))}
					/>
				</li>
				<li className={isDisabled ? 'disabled' : ''}>
					<KiFontIcon
						value="chevron_left"
						className="pager-icon"
						onClick={() => (isDisabled ? null : this.props.onPageNumberChange(this.props.activePage - 1))}
					/>
				</li>
			</div>
		);
	};

	NextComponent = () => {
		const isDisabled = this.props.activePage >= Math.ceil(this.props.totalRowCount / this.props.pageSize);
		return (
			<div className={'pagination'}>
				<li className={isDisabled ? 'disabled' : ''}>
					<KiFontIcon
						value="chevron_right"
						className="pager-icon"
						onClick={() => (isDisabled ? null : this.props.onPageNumberChange(this.props.activePage + 1))}
					/>
				</li>
				<li className={isDisabled ? 'disabled' : ''}>
					<KiFontIcon
						value="last_page"
						className="pager-icon"
						onClick={() =>
							isDisabled
								? null
								: this.props.onPageNumberChange(
										Math.ceil(this.props.totalRowCount / this.props.pageSize)
								  )
						}
					/>
				</li>
			</div>
		);
	};

	render() {
		const {
			data = {},
			data: {rows = [], columns = []},
			dataError,
			setSortColumn,
			groupBy,
			snapshotType,
			isLoading,
		} = this.props;
		if (dataError) {
			return (
				<article style={{display: 'flex', flex: 1, flexFlow: 'column', alignItems: 'center'}}>
					<div className="no-data-msg">
						<h4>Error fetching explorer data.</h4>
						<div>{dataError.message || ''}</div>
					</div>
				</article>
			);
		}

		const noDataMsg = () => {
			if (isLoading) return '';
			return `There is no ${snapshotType === 'blended' ? 'blended' : ''} data found for ${
				this.props.requestedStatementDate
			} using the ${this.props.dateContextName || 'Latest Snapshot'} data context`;
		};
		return (
			<ReactTable
				manual
				page={this.props.activePage - 1}
				pages={Math.ceil(this.props.totalRowCount / this.props.pageSize)}
				pageSize={this.props.pageSize || 100}
				showPagination={!this.props.maxRecords}
				className="-highlight data-explorer-table"
				onPageChange={pageIndex => this.props.onPageNumberChange(pageIndex + 1)}
				onPageSizeChange={pageSize => this.props.onPageSizeChange(pageSize, 0)} // Called when the pageSize is changed by the user. The resolve page is also sent to maintain approximate position in the data
				pageSizeOptions={[50, 100, 200, 500, 1000]}
				sortable={false}
				data={rows}
				minRows={1}
				LoadingComponent={LoadingComponent}
				noDataText={noDataMsg()}
				PreviousComponent={this.PrevComponent}
				NextComponent={this.NextComponent}
				loading={this.props.isLoading}
				columns={columns.map((col, idx) => {
					return {
						...col,
						minWidth: 120,
						maxWidth: 180,
						Header: () => {
							return (
								<ExplorerTableColumn
									key={`${col.id}-${idx}`}
									idx={idx}
									col={col}
									handleColumnSortSelect={setSortColumn}
									handleTimeSeriesColumnSelect={this.handleTimeSeriesColumnSelect}
									getSummaryColumnChartData={this.getSummaryColumnChartData}
									getTimeSeriesChartData={this.getTimeSeriesChartData}
									getTimeseriesAllChartData={this.getTimeseriesAllChartData}
									showSummaryColumnVisualizationLink={
										col.dataType === 'numeric' &&
										col.columnType === 'aggregate' &&
										data.tableType !== 'timeSeries'
									}
									showTimeSeriesVisualizationLink={data.tableType === 'timeSeries' && idx === 0}
									setGranularity={this.props.handleGranularityUpdate}
									tableType={data.tableType}
									bands={this.props.bands}
									groupBy={groupBy}
									snapshotType={snapshotType}
									startInclusive={this.props.startInclusive}
									timeSeriesColumn={this.props.timeSeriesColumn}
								/>
							);
						},
						id: `${col.id}-${idx}`,
						accessor: d => d.data[idx],
						Cell: cell => {
							return (
								<ExplorerTableCellRT
									assetDrillIndex={this.findDrillableSummaryColumnIndex(columns, data.tableType)}
									handleBucketClick={this.handleBucketClick}
									handleDrillableCellClick={this.handleDrillableCellClick}
									tableType={data.tableType}
									cell={cell}
									isLastRow={cell.index === this.props.totalRowCount - 1}
									isSingleRowTable={this.props.totalRowCount === 1}
									idx={idx}
									groupBy={groupBy}
								/>
							);
						},
					};
				})}
			/>
		);
	}
}

export default ExplorerTable;
