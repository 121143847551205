import {format, getFormatStringType} from 'ki-common/utils/displayFormatter';
import {buildTitle} from 'ki-common/utils/explorerUtils';
import {explorerApi, datasetDatesApi} from 'api';
import _cloneDeep from 'lodash/cloneDeep';
import options from 'ki-common/options';

export const getColumns = (rows, columns) => {
	return columns.map(c => {
		return {
			Header: c.displayName,
			accessor: c.columnName,
		};
	});
};

export const formatData = (rows = [], columns = []) => {
	const transformedRows = [];
	const displayFormats = columns.map(c => c.displayFormat);
	const transformedColumns = getColumns(rows, columns);
	const columnAccessors = transformedColumns.map(c => c.accessor);

	rows.forEach(row => {
		const r = {};
		row.data.forEach((cell, index) => {
			r[columnAccessors[index]] = format(cell, displayFormats[index]);
		});
		transformedRows.push(r);
	});
	return {
		transformedColumns: [...transformedColumns],
		transformedRows: [...transformedRows],
	};
};

export const defaultData = {
	rows: [],
	columns: [],
};

export const getSummaryColumn = (type, data, tableType, columnType, timeSeriesSummaryColumn, timeSeriesColumnId) => {
	// TODO: this really needs refactoring. map() returning nulls then filtering is awkward.
	const mappedSummaryColumn = JSON.parse(JSON.stringify([...data.columns]))
		.slice(1)
		.map(col => {
			if (
				getFormatStringType(col.displayFormat) !== columnType.value &&
				type !== 'pie' &&
				timeSeriesColumnId !== 'all'
			) {
				return null;
			}
			return col;
		})
		.filter(col => {
			if (timeSeriesSummaryColumn && tableType !== 'timeSeries') {
				return (
					col !== null && (col.id === timeSeriesSummaryColumn._id || col.id === timeSeriesSummaryColumn.id)
				);
			}
			return col !== null;
		})
		.find(col => {
			return col !== null;
		});
	if (!mappedSummaryColumn) {
		return undefined;
	}
	mappedSummaryColumn.displayName = buildTitle(
		mappedSummaryColumn.displayName,
		mappedSummaryColumn.calculation,
		mappedSummaryColumn.calculateColumn
	);
	mappedSummaryColumn._id = mappedSummaryColumn.id;
	return mappedSummaryColumn;
};

export const fetchCalculatedDate = async (dateContextId, statementDate, fundingVehicleId, isFixedDate, datasetId) => {
	try {
		return await datasetDatesApi.getCalculatedDates(
			[
				{
					groupId: dateContextId,
					statementDate: statementDate,
					fundingVehicleId: fundingVehicleId,
					isFixedDate: isFixedDate,
				},
			],
			datasetId
		);
	} catch (err) {
		return Promise.reject(err);
	}
};

export async function fetchData(
	datasetColumns,
	view,
	card,
	statementDate,
	fundingVehicle,
	pagingSortingSettings,
	scenarioId
) {
	const newExplorerRequest = _cloneDeep(view);
	const fundingVehicleId = fundingVehicle && fundingVehicle._id ? fundingVehicle._id : null;
	const dateContextList = await datasetDatesApi.fetchPortfolioDates(card.datasetId);
	const dateContext = dateContextList.find(d => d._id === view.explorerData.dateContext);
	const dateContextInfo = await fetchCalculatedDate(
		dateContext.groupId,
		statementDate,
		fundingVehicleId,
		view.explorerData.isFixedDate,
		card.datasetId
	);
	let scenarioType;
	// not sure the reasoning behind these copying logic found in explorer
	switch (scenarioId) {
		case '':
		case 'assetSnapshot':
			scenarioType = 'assetSnapshot';
			break;
		case 'lastApproved':
			scenarioType = 'lastApproved';
			break;
		case 'lastCommitted':
			scenarioType = 'hypo';
			break;
		default:
			// any actual scenarioIds
			scenarioType = 'hypo';
	}

	// card display overrides
	newExplorerRequest.explorerData.statementDate = statementDate;
	newExplorerRequest.explorerData.quickFilters.fundingVehicleId = fundingVehicleId;
	if (card.settings.chartType === 'data') {
		newExplorerRequest.explorerData.pageSize = pagingSortingSettings.pageSize;
	} else {
		newExplorerRequest.explorerData.pageSize = 360; //show all data points for gadget charts
	}
	newExplorerRequest.explorerData.pageNumber = pagingSortingSettings.pageNumber;
	newExplorerRequest.explorerData.sortColumn = pagingSortingSettings.sort[0].id;
	//removing this conditional for now.. making chronological checkbox the source of truth for sorting
	// if (card.settings.chartType === 'data') {
	// 	newExplorerRequest.explorerData.sortOrder = pagingSortingSettings.sort[0].desc ? 'desc' : 'asc';
	// } else {
	// 	newExplorerRequest.explorerData.sortOrder = card.settings.chronological ? 'asc' : 'desc';
	// }
	newExplorerRequest.explorerData.sortOrder = card.settings.chronological ? 'asc' : 'desc';
	// card display saved settings
	newExplorerRequest.explorerData.showTotals = card.settings.showTotals;
	newExplorerRequest.explorerData.transpose = card.settings.transpose;
	newExplorerRequest.explorerData.timeseries.chronological = card.settings.chronological;
	newExplorerRequest.explorerData.quickFilters.scenarioId = scenarioId;
	newExplorerRequest.explorerData.quickFilters.scenarioType = scenarioType;
	// fetch the data
	let newData;
	const data = await explorerApi.fetchExplore(newExplorerRequest.explorerData, datasetColumns);
	if (data) {
		newData = {...data, dateContextInfo: dateContextInfo};
	} else {
		newData = {dateContextInfo: dateContextInfo, columns: [], rows: []};
	}
	return newData;
}

export const getSummaryColumnList = (view, columnType) => {
	return view.explorerData.columns
		.slice(1)
		.filter(col => {
			if (view.explorerData.tableType !== 'timeSeries') {
				return getFormatStringType(col.displayFormat) === columnType.value && col.columnType !== 'asset';
			}
			return col.columnType !== 'asset';
		})
		.map(col => {
			return {
				...col,
				displayName: buildTitle(col.displayName, col.calculation, col.calculateColumn),
			};
		});
};

export const getDisplayTypes = () => {
	return [
		{
			label: 'Data',
			value: 'data',
			type: 'data',
		},
		...options.aggregateChartTypes,
	];
};

export const getChartTypes = tableType => {
	return tableType === 'timeSeries'
		? options.aggregateChartTypes.filter(op => op.type !== 'pie' && op.type !== 'column')
		: options.aggregateChartTypes;
};

export default {
	formatData,
	defaultData,
	getSummaryColumn,
	fetchData,
	getSummaryColumnList,
	getDisplayTypes,
	getChartTypes,
};
