/*
import { ReactLogger } from './logger';
const logger = new ReactLogger();

logger.log('Hello!', {a: 1, b: 2, c:3});
logger.warn('Careful there!');
logger.error('Oh, no!');
*/

/*
USAGE
Set ENV var REACT_LOG_LEVEL=none|log|warn|error
 */



/* eslint-disable no-console*/
const NO_OP = () => {};

export default class ReactLogger {
	constructor(options) {
		const {level} = options || 'none';

		// If the level is none, map all to NO_OP
		if (level === 'none') {
			this.error = NO_OP;
			this.warn = NO_OP;
        	this.log = NO_OP;
			return;
		}

		// Bind the console so we can see the actual line numbers called
		this.error = console.error.bind(console);
		this.warn = console.warn.bind(console);
		this.log = console.log.bind(console);

		// Disabled logging by level
		switch(level) {
			case 'error':
				this.warn = NO_OP;
            	this.log = NO_OP;
				break;
			case 'warn':
				this.log = NO_OP;
				break;
		}
	}

	log(message, ...optionalParams) {
		console.log(message, ...optionalParams);
	}

	warn(message, ...optionalParams) {
		console.warn(message, ...optionalParams);
	}

	error(message, ...optionalParams) {
		console.error(message, ...optionalParams);
	}
}
