import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styles from './AsOfDateColumns.theme.scss';
import {connect} from 'react-redux';
import AddAsOfDateColumns from './AddAsOfDateColumns';
import ItemRow from './ItemRow';
import KiButton from 'components/KiButton';
import {generateAsOfDateColumnsForDataset, checkForAsOfDateColumnModifications} from 'containers/datasetList/actions';
import KiProgressBar from 'components/KiProgressBar';

export class AsOfDateColumns extends Component {
	static propTypes = {
		columns: PropTypes.array,
		datasetId: PropTypes.string,
		isAdmin: PropTypes.bool,
		generateAsOfDateColumnsForDataset: PropTypes.func.isRequired,
		checkForAsOfDateColumnModifications: PropTypes.func.isRequired,
		fetchDataset: PropTypes.func.isRequired,
	};

	static defaultProps = {
		columns: [],
	};

	state = {
		searchValue: '',
		isLoading: false,
		formMode: null,
	};

	toggleFormMode = (mode = null, column = null) => {
		this.setState({
			formMode: mode,
			column: column,
		});
	};

	handleGenerateModifiedClick = () =>
		this.props.generateAsOfDateColumnsForDataset(
			this.props.columns.filter(col => col.hasModifications).map(c => c._id)
		);

	handleGenerateAllClick = () => this.props.generateAsOfDateColumnsForDataset(this.props.columns.map(c => c._id));

	render() {
		const {isAdmin, datasetId} = this.props;
		if (this.state.formMode === 'insert') {
			return (
				<AddAsOfDateColumns
					datasetId={datasetId}
					onExit={() => this.toggleFormMode()}
					fetchDataset={this.props.fetchDataset}
				/>
			);
		}

		if (this.state.formMode === 'edit') {
			return (
				<AddAsOfDateColumns
					datasetId={datasetId}
					column={this.state.column}
					onExit={() => this.toggleFormMode()}
					fetchDataset={this.props.fetchDataset}
				/>
			);
		}

		const hasModifiedColumns = this.props.columns.some(col => col.hasModifications);

		const filteredColumns = this.props.columns.filter(
			c => !this.state.searchValue || c.displayName.toLowerCase().includes(this.state.searchValue.toLowerCase())
		);

		return (
			<React.Fragment>
				{isAdmin && (
					<div
						className={`cta-icon-btn ${styles.addButtonRow}`}
						onClick={() => this.toggleFormMode('insert')}
					>
						<i className="material-icons">add_circle</i>
						<p>Add Columns</p>
					</div>
				)}
				<section className="flyout-panel-search">
					<p>Search Columns</p>
					<input placeholder="Column Name..." onChange={e => this.setState({searchValue: e.target.value})} />
				</section>
				{this.state.isLoading && <KiProgressBar />}
				{hasModifiedColumns && <p className={styles.asteriskLabel}>* modified</p>}
				<section className={styles.mainBody}>
					{!filteredColumns.length ? (
						<p>No Columns Found</p>
					) : (
						<div className="column-list">
							{filteredColumns.map(c => (
								<ItemRow
									key={c._id}
									column={c}
									isGenerating={c.status === 'generating'}
									isModified={!!c.hasModifications}
									isPendingModificationCheck={!!c.pendingModificationCheck}
									checkForModifications={() =>
										this.props.checkForAsOfDateColumnModifications(datasetId, c._id)
									}
									isGenerationDisabled={this.props.columns.some(c => c.status === 'generating')}
									handleGenerateColumn={() => this.props.generateAsOfDateColumnsForDataset([c._id])}
									toggleFormMode={this.toggleFormMode}
								/>
							))}
						</div>
					)}
				</section>
				{isAdmin && (
					<footer className={styles.footer}>
						<KiButton
							primary
							raised
							disabled={this.props.columns.some(c => c.status === 'generating')}
							label={
								this.props.columns.some(c => c.status === 'generating')
									? 'Processing...'
									: 'Generate All'
							}
							onMouseUp={this.handleGenerateAllClick}
						/>
						<div style={{flex: 1}} />
						{hasModifiedColumns && (
							<KiButton
								primary
								raised
								disabled={this.props.columns.some(c => c.status === 'generating')}
								label={
									this.props.columns.some(c => c.status === 'generating')
										? 'Processing...'
										: 'Generate Modified'
								}
								onMouseUp={this.handleGenerateModifiedClick}
							/>
						)}
					</footer>
				)}
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => {
	return {
		isAdmin: state.user.groups.includes('SystemAdmins'),
		columns: state.datasetList.columnList.filter(c => c.columnType === 'asset' && !!c.asOfDateType),
		datasetId: state.datasetList.selected.datasetId,
	};
};

const mapPropsToDispatch = {
	generateAsOfDateColumnsForDataset,
	checkForAsOfDateColumnModifications,
};

export default connect(mapStateToProps, mapPropsToDispatch)(AsOfDateColumns);
