import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import ContextIcons from 'components/ContextSidebar/icons';
import ContextSidebar from 'components/ContextSidebar';
import ManageEligibilityViews from './ManageEligibilityViews';
import FlyoutDates from 'components/FlyoutDates';
import {KiIconCard} from 'components/KiIcons';
import FlyoutCardList from 'components/FlyoutCardList';

//viewList
class EligibilitySidebar extends Component {
	static propTypes = {
		eligibilityAnalysis: PropTypes.object.isRequired, //eslint-disable-line react/no-unused-prop-types
		history: PropTypes.object,
	};

	render() {
		return (
			<React.Fragment>
				<ContextSidebar
					items={[
						{
							name: 'Views',
							icon: <ContextIcons.ViewsIcon />,
							element: <ManageEligibilityViews history={this.props.history} />,
						},
						{
							name: 'Manage Dates',
							icon: <ContextIcons.MaterialIcon name="date_range" />,
							element: <FlyoutDates />,
						},
						{
							name: 'Manage Cards',
							icon: <KiIconCard />,
							element: (
								<FlyoutCardList
									datasetId={this.props.eligibilityAnalysis.metadata.datasetId}
									bookmark={this.props.eligibilityAnalysis.metadata}
									explorerViewType="eligibilityAnalysis"
								/>
							),
						},
					]}
				/>
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => {
	return {
		eligibilityAnalysis: state.eligibilityAnalysis,
	};
};

export default connect(mapStateToProps)(EligibilitySidebar);
