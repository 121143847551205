// Globals
import React, {useEffect, useState, useContext} from 'react';
import PropTypes from 'prop-types';
import uuidV4 from 'uuid/v4';
import ReactTable from 'react-table-6';
import {buildTitle} from 'ki-common/utils/explorerUtils';
import 'react-table-6/react-table.css';

// Website imports
import KiFontIcon from 'components/KiFontIcon';
import KiProgressBar from 'components/KiProgressBar';

// Local imports
import {DataExplorerContext} from '../../DataExplorerContext';
import {ExplorerTableCellRT} from './ExplorerTableCell';
import {ExplorerTableColumn} from './ExplorerTableColumns';
import './ExplorerTable.scss';

// Logger
import ReactLogger from 'utils/ReactLogger';
const logger = new ReactLogger({level: window.REACT_LOG_LEVEL});

export const LoadingComponent = ({loading}) => {
	if (!loading) {
		return false;
	}
	return (
		<div className="-loading -active" style={{zIndex: 0}}>
			<KiProgressBar className={'keep-flex'} />
		</div>
	);
};
LoadingComponent.propTypes = {
	loading: PropTypes.bool,
};

export function ExplorerTable({timeSeriesGranularity}) {
	/*
	data={this.props.dataExplorer.data || {}}
	dataset={this.props.dataset}
	columnList={this.props.columnList}
	dataError={this.props.dataExplorer.error}
	drillIntoAsset={this.props.drillIntoAsset}
	drillIntoBucket={this.props.drillIntoBucket}
	drillIntoTimeSeries={this.props.drillIntoTimeSeries}
	fetchExplorerData={this.props.fetchExplorerData}
	setBreadcrumb={this.props.setBreadcrumb}
	// updateCohortBucket={this.props.updateCohortBucket}
	setSortColumn={this.handleSetSortColumn}
	sortColumn={this.props.dataExplorer.sortColumn}
	setGranularity={this.props.setGranularity}
	handleGranularityUpdate={this.handleGranularityUpdate}
	timeSeriesGranularity={this.props.dataset.timeSeriesGranularity}
	datasetId={this.props.dataset.datasetId}
	bookmarkId={this.state.bookmark._id}
	assetCountColumn={this.props.columnList.find(c => c.columnName === 'kiAssetCount')}
	isLoading={this.props.dataExplorer.isLoading}
	totalRowCount={
		(this.props.dataExplorer.data && this.props.dataExplorer.data.totalNumberOfRows) || 0
	}
	activePage={(this.props.dataExplorer.data && this.props.dataExplorer.data.pageNumber) || 0}
	pageSize={this.props.dataExplorer.data && this.props.dataExplorer.data.pageSize}
	onPageNumberChange={this.handleSetPageNumber}
	onPageSizeChange={this.handleSetPageSize}
	bands={this.props.dataExplorer.bands}
	setViewHasChanges={this.setViewHasChanges}
	maxRecords={this.props.dataExplorer.maxRecords}
	groupBy={this.props.dataExplorer.groupBy}
	statementDate={this.props.dataExplorer.statementDate}
	startInclusive={this.props.dataExplorer.startInclusive}
	timeSeriesColumn={_.get(this.props, 'dataExplorer.timeseries.column')}
	snapshotType={this.props.dataExplorer.snapshotType}
	calculatedDate={this.props.dataExplorer.calculatedDate}
	dateContextName={this.props.dataExplorer.dateContextName}
	requestedStatementDate={this.state.requestedStatementDate}
 	*/

	// Context State
	const dataExplorerContext = useContext(DataExplorerContext);
	const {bookmark, renderState, allColumns, actions} = dataExplorerContext;
	const explorerData = bookmark.explorerData;

	const {columns, rows, totalNumberOfRows, pageSize, pageNumber} = renderState.data;
	const totalRowCount = totalNumberOfRows || 0;
	const activePage = pageNumber;

	// Local State
	const [drillClickCount, setDrillClickCount] = useState(0);
	const [summaryColIndex, setSummaryColIndex] = useState(-1);

	const onPageNumberChange = pageNumber => {
		dataExplorerContext.setExplorerDataAndFetch({pageNumber});
	};

	const onPageSizeChange = pageSize => {
		dataExplorerContext.setExplorerDataAndFetch({pageSize});
	};

	const setSortColumn = (columnId, order, calculation) => {
		actions.setSortColumn(columnId, order, calculation);
	};

	const handleTimeSeriesColumnSelect = columnName => {
		logger.warn('handleTimeSeriesColumnSelect', timeSeriesGranularity);
		const monthlyGranularityExists = timeSeriesGranularity.find(g => g.value === 'monthly');
		actions.drillIntoTimeSeries({
			columnData: {
				id: columnName,
			},
			range: 'last_6_periods',
			period: monthlyGranularityExists ? 'monthly' : timeSeriesGranularity[0].value,
		});
	};

	const handleDrillableCellClick = async (cellValue, rowMetaData, rowIndex) => {
		const actualDrillClickCount = drillClickCount + 1;
		setDrillClickCount(actualDrillClickCount);
		if (actualDrillClickCount > 1) {
			return null;
		}
		const id = uuidV4();
		const rows = renderState.data.rows || [];
		const buckets = rows.map((data, index) => ({
			id: id,
			type: 'drill',
			value: index,
			label:
				data.meta.bucket.value && data.meta.bucket.value.length
					? data.meta.bucket.value
					: `${data.meta.bucket.min} - ${data.meta.bucket.max}`,
			bucket: {
				min: data.meta.bucket.min,
				max: data.meta.bucket.max,
				value: data.meta.bucket.value,
			},
		}));
		const breadcrumb = {
			id: id,
			type: 'drill',
			currValue: rowIndex,
			options: buckets,
		};
		actions.drillIntoAsset(rowMetaData.bucket, breadcrumb);
	};

	const handleBucketClick = async (cellValue, rowMetaData, rowIndex, cellIndex) => {
		const actualDrillClickCount = drillClickCount + 1;
		setDrillClickCount(actualDrillClickCount);
		if (actualDrillClickCount > 1) {
			return null;
		}
		const id = uuidV4();
		const rows = renderState.data.rows || [];
		const columns = renderState.data.columns || [];
		const clickedColumn = columns[cellIndex];
		const buckets = rows.map((data, index) => ({
			id: id,
			type: 'drill',
			value: index,
			label:
				data.meta.bucket.value && data.meta.bucket.value.length
					? data.meta.bucket.value
					: `${data.meta.bucket.min} - ${data.meta.bucket.max}`,
			bucket: {
				min: data.meta.bucket.min,
				max: data.meta.bucket.max,
				value: data.meta.bucket.value,
			},
			granularity: clickedColumn.nextGranularity,
		}));
		const breadcrumb = {
			id: id,
			type: 'drill',
			currValue: rowIndex,
			options: buckets,
			prevGranularity: clickedColumn.granularity,
		};
		actions.drillIntoBucket(rowMetaData.bucket, breadcrumb, clickedColumn.nextGranularity);
	};

	const getSummaryColumnChartData = (columnName, columnCalculation) => {
		const rows = renderState.data.rows || [];
		const columns = renderState.data.columns || [];
		const columnIndex = columns.findIndex(
			col => col.columnName === columnName && col.calculation === columnCalculation
		);
		const cohortName = columns[0].displayName;
		const selectedSummaryColumn = columns[columnIndex];
		const summaryColumnName = buildTitle(
			selectedSummaryColumn.displayName,
			selectedSummaryColumn.calculation,
			selectedSummaryColumn.calculateColumn
		);
		const categories = rows.map(data => {
			const value = data.meta.bucket.value; //eslint-disable-line prefer-destructuring
			const bucket = `${data.meta.bucket.min} - ${data.meta.bucket.max}`;
			return value || bucket;
		});
		const seriesData = rows.map(row => Number(row.data[columnIndex]));

		return {
			chartType: 'column',
			categories,
			series: [
				{
					name: `${cohortName}`,
					data: seriesData,
				},
			],
			chartTitle: `${summaryColumnName} by ${cohortName}`,
			xTitle: '',
			yTitle: `${summaryColumnName}`,
		};
	};

	const getTimeSeriesChartData = () => {
		const rows = renderState.data.rows || [];
		const columns = renderState.data.columns || [];
		const selectedSummaryColumn = renderState.data.selectedSummaryColumn || '';

		// Categories are dates
		let categories = columns.map(data => `${data.displayName}`);
		categories = categories.slice(1); // Remove cohort column

		// // Multiple series, one for each bucket
		const seriesData = rows.map(row => {
			let series = row.data.slice(1); // Remove cohort column
			series = series.map(entry => Number(entry));
			return {
				name: `${row.data[0]}`,
				data: series,
			};
		});

		let yTitle = '';

		if (selectedSummaryColumn[0]) {
			yTitle = buildTitle(
				selectedSummaryColumn[0].displayName,
				selectedSummaryColumn[0].calculation,
				selectedSummaryColumn[0].calculateColumn
			);
		}

		return {
			chartType: 'column',
			categories,
			series: seriesData,
			chartTitle: 'Time Series',
			xTitle: columns[0] ? columns[0].displayName : '',
			yTitle: yTitle,
			legend: {verticalAlign: 'bottom', floating: false},
		};
	};

	const getTimeseriesAllChartData = () => {
		const rows = renderState.data.rows || [];
		const columns = renderState.data.columns || [];

		const yTitle = '';

		const categories = rows.map(data => {
			const value = data.meta.bucket.value; //eslint-disable-line prefer-destructuring
			const bucket = `${data.meta.bucket.min} - ${data.meta.bucket.max}`;
			return value || bucket;
		});

		const seriesData = columns.slice(1).map((col, index) => {
			return {
				id: col.id,
				name: col.displayName, //buildTitle(col.displayName, col.calculation, col.calculateColumn),
				data: rows.map(row => row.data[index + 1]),
			};
		});

		return {
			chartType: 'column',
			categories,
			series: seriesData,
			chartTitle: 'Time Series',
			xTitle: columns[0] ? columns[0].displayName : '',
			yTitle: yTitle,
			legend: {verticalAlign: 'bottom', floating: false},
		};
	};

	const PrevComponent = () => {
		const isDisabled = activePage === 1;
		return (
			<div className={'pagination'}>
				<li className={isDisabled ? 'disabled' : ''}>
					<KiFontIcon
						value="first_page"
						className="pager-icon"
						onClick={() => (isDisabled ? null : onPageNumberChange(1))}
					/>
				</li>
				<li className={isDisabled ? 'disabled' : ''}>
					<KiFontIcon
						value="chevron_left"
						className="pager-icon"
						onClick={() => (isDisabled ? null : onPageNumberChange(activePage - 1))}
					/>
				</li>
			</div>
		);
	};

	const NextComponent = () => {
		const isDisabled = activePage >= Math.ceil(totalRowCount / pageSize);
		return (
			<div className={'pagination'}>
				<li className={isDisabled ? 'disabled' : ''}>
					<KiFontIcon
						value="chevron_right"
						className="pager-icon"
						onClick={() => (isDisabled ? null : onPageNumberChange(activePage + 1))}
					/>
				</li>
				<li className={isDisabled ? 'disabled' : ''}>
					<KiFontIcon
						value="last_page"
						className="pager-icon"
						onClick={() => (isDisabled ? null : onPageNumberChange(Math.ceil(totalRowCount / pageSize)))}
					/>
				</li>
			</div>
		);
	};

	// -------------------
	// useEffect functions
	// -------------------
	useEffect(() => {
		logger.log('ExplorerTable.useEffect []');
		/*
			TODO?
			componentDidUpdate(prevProps) {
				// Only reset the drill clicks once the data has changed
				if (!_isEqual(data, prevdata)) {
					setDrillClickCount(0);
				}
			}
			 */
	}, []);

	useEffect(
		() => {
			if (columns.length > 0) {
				const assetCountColumn = allColumns.find(c => c.columnName === 'kiAssetCount');
				// Must cast the _id Object to a String for the match to work
				const assetCountColumnId = `${assetCountColumn._id}`;
				const idxCol = columns.findIndex(
					c =>
						c.assetColumnId === assetCountColumnId &&
						c.columnType === 'aggregate' &&
						c.calculation === 'SUM' &&
						renderState.data.tableType !== 'timeSeries'
				);
				setSummaryColIndex(idxCol);
			}
		},
		[columns]
	);

	if (renderState.error) {
		return (
			<article style={{display: 'flex', flex: 1, flexFlow: 'column', alignItems: 'center'}}>
				<div className="no-data-msg">
					<h4>Error fetching explorer data.</h4>
					<div>{renderState.error.message || ''}</div>
				</div>
			</article>
		);
	}

	return (
		<ReactTable
			manual
			page={activePage - 1}
			pages={Math.ceil(totalRowCount / pageSize)}
			pageSize={pageSize || 100}
			showPagination={!explorerData.maxRecords}
			className="-highlight data-explorer-table"
			onPageChange={pageIndex => onPageNumberChange(pageIndex + 1)}
			onPageSizeChange={pageSize => onPageSizeChange(pageSize, 0)} // Called when the pageSize is changed by the user. The resolve page is also sent to maintain approximate position in the data
			pageSizeOptions={[50, 100, 200, 500, 1000]}
			sortable={false}
			data={rows}
			minRows={1}
			LoadingComponent={LoadingComponent}
			noDataText={'No Data Found'}
			PreviousComponent={PrevComponent}
			NextComponent={NextComponent}
			loading={renderState.isLoading}
			columns={columns.map((col, idx) => {
				return {
					...col,
					minWidth: 120,
					maxWidth: 180,
					// eslint-disable-next-line react/display-name
					Header: () => {
						return (
							<ExplorerTableColumn
								key={`${col.id}-${idx}`}
								idx={idx}
								col={col}
								handleColumnSortSelect={setSortColumn}
								handleTimeSeriesColumnSelect={handleTimeSeriesColumnSelect}
								getSummaryColumnChartData={getSummaryColumnChartData}
								getTimeSeriesChartData={getTimeSeriesChartData}
								getTimeseriesAllChartData={getTimeseriesAllChartData}
								showSummaryColumnVisualizationLink={
									col.dataType === 'numeric' &&
									col.columnType === 'aggregate' &&
									renderState.data.tableType !== 'timeSeries'
								}
								showTimeSeriesVisualizationLink={
									renderState.data.tableType === 'timeSeries' && idx === 0
								}
								setGranularity={actions.setGranularityAndFetch}
								tableType={renderState.data.tableType}
								bands={explorerData.bands}
								groupBy={explorerData.groupBy}
								snapshotType={explorerData.snapshotType}
								startInclusive={explorerData.startInclusive}
								timeSeriesColumn={explorerData.timeseries.column}
							/>
						);
					},
					id: `${col.id}-${idx}`,
					accessor: d => d.data[idx],
					// eslint-disable-next-line react/display-name
					Cell: cell => {
						return (
							<ExplorerTableCellRT
								assetDrillIndex={summaryColIndex}
								handleBucketClick={handleBucketClick}
								handleDrillableCellClick={handleDrillableCellClick}
								tableType={renderState.data.tableType}
								cell={cell}
								isLastRow={cell.index === totalRowCount - 1}
								isSingleRowTable={totalRowCount === 1}
								idx={idx}
								groupBy={explorerData.groupBy}
							/>
						);
					},
				};
			})}
		/>
	);
}

export default ExplorerTable;

ExplorerTable.propTypes = {
	timeSeriesGranularity: PropTypes.array,
};

/*
static propTypes = {
		data: PropTypes.shape({
			columns: PropTypes.array,
			rows: PropTypes.array,
			meta: PropTypes.object,
			selectedSummaryColumn: PropTypes.any,
			displayName: PropTypes.string,
			tableType: PropTypes.string,
		}),
		columnList: PropTypes.array,
		dataError: PropTypes.any,
		drillIntoAsset: PropTypes.func,
		drillIntoBucket: PropTypes.func,
		drillIntoTimeSeries: PropTypes.func,
		setSortColumn: PropTypes.func,

		handleGranularityUpdate: PropTypes.func,
		timeSeriesGranularity: PropTypes.array,
		assetCountColumn: PropTypes.object,
		isLoading: PropTypes.bool,
		totalRowCount: PropTypes.number,
		activePage: PropTypes.number,
		pageSize: PropTypes.number,
		onPageNumberChange: PropTypes.func.isRequired,
		onPageSizeChange: PropTypes.func.isRequired,
		bands: PropTypes.object,
		setViewHasChanges: PropTypes.func,
		maxRecords: PropTypes.string,
		groupBy: PropTypes.any,
		snapshotType: PropTypes.string,
		startInclusive: PropTypes.bool,
		timeSeriesColumn: PropTypes.object,
		calculatedDate: PropTypes.string,
		dateContextName: PropTypes.string,
		requestedStatementDate: PropTypes.string,
	};
 */
