// Globals
import React, {Component} from 'react';
import {Route, Redirect, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import Select from 'react-select';

// Ki Imports
import {getDefaultColumnIds} from 'ki-common/utils/explorerUtils';

// Ki-website api
import {columnServiceApi} from 'api';

// Ki-website container
import {fetchDataset} from 'containers/datasetList/actions';
import {fetchCalendars} from 'containers/calendars/actions';
import ManageTriggers from 'containers/dealStructures/components/ManageTriggers';

// Ki-website components
import KiAppBar from 'components/KiAppBar';
import ContextSidebar from 'components/ContextSidebar';
import FlyoutCalculations from 'components/FlyoutCalculations';
import FlyoutManageViews from 'components/FlyoutManageViews';
import ContextIcons from 'components/ContextSidebar/icons';
import {fetchDates} from 'components/FlyoutDates/actions';
import KiTwoPanelList from 'components/KiTwoPanelList';

// Local Imports
import {
	fetchFundingVehicle,
	updateFundingVehicle,
	updateFundingVehicleSetting,
	deleteFundingVehicleSettings,
	addFundingVehicleSetting,
	fetchAllReportingDates,
	fetchFundingVehicleSettings,
} from './actions';
import FundingVehicleForm from './form.js';
import FundingVehicleSettings from './settings';
import ConstraintListItemView from './ConstraintListItemView';
import SettingsRenderForm from './settingsRenderForm';
import Debt from './settings/debt';
import CreditSupports from './settings/creditSupports';
import Fees from './settings/fees';
import Counterparties from './settings/counterparties';

// import Constraints from './settings/constraints';

const FVM_ROUTES = [
	{
		label: 'Funding Vehicle Setup',
		value: 'setup',
		groups: ['capitalStructure', 'fundingConfiguration'],
	},
	{
		label: 'Tranche',
		value: 'debts',
		groups: ['capitalStructure'],
	},
	{
		label: 'Calculations',
		value: 'calculations',
		groups: ['fundingConfiguration'],
	},
	{
		label: 'Constraints',
		value: 'constraints',
		groups: ['fundingConfiguration'],
	},
	{
		label: 'Accounts',
		value: 'creditSupports',
		groups: ['capitalStructure'],
	},
	{
		label: 'Fees',
		value: 'fees',
		groups: ['capitalStructure'],
	},
	{
		label: 'Counterparties',
		value: 'counterparties',
		groups: ['capitalStructure'],
	},
];

export class FundingVehicle extends Component {
	static propTypes = {
		app: PropTypes.object.isRequired,
		fundingVehicle: PropTypes.object,
		fundingVehicleSettings: PropTypes.object,
		fundingVehiclePools: PropTypes.array,
		datasetColumns: PropTypes.array,
		aggregateColumns: PropTypes.array,
		eligibleColumns: PropTypes.array,
		match: PropTypes.object,
		fetchFundingVehicle: PropTypes.func,
		updateFundingVehicle: PropTypes.func,
		history: PropTypes.object,
		fetchDataset: PropTypes.func.isRequired,
		user: PropTypes.object,
		dataset: PropTypes.object,
		updateFundingVehicleSetting: PropTypes.func,
		deleteFundingVehicleSettings: PropTypes.func,
		addFundingVehicleSetting: PropTypes.func,
		fetchFundingVehicleSettings: PropTypes.func,
		fetchCalendars: PropTypes.func,
		fetchAllReportingDates: PropTypes.func,
		calendars: PropTypes.array,
		fetchDates: PropTypes.func,
	};

	static defaultProps = {
		calendars: [],
	};

	constructor(props) {
		super(props);

		const {
			fetchFundingVehicle,
			match: {params: routeParams = {}},
		} = this.props;

		this.props.fetchCalendars();

		if (routeParams.id) {
			fetchFundingVehicle(routeParams.id).then(fv => {
				this.props.fetchDataset(fv.datasetId, false);
				this.props.fetchFundingVehicleSettings(fv._id.toString());
				this.props.fetchDates(fv.datasetId);
			});

			this.state = {
				section: this.props.match.params.section,
				routeGroup: this.props.match.params.group,
				groupBy: _get(this.props, 'fundingVehicle.groupBy', ''),
				assetColumns: [],
			};
		}
	}

	state = {
		aggregateColumns: [],
	};

	componentDidUpdate(prevProps) {
		if (prevProps.fundingVehicle && prevProps.fundingVehicle.name) {
			document.title = `${this.props.app.kiVersion} - ${this.props.fundingVehicle.name}`;
		}
		// check if groupBy has changed from null/undefined/emptyString to a truthy value
		// forcing booleans so that we don't set new state on '' vs undefined or undefined vs null, etc
		if (prevProps.fundingVehicle && !!this.state.groupBy !== !!prevProps.fundingVehicle.groupBy) {
			this.setState({groupBy: this.props.fundingVehicle.groupBy || ''});
		}

		const currentSection = this.props.match.params.section;
		if (prevProps.match.params.section !== currentSection) {
			this.setState({section: currentSection});
		}

		if (prevProps.fundingVehicle !== this.props.fundingVehicle) {
			const queriedFields = [
				'_id',
				'displayName',
				'displayFormat',
				'dataType',
				'calculation',
				'columnType',
				'columnName',
				'assetColumnId',
				'detailedDisplayName',
			];

			const filterSet = {
				includeSyntheticColumns: false,
			};

			const optionSet = {
				queriedFields,
			};

			columnServiceApi
				.getColumnsFromService(this.props.fundingVehicle.datasetId, {
					sources: {
						includeAssetColumns: true,
						includeAssetCalculations: true,
						includeBusinessFunctions: true,
						includeHistoricalColumns: true,
						includeDateColumns: true,
					},
					filters: filterSet,
					options: optionSet,
				})
				.then(assetColumns => {
					this.setState({assetColumns});
				});

			columnServiceApi
				.getColumnsFromService(this.props.fundingVehicle.datasetId, {
					sources: {
						includeCohortColumns: true,
					},
					filters: {
						includeSyntheticColumns: false,
						includePeerFileColumns: true,
					},
					options: {
						embedColumns: true,
					},
				})
				.then(cohortColumns => {
					this.setState({cohortColumns});
				});

			columnServiceApi
				.getColumnsFromService(this.props.fundingVehicle.datasetId, {
					sources: {
						includeAggregateColumns: true,
					},
					filters: filterSet,
					options: optionSet,
				})
				.then(aggregateColumns => {
					const filteredAggCols = aggregateColumns.filter(c =>
						['SUM', 'AVG', 'MIN', 'MAX', 'WGHTAVG', 'FIRST_VALID'].includes(c.calculation)
					);
					this.setState({aggregateColumns: filteredAggCols});
				});

			columnServiceApi
				.getColumnsFromService(this.props.fundingVehicle.datasetId, {
					sources: {
						includeWaterfallCalculations: true,
					},
					filters: filterSet,
					options: optionSet,
				})
				.then(waterfallColumns => {
					this.setState({waterfallColumns: waterfallColumns.filter(c => c.dataType !== 'boolean')});
				});

			columnServiceApi
				.getColumnsFromService(this.props.fundingVehicle.datasetId, {
					sources: {
						includeDebtCalculations: true,
					},
					filters: filterSet,
					options: optionSet,
				})
				.then(debtColumns => {
					this.setState({debtColumns});
				});

			columnServiceApi.getEligibleColumnIds(this.props.fundingVehicle.datasetId).then(eligibleColumns => {
				this.setState({eligibleColumns});
			});
		}
	}

	onGroupByChanged = value => {
		this.setState({groupBy: value || ''});
	};

	customSelectStyles = {
		control: (provided, state) => ({
			...provided,
			cursor: 'pointer',
			width: 8 * _get(state.getValue(), '[0].label.length', 8) + 60,
		}),
		menu: provided => ({
			...provided,
			width: 'auto',
		}),
	};

	getAssetCohortColumnId = columnList => {
		if (columnList.length > 0 && this.props.dataset.snapshots.length > 0) {
			const colIds = getDefaultColumnIds(
				this.props.dataset.snapshots,
				columnList,
				this.props.dataset.snapshots[0].snapshotDate
			);
			if (colIds && colIds.assetCohortColumnId) {
				return colIds.assetCohortColumnId;
			} else {
				return '';
			}
		} else {
			return '';
		}
	};

	render() {
		if (
			!this.props.fundingVehicle ||
			!this.props.dataset.snapshots ||
			!this.state.assetColumns.length ||
			!this.state.aggregateColumns ||
			!this.state.cohortColumns
		) {
			return <div className="container-loading">loading...</div>;
		}
		const {match} = this.props;
		const currentRoute = FVM_ROUTES.find(({value}) => value === match.params.section) || FVM_ROUTES[0];
		const columnList = [...this.state.assetColumns, ...this.state.aggregateColumns];
		const assetCohortColumnId = this.getAssetCohortColumnId(columnList);
		const routeOptions = FVM_ROUTES.filter(
			route =>
				route.groups.includes(this.state.routeGroup) &&
				(!this.props.fundingVehicle.isUnencumbered || route.value === 'setup')
		);

		return (
			<div className="container-wrapper">
				<article className="funding-vehicle-container container-body">
					<header>
						<KiAppBar>
							<div className="top-bar-breadcrumb">
								<div className="top-bar-breadcrumb">
									<h1
										className="link"
										onClick={() => this.props.history.push('/datasets')}
									>{`Datasets`}</h1>
									<h1>{` > ${this.props.dataset.name} `}</h1>
									<h1
										className="link"
										onClick={() =>
											this.props.history.push(
												`/datasets/${this.props.dataset.datasetId}/fundingVehicles/`
											)
										}
									>
										&gt; Funding Vehicles
									</h1>
									<h1>{` > ${this.props.fundingVehicle && this.props.fundingVehicle.name}`}</h1>
									<h1>{` > ${currentRoute.label}`}</h1>
								</div>
							</div>
						</KiAppBar>
					</header>
					<div className={'ki-panel'}>
						<Select
							value={FVM_ROUTES.find(route => route.value === this.state.section) || FVM_ROUTES[0]}
							options={routeOptions}
							onChange={option => {
								this.props.history.push(
									`/datasets/${this.props.dataset.datasetId}/fundingVehicles/${match.params.id}/${
										this.state.routeGroup
									}/${option.value}`
								);
							}}
							isDisabled={this.props.fundingVehicle.isUnencumbered}
							styles={this.customSelectStyles}
							isSearchable={false}
						/>
						<br />
						<Switch>
							<Route
								path={'/datasets/:datasetId/fundingVehicles/:id/:group/setup'}
								render={() => (
									<FundingVehicleForm
										fundingVehicle={this.props.fundingVehicle}
										updateMethod={this.props.updateFundingVehicle}
										cohortColumns={this.state.cohortColumns}
										assetCohortColumnId={assetCohortColumnId}
										onGroupByChanged={this.onGroupByChanged}
										fundingVehiclePools={this.props.fundingVehiclePools}
									/>
								)}
							/>
							<Route
								path={'/datasets/:datasetId/fundingVehicles/:id/:group/debts/:debtId?'}
								render={props => (
									<Debt
										{...props}
										dataset={this.props.dataset}
										fundingVehicle={this.props.fundingVehicle}
										debts={this.props.fundingVehicleSettings.debt.filter(
											debt => debt.fundingVehicleId === this.props.fundingVehicle._id
										)}
									/>
								)}
							/>
							<Route
								path={'/datasets/:datasetId/fundingVehicles/:id/:group/calculations'}
								render={() => (
									<FundingVehicleSettings
										settings={this.props.fundingVehicleSettings.calculations}
										debtCalcColumns={this.state.debtColumns}
										type="calculation"
										tbCohortColumns={columnList}
									/>
								)}
							/>
							<Route
								path={'/datasets/:datasetId/fundingVehicles/:id/:group/constraints'}
								render={() => (
									<KiTwoPanelList
										itemList={this.props.fundingVehicleSettings.constraints || []}
										itemKeyAttribute="_id"
										listItemComponent={<ConstraintListItemView columns={columnList} />}
										editItemComponent={
											<SettingsRenderForm
												settings={this.props.fundingVehicleSettings.constraints || []}
												isGrouped={!!this.state.groupBy}
												tbCohortColumns={columnList}
												eligibleColumns={this.state.eligibleColumns}
												fundingVehicleId={this.props.fundingVehicle._id}
												updateFundingVehicleSetting={this.props.updateFundingVehicleSetting}
												deleteFundingVehicleSettings={this.props.deleteFundingVehicleSettings}
												addFundingVehicleSetting={this.props.addFundingVehicleSetting}
											/>
										}
										listHeader={
											_get(this.props, 'fundingVehicleSettings.constraints', []).length === 0
												? 'No Constraints Found'
												: ''
										}
									/>
								)}
							/>
							<Route
								path={
									'/datasets/:datasetId/fundingVehicles/:id/:group/creditSupports/:creditSupportId?'
								}
								render={props => (
									<CreditSupports
										{...props}
										creditSupports={this.props.fundingVehicleSettings.creditSupports}
										waterfallCalcColumns={this.state.waterfallColumns}
										dataset={this.props.dataset}
										fundingVehicle={this.props.fundingVehicle}
									/>
								)}
							/>
							<Route
								path={'/datasets/:datasetId/fundingVehicles/:id/:group/fees/:feeId?'}
								render={props => (
									<Fees
										{...props}
										fees={this.props.fundingVehicleSettings.fees}
										waterfallCalcColumns={this.state.waterfallColumns}
										dataset={this.props.dataset}
										fundingVehicle={this.props.fundingVehicle}
									/>
								)}
							/>
							<Route
								path={'/datasets/:datasetId/fundingVehicles/:id/:group/counterparties/:counterpartyId?'}
								render={props => (
									<Counterparties
										{...props}
										counterparties={this.props.fundingVehicleSettings.counterparties}
										dataset={this.props.dataset}
										fundingVehicle={this.props.fundingVehicle}
									/>
								)}
							/>
							<Redirect to={`${match.url}/setup`} />
						</Switch>
					</div>
				</article>
				<ContextSidebar
					items={[
						{
							name: 'Manage Views',
							icon: <ContextIcons.ViewsIcon />,
							element: <FlyoutManageViews />,
						},
						{
							name: 'Calculations',
							icon: <ContextIcons.CalculationsIcon />,
							element: <FlyoutCalculations user={this.props.user} />,
						},
						{
							name: 'Triggers',
							icon: <ContextIcons.MaterialIcon name="playlist_add_check" />,
							element: (
								<ManageTriggers
									user={this.props.user}
									datasetId={this.props.dataset.datasetId}
									panelMode={this.state.addTriggerOpen}
									onTriggerAdded={this.onTriggerAdded}
								/>
							),
						},
					]}
				/>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	app: state.app,
	fundingVehicle: state.fundingVehicle.selected,
	fundingVehicleSettings: state.fundingVehicle.selectedSettings,
	fundingVehiclePools: state.fundingVehicle.selectedPools,
	datasetColumns: state.datasetList.selected.columns.filter(c => c.columnType === 'asset'),
	user: state.user,
	dataset: state.datasetList.selected,
	calendars: state.calendars.calendars,
});

const mapDispatchToProps = () => ({
	fetchFundingVehicle,
	updateFundingVehicle,
	fetchDataset,
	updateFundingVehicleSetting,
	deleteFundingVehicleSettings,
	addFundingVehicleSetting,
	fetchCalendars,
	fetchAllReportingDates,
	fetchDates,
	fetchFundingVehicleSettings,
});

export default connect(
	mapStateToProps,
	mapDispatchToProps()
)(FundingVehicle);
