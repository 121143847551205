import * as actionTypes from './actionTypes';
import exploreRequestBuilder from 'ki-common/utils/exploreRequestBuilder';
import _get from 'lodash/get';

const initialState = exploreRequestBuilder.defaultState;

const dataExplorer = (state = initialState, action) => {
	// eslint-disable-line complexity
	switch (action.type) {
		case actionTypes.EXPLORER_SORT_COLUMN_SET: {
			return {
				...state,
				sortColumn: action.sortColumn,
				sortCalculation: action.sortCalculation,
				sortOrder: action.sortOrder,
			};
		}
		case actionTypes.EXPLORER_SET_STATEMENT_DATE:
			return {
				...state,
				statementDate: action.statementDate,
			};
		case actionTypes.EXPLORER_SET_GROUPBY:
			return {
				...state,
				groupBy: action.groupBy,
			};
		case actionTypes.EXPLORER_SET_MAXRECORDS:
			return {
				...state,
				maxRecords: action.maxRecords,
			};
		case actionTypes.EXPLORER_DATECONTEXTNAME_SET:
			return {
				...state,
				dateContextName: action.name,
			};
		case actionTypes.EXPLORER_CALCULATEDDATE_SET:
			return {
				...state,
				calculatedDate: action.calculatedDate,
			};
		case actionTypes.EXPLORER_SET_DATE_CONTEXT:
			return {
				...state,
				dateContext: action.dateContext,
			};
		case actionTypes.EXPLORER_SET_IS_FIXED_DATE:
			return {
				...state,
				isFixedDate: action.isFixedDate,
			};
		case actionTypes.EXPLORER_SNAPSHOT_TYPE:
			return {
				...state,
				snapshotType: action.snapshotType,
			};
		case actionTypes.EXPLORER_SET_BANDS:
			return {
				...state,
				bands: action.bands,
			};
		case actionTypes.EXPLORER_PAGE_NUMBER_SET:
			return {
				...state,
				pageNumber: action.pageNumber,
			};
		case actionTypes.EXPLORER_PAGE_SIZE_SET:
			return {
				...state,
				pageSize: action.pageSize,
			};
		case actionTypes.EXPLORER_TABLE_TYPE_SET:
			return {
				...state,
				tableType: action.tableType,
			};
		case actionTypes.EXPLORER_DATSETID_SET:
			return {
				...state,
				datasetId: action.datasetId,
			};
		case actionTypes.EXPLORER_SET_COHORT_COLUMN:
			return {
				...state,
				columns: [action.cohortColumn, ...state.columns.slice(1)],
			};
		case actionTypes.EXPLORER_BREADCRUMBS_CLEAR:
			return {
				...state,
				breadcrumbs: [],
			};

		case actionTypes.EXPLORER_BREADCRUMBS_SET:
			return {
				...state,
				breadcrumbs: [...state.breadcrumbs, action.breadcrumb],
			};

		case actionTypes.EXPLORER_BREADCRUMBS_LOAD:
			return {
				...state,
				breadcrumbs: action.breadcrumbList,
			};

		case actionTypes.EXPLORER_BREADCRUMBS_UPDATE: {
			const idx = state.breadcrumbs.findIndex(b => b.id === action.breadcrumb.id);
			const bc = state.breadcrumbs[idx];
			bc.currValue = action.breadcrumb.value;
			return {
				...state,
				breadcrumbs: [
					...state.breadcrumbs.slice(0, idx),
					bc,
					...state.breadcrumbs.slice(idx + 1).filter(bc => bc.type === 'asset'),
				],
			};
		}

		case actionTypes.EXPLORER_BREADCRUMBS_DELETE: {
			const idx = state.breadcrumbs.findIndex(b => b.id === action.id);
			return {
				...state,
				breadcrumbs: [...state.breadcrumbs.slice(0, idx)],
			};
		}

		case actionTypes.EXPLORER_SET_BUCKET: {
			return {
				...state,
				bucket: {
					min: _get(action, 'min', ''),
					max: _get(action, 'max', ''),
					value: _get(action, 'value', ''),
				},
			};
		}

		case actionTypes.EXPLORER_SET_GRANULARITY: {
			const defaultBands = Object.assign({}, {type: 'default'});
			// custom even won't work because the action.granularity is 'custom-even' instead of a number
			const customEvenBands = Object.assign({}, {type: 'custom-even'}, {step: Number(action.granularity)});
			return {
				...state,
				granularity: action.granularity,
				bands: action.granularity === 'custom-even' ? customEvenBands : defaultBands,
			};
		}

		case actionTypes.EXPLORER_DATA_CLEAR:
			return {
				...initialState,
			};

		case actionTypes.EXPLORER_SET_TIMESERIES_COLUMN: {
			const column = action.column;
			return {
				...state,
				timeseries: {...state.timeseries, column},
			};
		}

		case actionTypes.EXPLORER_SET_TIMESERIES_RANGE: {
			const range = action.range;
			return {
				...state,
				timeseries: {...state.timeseries, range},
			};
		}

		case actionTypes.EXPLORER_SET_TIMESERIES_PERIOD: {
			const period = action.period;
			return {
				...state,
				timeseries: {...state.timeseries, period},
			};
		}

		case actionTypes.EXPLORER_LOAD_TIMESERIES: {
			return {
				...state,
				timeseries: action.timeseries,
			};
		}

		case actionTypes.EXPLORER_COLUMNS_SET:
			return {
				...state,
				columns: action.columns,
			};

		case actionTypes.EXPLORER_COLUMNS_UPDATE_ONE:
			return {
				...state,
				columns: state.columns.reduce((acc, col) => {
					if (col._id === action.column._id) {
						return [...acc, action.column];
					}
					return [...acc, col];
				}, []),
			};

		case actionTypes.EXPLORER_FILTERS_SET:
			return {
				...state,
				filters: action.filters,
			};

		case actionTypes.EXPLORER_QUICKFILTERS_SCENARIO: {
			const scenarioId = action.value;
			return {
				...state,
				quickFilters: {...state.quickFilters, scenarioId},
			};
		}

		case actionTypes.EXPLORER_QUICKFILTERS_SCENARIO_TYPE: {
			const scenarioType = action.value;
			return {
				...state,
				quickFilters: {...state.quickFilters, scenarioType},
			};
		}

		case actionTypes.EXPLORER_QUICKFILTERS_FUNDINGVEHICLE: {
			const fundingVehicleId = action.value;
			return {
				...state,
				quickFilters: {...state.quickFilters, fundingVehicleId},
			};
		}

		case actionTypes.EXPLORER_QUICKFILTERS_POOL: {
			const poolId = action.value;
			return {
				...state,
				quickFilters: {...state.quickFilters, poolId},
			};
		}

		case actionTypes.EXPLORER_QUICKFILTERS_HYPO_POOL: {
			const hypoPoolId = action.value;
			return {
				...state,
				quickFilters: {...state.quickFilters, hypoPoolId},
			};
		}

		case actionTypes.EXPLORER_QUICKFILTERS_HYPO_FUNDINGVEHICLE: {
			const hypoFundingVehicleId = action.value;
			return {
				...state,
				quickFilters: {...state.quickFilters, hypoFundingVehicleId},
			};
		}

		case actionTypes.VIEW_STARTINCLUSIVE_SET:
			return {
				...state,
				startInclusive: action.startInclusive,
			};

		case actionTypes.EXPLORER_APPLY_BOOKMARK_TO_STATE: {
			return action.newDataExplorer;
		}

		case actionTypes.EXPLORER_DATA_FETCH:
			switch (action.status) {
				case 'pending':
					return {
						...state,
						error: null,
						isLoading: true,
					};

				case 'success':
					return {
						...state,
						data: action.data,
						error: null,
						isLoading: false,
					};

				case 'error':
					return {
						...state,
						error: action.error,
						isLoading: false,
					};

				default:
					return state;
			}

		default:
			return state;
	}
};

export default dataExplorer;
