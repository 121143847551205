import PropTypes from 'prop-types';
import React, {Component} from 'react';
import KiList from 'components/KiList';
import TimeSeriesLink from './TimeSeriesLink';
import SummaryLink from './SummaryLink';
import VisualizationLink from './VisualizationLink';
import GranularityMenu from './GranularityMenu';
import SummaryData from './SummaryData';
import styles from './ColumnMenu.theme.scss';
import _isEqual from 'lodash/isEqual';

export class ColumnMenu extends Component {
	static propTypes = {
		col: PropTypes.object.isRequired,
		show: PropTypes.bool.isRequired,
		handleColumnMenuToggle: PropTypes.func.isRequired,
		toggleSummaryColumnChart: PropTypes.func.isRequired,
		toggleTimeSeriesChart: PropTypes.func.isRequired,
		showSummaryColumnVisualizationLink: PropTypes.bool,
		showTimeSeriesVisualizationLink: PropTypes.bool,
		handleTimeSeriesColumnSelect: PropTypes.func,
		setGranularity: PropTypes.func,
		tableType: PropTypes.string,
		bands: PropTypes.object,
		groupBy: PropTypes.any,
		snapshotType: PropTypes.string,
	};

	state = {
		active: false,
		showSummary: false,
	};

	componentDidUpdate(prevProps) {
		if (!_isEqual(prevProps, this.props)) {
			this.setState({active: this.props.show});
			if (this.props.show) {
				document.addEventListener('click', this.handleClick, false);
			} else {
				document.removeEventListener('click', this.handleClick, false);
			}
		}
	}

	handleClick = e => {
		if (!this.state.active) {
			return;
		}
		if (e.target.textContent === 'Summary' || e.target.textContent === 'info_outline') {
			return;
		}
		this.props.handleColumnMenuToggle(false);
	};

	toggleSummary = () => {
		this.setState({showSummary: !this.state.showSummary});
	};

	render() {
		if (!this.props.show) {
			return null;
		}
		return (
			<section className={styles.list}>
				<KiList selectable>
					<TimeSeriesLink
						className={styles.item}
						col={this.props.col}
						tableType={this.props.tableType}
						handleClick={this.props.handleTimeSeriesColumnSelect}
						groupBy={this.props.groupBy}
						snapshotType={this.props.snapshotType}
					/>
					<VisualizationLink
						className={styles.item}
						show={this.props.showSummaryColumnVisualizationLink}
						handleClick={this.props.toggleSummaryColumnChart}
					/>
					<VisualizationLink
						className={styles.item}
						show={this.props.showTimeSeriesVisualizationLink}
						handleClick={this.props.toggleTimeSeriesChart}
					/>
					<GranularityMenu
						className={`${styles.item} ${styles.granularityMenu}`}
						col={this.props.col}
						handleClick={this.props.setGranularity}
						bands={this.props.bands}
					/>
					<SummaryLink handleClick={this.toggleSummary} />
					{this.state.showSummary && (
						<SummaryData columnId={this.props.col.id} columnType={this.props.col.columnType} />
					)}
				</KiList>
			</section>
		);
	}
}

export default ColumnMenu;
