import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import CsvLink from 'components/CsvLink';
import KiAppBar from 'components/KiAppBar';
import {KiIconCsv} from 'components/KiIcons';
import EditViewRewrite from 'components/EditViewRewrite';
import explorerStyles from 'containers/dataExplorer/dataExplorer.theme.scss';
import {createEligibilityView, updateEligibilityView} from 'containers/eligibilityAnalysis/actions';
import {fetchAllCards, setCurrentCard} from 'containers/dashboards/actions';
import styles from './EligibilityHeader.theme.scss';

export class EligibilityHeader extends Component {
	static propTypes = {
		user: PropTypes.object,
		dataset: PropTypes.object.isRequired,
		eligibilityAnalysis: PropTypes.object.isRequired,
		createEligibilityView: PropTypes.func,
		updateEligibilityView: PropTypes.func,
		history: PropTypes.object,
		fetchAllCards: PropTypes.func,
		setCurrentCard: PropTypes.func.isRequired,
		statementDate: PropTypes.string.isRequired,
	};

	state = {
		isSaveDialogOpen: false,
		areViewActionsActive: true,
		showCardEditor: false,
	};

	toggleSaveDialog = () => {
		this.setState({isSaveDialogOpen: !this.state.isSaveDialogOpen});
	};

	openCardEditor = () => {
		// if a card exists set it in redux for the card editor otherwise new card dialog shows
		return this.props.fetchAllCards(this.props.dataset.datasetId).then(cards => {
			this.props.setCurrentCard(
				cards.find(
					g =>
						g.datasetId === this.props.dataset.datasetId &&
						g.settings.eligibilityViewId === this.props.eligibilityAnalysis.metadata._id
				)
			);
			this.setState({showCardEditor: true});
		});
	};

	closeCardEditor = () => {
		this.setState({showCardEditor: false});
	};

	handleSaveView = view => {
		this.setState(
			{
				isSaveDialogOpen: false,
				areViewActionsActive: false,
			},
			() => {
				this.props.updateEligibilityView(view).then(() => {
					this.setState({areViewActionsActive: true});
				});
			}
		);
	};

	handleCopyView = view => {
		this.setState(
			{
				isSaveDialogOpen: false,
				areViewActionsActive: false,
			},
			() => {
				this.props.createEligibilityView(view).then(newView => {
					this.props.history.replace(`/eligibilityAnalysis/${newView._id}`);
					this.setState({areViewActionsActive: true});
				});
			}
		);
	};

	render() {
		const {user, dataset, eligibilityAnalysis} = this.props;
		const isAdmin = user.groups.findIndex(g => g === 'SystemAdmins') >= 0;
		if (!dataset) return null;
		return (
			<header>
				<KiAppBar className="top-bar">
					<div className={styles.root}>
						<Link to={'/datasets'}>Datasets</Link>
						<Link to={`/dataExplorer/${dataset.datasetId}`}>{dataset.name}</Link>
						<h1>{` > ${eligibilityAnalysis.metadata.name}`}</h1>
					</div>
					<div className={styles.csvLink} alt="Download CSV" title="Download CSV">
						<CsvLink
							viewId={eligibilityAnalysis.metadata._id}
							filename={eligibilityAnalysis.metadata.name}
							viewType="eligibilityViews"
							statementDate={this.props.statementDate}
						/>
					</div>
					<div className={styles.saveDialogContainer}>
						<i className={`material-icons`} title="Click to save the view." onClick={this.toggleSaveDialog}>
							save
						</i>
						{!!this.state.isSaveDialogOpen &&
							!!this.state.areViewActionsActive && (
								<div className={`${explorerStyles.saveDialog} ${styles.saveDialog}`}>
									<EditViewRewrite
										view={eligibilityAnalysis.metadata}
										viewList={eligibilityAnalysis.viewList}
										displayName="Eligibility Analysis"
										cancelFunc={this.toggleSaveDialog}
										saveFunc={this.handleSaveView}
										copyFunc={this.handleCopyView}
										intitialName={
											eligibilityAnalysis.metadata.isGlobal && !isAdmin
												? `${eligibilityAnalysis.metadata.name} Copy`
												: `${eligibilityAnalysis.metadata.name}`
										}
										userId={user.userId}
										isAdmin={isAdmin}
									/>
								</div>
							)}
					</div>
					<KiIconCsv />
				</KiAppBar>
			</header>
		);
	}
}

const mapDispatchToProps = () => ({
	createEligibilityView,
	updateEligibilityView,
	fetchAllCards,
	setCurrentCard,
});

const mapStateToProps = state => ({
	user: state.user,
	dataset: state.datasetList.selected,
	eligibilityAnalysis: state.eligibilityAnalysis,
});

export default connect(mapStateToProps, mapDispatchToProps())(EligibilityHeader);
