import PropTypes from 'prop-types';
import React from 'react';
import TriggerFunctionSelector from 'components/TriggerFunctionSelector';
import _get from 'lodash/get';

export const TimeSeriesPeriodSelector = props => {
	const params = {
		options: _get(props, 'dataset.timeSeriesGranularity', []),
		className: 'snapshot-selector',
		targetFunction: props.targetFunction,
		startingValue: _get(props, 'view.timeseriesPeriod', _get(props, 'explorerRequest.timeseries.period')),
		visible: props.visible || true,
	};

	const classNames = props.visible ? 'time-series-selector' : 'time-series-selector no-display';

	return (
		<div className={classNames}>
			<span className="theme-label form-instruction">Period</span>
			<TriggerFunctionSelector {...params} />
		</div>
	);
};

TimeSeriesPeriodSelector.propTypes = {
	dataset: PropTypes.object,
	explorerRequest: PropTypes.object, //eslint-disable-line react/no-unused-prop-types
	targetFunction: PropTypes.func,
	visible: PropTypes.bool,
	view: PropTypes.object,
};

export default TimeSeriesPeriodSelector;
