import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../common/List.theme.scss';
import classNames from 'classnames';

const ReplineMainCell = ({repline}) => {
	const {name, description, cohorts} = repline;
	const cohortsString = cohorts.map(item => item.displayName).join(', ');

	return (
		<div className={classNames(styles.additionalDataCell, styles.twoLineData)}>
			<div className={styles.mainData}>{name}</div>
			<div className={styles.additionalData}>
				<div title={cohortsString}>Cohorts: {cohortsString}</div>
				<div title={description}>Description: {description}</div>
			</div>
		</div>
	);
};

ReplineMainCell.propTypes = {
	repline: PropTypes.object.isRequired,
};

export default ReplineMainCell;
