import React, {useEffect, useState, useCallback} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import styles from '../common/List.theme.scss';
import {FORECASTING_FLYOUT_MODE} from '../common/FlyoutSwitch';
import KiInput from '../../../../components/KiInput';
import _debounce from 'lodash/debounce';
import KiConfirmModal from '../../../../components/KiConfirmModal';
import NewItemSection from '../common/NewItemSection';
import ListUpdatedAtCell from '../common/ListUpdatedAtCell';
import ListActionsCell from '../common/ListActionsCell';
import classNames from 'classnames';
import ReplineMainCell from './components/ReplineMainCell';
import {getReplines, deleteRepline, getReplineDeps} from '../../../../api/waterfallApi';
import {showSnackbar} from '../../../../state/actions/Snackbar';
/* eslint-disable react/display-name */

const ReplineList = ({datasets, setFlyoutMode, showSnackbar}) => {
	const [isLoading, setIsLoading] = useState(false);
	const [fullReplineList, setFullReplineList] = useState([]);
	const [currentReplines, setCurrentReplines] = useState([]);
	const [searchPhrase, setSearchPhrase] = useState('');
	const [deletionTarget, setDeletionTarget] = useState();
	const [deleteReferentialMsgActive, setDeleteReferentialMsgActive] = useState(false);
	const [replineDeps, setReplineDeps] = useState([]);

	const filterReplines = (phrase, fullList) =>
		setCurrentReplines(fullList.filter(item => item.name.toLowerCase().includes(phrase.toLowerCase())));

	const loadData = () => {
		setIsLoading(true);
		getReplines().then(replines => {
			const mappedReplines = replines.map(repline => ({
				...repline,
				dataset: datasets.find(dataset => dataset.datasetId === repline.datasetId),
			}));
			setFullReplineList(mappedReplines);
			filterReplines(searchPhrase, mappedReplines);
			setIsLoading(false);
		});
	};

	useEffect(() => {
		loadData();
	}, []);

	const columns = React.useMemo(
		() => [
			{
				Header: 'Name',
				accessor: 'name',
				sortable: true,
				Cell: row => <ReplineMainCell repline={row.original} />,
			},
			{
				Header: 'Dataset',
				accessor: 'dataset.name',
				sortable: true,
				Cell: row => <span title={row.value}>{row.value}</span>,
			},
			{
				Header: 'Updated',
				accessor: 'lastUpdated',
				sortable: true,
				Cell: row => <ListUpdatedAtCell value={row?.value} />,
			},
			{
				Header: '',
				Cell: row => (
					<ListActionsCell
						actions={[
							{
								title: 'Edit',
								icon: 'edit',
								callback: () => setFlyoutMode(FORECASTING_FLYOUT_MODE.REPLINES_ADD, row.original._id),
							},
							{title: 'Delete', icon: 'delete', callback: () => setDeletionTarget(row.original)},
						]}
					/>
				),
				sortable: false,
				width: 76,
				className: styles.actionsColumn,
			},
		],
		[]
	);

	const filterReplinesDebounce = useCallback(_debounce(phrase => filterReplines(phrase, fullReplineList), 500), [
		fullReplineList,
	]);

	const onSearch = phrase => {
		setSearchPhrase(phrase);
		filterReplinesDebounce(phrase);
	};

	const onDeleteConfirmation = () => {
		setIsLoading(true);
		getReplineDeps(deletionTarget._id).then(result => {
			if (Array.isArray(result) && result.length > 0) {
				setReplineDeps(result);
				setDeleteReferentialMsgActive(true);
				setDeletionTarget(undefined);
				setIsLoading(false);
			} else {
				deleteRepline(deletionTarget._id).then(() => {
					setDeletionTarget(null);
					showSnackbar('Repline deleted successfully');
					loadData();
				});
			}
		});
	};

	return (
		<>
			<NewItemSection label="Repline Set" onClick={() => setFlyoutMode(FORECASTING_FLYOUT_MODE.REPLINES_ADD)} />
			<section className={styles.filters}>
				<KiInput label="Search Replines" onChange={onSearch} value={searchPhrase} />
			</section>
			<ReactTable
				data={currentReplines}
				columns={columns}
				loading={isLoading}
				className={classNames(styles.table, styles.additionalDataTable)}
				showPagination={false}
				minRows={4}
				defaultSorted={[
					{
						id: 'name',
						desc: false,
					},
				]}
				defaultPageSize={1000}
			/>
			<KiConfirmModal
				id="ReplineDeletionConfirmModal"
				header="Delete Repline"
				message={`Are you sure you want to delete the "${deletionTarget?.name}"?`}
				acceptFunc={onDeleteConfirmation}
				rejectFunc={() => setDeletionTarget(null)}
				acceptLabel="Delete"
				rejectLabel="Cancel"
				active={!!deletionTarget}
			/>
			<KiConfirmModal
				active={deleteReferentialMsgActive}
				acceptDisabled={true}
				rejectFunc={() => setDeleteReferentialMsgActive(false)}
				rejectLabel="OK"
				header="Cannot delete"
				message="The repline could not be deleted because it is used in the following scenarios"
			>
				{replineDeps.map(c => {
					return <li key={c._id}>{c.name}</li>;
				})}
			</KiConfirmModal>
		</>
	);
};

ReplineList.propTypes = {
	datasets: PropTypes.array,
	setFlyoutMode: PropTypes.func.isRequired,
	isLoading: PropTypes.bool,
	showSnackbar: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	datasets: state.datasetList.data,
});

export default connect(mapStateToProps, {showSnackbar})(ReplineList);
