import React, {useState, useEffect, useContext} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import classNames from 'classnames';
import _ from 'lodash';
// Local imports
import FundingAnalysisContext from '../../fundingAnalysisContext';
import {fetchAccounts} from 'containers/accounts/actions';
import {showSnackbar} from 'state/actions/Snackbar';
import {upsertBookmark, deleteBookmark} from 'api/fundingAnalysisApi';
import KiFontIcon from 'components/KiFontIcon';
import KiButton from 'components/KiButton';
import KiProgressBar from 'components/KiProgressBar';
import styles from 'components/FlyoutManageViews/FlyoutManageViews.theme.scss';
import FlyoutConfirmPanel from 'components/FlyoutConfirmPanel';
import {closeContextSidebarPanel} from 'state/actions/App';

function ViewsList() {
	const fundingAnalysisContext = useContext(FundingAnalysisContext);
	const dispatch = useDispatch();
	const history = useHistory();
	const user = useSelector(state => state.user);
	const isAdmin = useSelector(state => state.user.groups.includes('SystemAdmins'));
	const dataset = useSelector(state => state.datasetList.selected);

	const [searchTerm, setSearchTerm] = useState('');
	//const [selectedUserId, setSelectedUserId] = useState(null);
	const [showConfirmDelete, setShowConfirmDelete] = useState(false);
	const [selectedBookmark, setSelectedBookmark] = useState(null);
	const [loadingBookmarks, setLoadingBookmarks] = useState(false);

	useEffect(() => {
		async function fetchData() {
			setLoadingBookmarks(true);
			fetchAccounts();
			await fundingAnalysisContext.fetchBookmarks(dataset.datasetId);
			setLoadingBookmarks(false);
		}
		fetchData();
	}, []);

	const handleDeleteBookmarkClick = bookmark => {
		setSelectedBookmark(bookmark);
		setShowConfirmDelete(true);
	};

	const handleDeleteBookmark = () => {
		return deleteBookmark(selectedBookmark._id)
			.then(() => {
				setShowConfirmDelete(false);
				fundingAnalysisContext.fetchBookmarks(dataset.datasetId).then(bookmarks => {
					if (selectedBookmark._id === fundingAnalysisContext.bookmark._id) {
						const bookmarkToRedirect = bookmarks.find(bookmark => bookmark.isDefault) || bookmarks[0];
						const bookmarkUrlPart = bookmarkToRedirect ? `/bookmark/${bookmarkToRedirect._id}` : '';
						history.push(`/fundingAnalysis/${dataset.datasetId}${bookmarkUrlPart}`);
					}
				});
				dispatch(showSnackbar('Bookmark successfully deleted.'));
			})
			.catch(err => {
				fundingAnalysisContext.addError(`[ Error deleting ${selectedBookmark.name} ] - ${err.message}`);
			});
	};

	const onCopyBookmark = async bookmark => {
		try {
			const bookmarkToSave = _.cloneDeep(bookmark);
			const copiedName = `${bookmarkToSave.name}_copy`;
			delete bookmarkToSave._id;
			bookmarkToSave.name = copiedName;
			bookmarkToSave.isSystem = false;
			await upsertBookmark(bookmarkToSave);
			fundingAnalysisContext.fetchBookmarks(dataset.datasetId);
			dispatch(showSnackbar('Bookmark successfully copied.'));
		} catch (err) {
			fundingAnalysisContext.addError(`[ Error copying ${bookmark.name} ] - ${err.message}`);
		}
	};

	const filteredBookmarks = fundingAnalysisContext.allBookmarks
		.filter(bookmark => isAdmin || bookmark.isGlobal || bookmark.createdBy === user.userId)
		.filter(
			bookmark =>
				!searchTerm ||
				(bookmark.name.toLowerCase().includes(searchTerm) ||
					bookmark.tags.find(t =>
						t
							.toString()
							.toLowerCase()
							.includes(searchTerm)
					))
		);

	if (showConfirmDelete) {
		return (
			<FlyoutConfirmPanel
				header={'Delete'}
				message={`Are you sure you want to delete the following view? ${_.get(selectedBookmark, 'name', '')}`}
				acceptFunc={() => handleDeleteBookmark(selectedBookmark._id)}
				rejectFunc={() => {
					setShowConfirmDelete(false);
					setSelectedBookmark(null);
				}}
				acceptLabel={'DELETE'}
				rejectLabel={'CANCEL'}
			/>
		);
	}

	return (
		<div className="context-sidebar-panel-flex">
			<header className="flyout-panel-title">MANAGE VIEWS</header>
			<div>
				<section className="flyout-panel-search">
					<p>Search a View</p>
					<input placeholder="Name or Tag" value={searchTerm} onChange={e => setSearchTerm(e.target.value)} />
				</section>
				{!filteredBookmarks.length ? (
					loadingBookmarks ? (
						<KiProgressBar />
					) : (
						<p>No Views Found</p>
					)
				) : (
					<section className={styles.listContainer}>
						{filteredBookmarks.map((bookmark, index) => {
							return (
								<div className={styles.row} key={index}>
									<div className={styles.rowInfo}>
										<div
											className={classNames({
												'list-icon-btn': true,
												'link-text': true,
												'active-text': bookmark._id === fundingAnalysisContext.bookmark._id,
												'no-rollover': true,
											})}
											onClick={() => {
												history.push(
													`/fundingAnalysis/${dataset.datasetId}/bookmark/${bookmark._id}`
												);
												fundingAnalysisContext.setStateItem('tabIndex', 0);
											}}
										>
											{bookmark.name}
										</div>
										{bookmark.isDefault && (
											<div
												className={classNames({
													'list-icon-btn': true,
													'active-text': bookmark._id === fundingAnalysisContext.bookmark._id,
													'no-rollover': true,
												})}
											>
												<i title="Default" className="material-icons">
													home
												</i>
											</div>
										)}
										{bookmark.isFavorite && (
											<div
												className={classNames({
													'list-icon-btn': true,
													'active-text': bookmark._id === fundingAnalysisContext.bookmark._id,
													'no-rollover': true,
												})}
											>
												<i title="Favorite" className="material-icons">
													star
												</i>
											</div>
										)}
									</div>
									<div className={styles.rowActions}>
										<KiFontIcon
											className="list-icon-btn"
											value="content_copy"
											title="Copy"
											onClick={() => onCopyBookmark(bookmark)}
										/>
										<KiFontIcon
											style={{marginRight: '0px'}}
											className={`list-icon-btn ${
												!isAdmin || bookmark.isSystem ? 'disabled' : ''
											}`}
											value="delete"
											title="Delete"
											onClick={() => handleDeleteBookmarkClick(bookmark)}
										/>
									</div>
								</div>
							);
						})}
					</section>
				)}
			</div>
			<footer className={styles.editViewFooter}>
				<KiButton flat onClick={() => dispatch(closeContextSidebarPanel())}>
					Close
				</KiButton>
			</footer>
		</div>
	);
}

export default ViewsList;
