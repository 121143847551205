import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table-6';
import _get from 'lodash/get';
import KiProgressBar from 'components/KiProgressBar';
import KiFontIcon from 'components/KiFontIcon';
import {columnNameMap, simpleReactTableColumns} from 'ki-common/utils/eligibilityAnalysisUtil';
import styles from './EligibilityTable.theme.scss';

export class EligibilityTable extends Component {
	static propTypes = {
		metadata: PropTypes.object.isRequired,
		isLoading: PropTypes.bool,
		data: PropTypes.array,
		error: PropTypes.object,
	};

	PrevComponent = props => {
		return (
			<div className={'pagination'} onClick={props.onClick}>
				<li>
					<KiFontIcon value="chevron_left" className="pager-icon" />
				</li>
			</div>
		);
	};

	NextComponent = props => {
		return (
			<div className={'pagination'} onClick={props.onClick}>
				<li>
					<KiFontIcon value="chevron_right" className="pager-icon" />
				</li>
			</div>
		);
	};

	getHeader = (displayName, columnName) => {
		const sortColumn = this.props.metadata?.sortColumn || '';
		const sortOrder = this.props.metadata?.sortOrder || '';
		const upActive = sortColumn === columnName && sortOrder === 'asc' ? 'active' : 'false';
		const downActive = sortColumn === columnName && sortOrder === 'desc' ? 'active' : 'false';
		return (
			<div className="table-header">
				<div className="table-header-title">
					<div className="title-wrapper">
						<div>
							<div className="display-name" title={displayName}>
								{displayName}
							</div>
						</div>
					</div>
					<div className={'sort-arrows'} style={{cursor: 'pointer'}}>
						<i className={`material-icons sort-up ${upActive}`}>arrow_drop_up</i>
						<i className={`material-icons sort-down ${downActive}`}>arrow_drop_down</i>
					</div>
				</div>
			</div>
		);
	};

	getCell = (row, callback) => {
		return <div className="table-cell">{callback(row)}</div>;
	};

	reactTableColumns = () => {
		const columns = simpleReactTableColumns.map(entry => {
			return Object.assign({}, entry, {
				Header: this.getHeader(columnNameMap.get(entry.accessor), entry.accessor),
				Cell: row => this.getCell(row, entry.Cell),
			});
		});
		return columns;
	};

	render() {
		if (this.props.isLoading) {
			return <KiProgressBar />;
		}

		if (this.props.error?.message) {
			return (
				<p
					style={{
						color: 'red',
						display: 'flex',
						justifyContent: 'center',
						wordBreak: 'break-all',
					}}
				>
					{_get(this, 'props.error.message', 'An error occurred')}
				</p>
			);
		}

		if (!this.props.data) {
			return (
				<p
					style={{
						display: 'flex',
						justifyContent: 'center',
						wordBreak: 'break-all',
					}}
				>
					No data found.
				</p>
			);
		}

		return (
			<div className={styles.container}>
				<ReactTable
					className={`-highlight data-explorer-table ${styles.table}`}
					loading={this.props.isLoading}
					PreviousComponent={this.PrevComponent}
					NextComponent={this.NextComponent}
					data={this.props.data}
					columns={this.reactTableColumns()}
					defaultPageSize={10}
				/>
			</div>
		);
	}
}

export default EligibilityTable;
