// Lib Imports
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import _get from 'lodash/get';
// App Imports
import {fetchDatasetList, fetchDataset} from 'containers/datasetList/actions';
import {fetchFundingVehicleList} from 'containers/fundingVehicleList/actions';
import {eligibilityViewsApi} from 'api';
import KiAppBar from 'components/KiAppBar';
import KiProgressBar from 'components/KiProgressBar';
// Local Import
import styles from './eligibilityAnalysis.theme.scss';
import EligibilityHeader from './components/EligibilityHeader';
import EligibilityControls from './components/EligibilityControls';
import EligibilityTable from './components/EligibilityTable';
import EligibilitySidebar from './components/EligibilitySidebar';
import {fetchEAViewsByDatasetId, setPageMetadata} from './actions';
import {dateToShortDate} from 'ki-common/utils/dateHelpers';

export class EligibilityAnalysis extends Component {
	static propTypes = {
		match: PropTypes.object,
		history: PropTypes.object,
		app: PropTypes.object.isRequired,
		user: PropTypes.object, //eslint-disable-line react/no-unused-prop-types
		/* Redux */
		dataset: PropTypes.object.isRequired,
		eligibilityAnalysis: PropTypes.object.isRequired,
		fetchDatasetList: PropTypes.func,
		fetchDataset: PropTypes.func,
		fetchFundingVehicleList: PropTypes.func,
		setPageMetadata: PropTypes.func,
		fetchEAViewsByDatasetId: PropTypes.func,
	};

	componentDidMount() {
		document.title = `${this.props.app.kiVersion} - Eligibility Analysis`;
		const {match} = this.props;
		const id = _get(match, 'params.id', null);
		const datasetId = this.props.dataset.datasetId;
		// Look for eligibilityAnalaysis ID in the URL to load
		// If no ID is found use the default for this dataset
		const promises = [
			id
				? eligibilityViewsApi.fetchEAViewById(id)
				: eligibilityViewsApi.fetchEADefaultView(this.props.dataset.datasetId),
			this.props.fetchDatasetList(),
			this.props.fetchFundingVehicleList(),
		];
		return Promise.all(promises).then(results => {
			const metadata = results[0];
			// Fetch/set in redux the dataset to match this view
			this.props.fetchDataset(datasetId || metadata.datasetId);
			// If there is no ID in the URL, update the URL with this view ID
			if (!id) {
				this.props.history.replace(`/eligibilityAnalysis/${metadata._id}`);
			}
			// Update redux with new metadata
			this.props.setPageMetadata(metadata);
			this.props.fetchEAViewsByDatasetId(metadata.datasetId);
		});
	}

	componentDidUpdate(prevProps) {
		const paramId = _get(this.props.match, 'params.id', '');
		const oldId = _get(prevProps, 'eligibilityAnalysis.metadata._id', '').toString();
		const newId = _get(this.props, 'eligibilityAnalysis.metadata._id', '').toString();
		// Test to see if the paramId should be fetched
		// all three values must exist. cannot be first time page loads
		// if url is different but oldId and newId are the same fetch by url ID
		if (paramId && oldId && newId && oldId === newId && oldId !== paramId) {
			eligibilityViewsApi.fetchEAViewById(paramId).then(metadata => {
				this.props.setPageMetadata(metadata);
			});
		}
	}

	render() {
		// const isAdmin = this.props.user.groups.findIndex(g => g === 'SystemAdmins') >= 0;
		const {dataset, eligibilityAnalysis} = this.props;
		const metadata = eligibilityAnalysis.metadata;

		if (!metadata || !metadata.datasetId || !dataset) {
			return (
				<div className={styles.root}>
					<div className="container-body">
						<header>
							<KiAppBar className="top-bar">
								<div className="top-bar-breadcrumb">
									<h1
										className="link"
										onClick={() => this.props.history.push('/eligibilityAnalysis')}
									>
										Eligibility Analysis{' '}
									</h1>
								</div>
							</KiAppBar>
						</header>
						<div className="ki-panel">
							<KiProgressBar />
						</div>
					</div>
				</div>
			);
		}

		return (
			<div className={styles.root}>
				<div className="container-body">
					<EligibilityHeader
						history={this.props.history}
						statementDate={
							this.props.eligibilityAnalysis.metadata.statementDate || dateToShortDate(new Date())
						}
					/>
					<EligibilityControls />
					<EligibilityTable
						metadata={this.props.eligibilityAnalysis.metadata}
						data={this.props.eligibilityAnalysis.data}
						isLoading={this.props.eligibilityAnalysis.isLoading}
						error={this.props.eligibilityAnalysis.error}
					/>
				</div>
				<EligibilitySidebar history={this.props.history} />
			</div>
		);
	}
}

const mapDispatchToProps = () => ({
	fetchDatasetList,
	fetchDataset,
	fetchFundingVehicleList,
	setPageMetadata,
	fetchEAViewsByDatasetId,
});

const mapStateToProps = state => ({
	app: state.app,
	user: state.user,
	dataset: state.datasetList.selected,
	datasetList: state.datasetList.data,
	eligibilityAnalysis: state.eligibilityAnalysis,
	fundingVehicleList: state.fundingVehicleList.data,
});

export default connect(mapStateToProps, mapDispatchToProps())(EligibilityAnalysis);
