import PropTypes from 'prop-types';
import React, {Component} from 'react';
import styles from './editView.theme.scss';
import CreatableSelect from 'react-select/creatable';
import KiInput from 'components/KiInput';
import KiButton from 'components/KiButton';
import KiCheckbox from 'components/KiCheckbox';
import _get from 'lodash/get';

export class EditViewRewrite extends Component {
	static propTypes = {
		view: PropTypes.object,
		viewList: PropTypes.array,
		displayName: PropTypes.string,
		cancelFunc: PropTypes.func,
		saveFunc: PropTypes.func,
		copyFunc: PropTypes.func,
		intitialName: PropTypes.string,
		userId: PropTypes.string, //eslint-disable-line react/no-unused-prop-types
		isAdmin: PropTypes.bool,
	};

	static defaultProps = {
		view: {},
		viewList: [],
		displayName: 'View',
	};

	state = {
		formValues: {
			name: this.props.intitialName || this.props.view.name || '',
			tags: _get(this.props.view, 'tags', []).map(t => {
				return {value: t, label: t};
			}),
			isDefault: this.props.view.isDefault,
			isFavorite: this.props.view.isFavorite,
			isGlobal: this.props.view.isGlobal,
		},
		isCopy: !this.props.isAdmin || this.props.view.createdBy !== this.props.userId,
		formErrors: {},
		nameExistsWarning: null,
	};

	valueChanged = (name, value) => {
		this.setState(
			{
				formValues: {...this.state.formValues, [name]: value},
			},
			() => {
				if (name === 'name') {
					this.validateField(name, value);
				}
			}
		);
	};

	validateField = (name, value) => {
		if (value === '') {
			this.setState({
				formErrors: {...this.state.formErrors, [name]: 'Required'},
			});
		} else {
			if (this.props.view.name !== value && this.props.viewList.find(v => v.name === value)) {
				this.setState({
					nameExistsWarning: `A ${
						this.props.displayName
					} with the name ${value} exists already and will be replaced.`,
				});
			} else {
				this.setState({nameExistsWarning: null});
			}
			this.setState({
				formErrors: {...this.state.formErrors, [name]: null},
			});
		}
	};

	handleTagsChanged = vals => {
		this.setState({
			formValues: {...this.state.formValues, tags: vals},
		});
	};

	getUpdatedView = () => {
		const view = Object.assign(
			{}, // crucial because otherwise you assign to the prop object directly
			this.props.view,
			{name: this.state.formValues.name},
			{isDefault: this.state.formValues.isDefault},
			{tags: _get(this.state.formValues, 'tags', []).map(t => t.value)},
			{isFavorite: this.state.formValues.isFavorite}
		);
		// Only an admin can make a global view
		view.isGlobal = this.props.isAdmin ? this.state.formValues.isGlobal : false;
		// userId change will be handled by controller
		return view;
	};

	handleCancel = () => {
		this.setState(
			{
				formValues: {
					name: this.props.intitialName || this.props.view.name,
					tags: _get(this.props.view, 'tags', []).map(t => {
						return {value: t, label: t};
					}),
					isDefault: this.props.view.isDefault,
					isFavorite: this.props.view.isFavorite,
					isGlobal: this.props.view.isGlobal,
				},
				formErrors: {},
				nameExistsWarning: null,
			},
			() => this.props.cancelFunc()
		);
	};

	handleSave = () => {
		if (this.state.formValues.name) {
			this.props.saveFunc(this.getUpdatedView());
		} else {
			this.validateField('name', this.state.formValues.name);
		}
	};

	handleCopy = () => {
		const nameValue = this.state.formValues.name;
		if (!nameValue) {
			this.validateField('name', nameValue);
		} else if (this.props.view.name === nameValue || this.props.viewList.find(v => v.name === nameValue)) {
			this.setState({
				formErrors: {
					...this.state.formErrors,
					name: `${this.props.displayName} name already exists. Please enter another name.`,
				},
			});
		} else {
			this.props.copyFunc(this.getUpdatedView());
		}
	};

	render() {
		return (
			<div>
				<div className={styles.title}>{this.state.isCopy ? 'Copy' : 'Edit'}</div>
				<div className={styles.form}>
					<KiInput
						name="name"
						label={`${this.props.displayName} Name`}
						type="text"
						value={this.state.formValues.name}
						error={this.state.formErrors.name}
						onChange={val => this.valueChanged('name', val)}
					/>
					<CreatableSelect
						classNamePrefix="aut-select"
						isMulti={true}
						options={[]}
						onChange={this.handleTagsChanged}
						placeholder={'Add Tags'}
						isClearable={true}
						value={this.state.formValues.tags}
					/>
				</div>
				<KiCheckbox
					name="isDefault"
					checked={this.state.formValues.isDefault}
					label="Use as Default For Dataset"
					onChange={val => this.valueChanged('isDefault', val)}
				/>
				<KiCheckbox
					name="isFavorite"
					checked={this.state.formValues.isFavorite}
					label="Favorite"
					onChange={val => this.valueChanged('isFavorite', val)}
				/>
				{this.props.isAdmin &&
					!this.props.view.isGlobal && (
						<KiCheckbox
							name="isGlobal"
							checked={this.state.formValues.isGlobal}
							label="Make Global"
							onChange={val => this.valueChanged('isGlobal', val)}
						/>
					)}
				<div className={`${styles.warningText} ${this.state.nameExistsWarning ? '' : styles.hidden}`}>
					{this.state.nameExistsWarning}
				</div>
				<div
					className={`${styles.warningText} ${
						!this.props.view.isDefault &&
						this.state.formValues.isDefault &&
						this.props.viewList.find(v => v.isDefault == true)
							? ''
							: styles.hidden
					}`}
				>
					A default already exists and will be replaced.
				</div>
				<div className={styles.formButtons}>
					<KiButton flat primary onClick={this.handleCancel} label="Cancel" />
					<KiButton raised primary onClick={this.handleCopy} label="Save As" />
					<KiButton
						raised
						primary
						onClick={this.state.isCopy ? this.handleCopy : this.handleSave}
						label="Save"
					/>
				</div>
			</div>
		);
	}
}
export default EditViewRewrite;
