// Dependencies
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import Select from 'react-select';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import KiCheckbox from 'components/KiCheckbox';
// Externals
import 'components/ColumnPicker/columnpicker.scss';
import KiButton from 'components/KiButton';
import {KiCreatable} from 'components/KiSelect';
import KiInput from 'components/KiInput';
import DebtFormulasList from 'components/ColumnPicker/forms/components/DebtFormulasList';
import DebtFormulasListNumeric from 'components/ColumnPicker/forms/components/DebtFormulasListNumeric';
import TriggersModal from '../modals/TriggersModal';
import TriggersModalNumeric from '../modals/TriggersModalNumeric';
import TriggersModalRatings from '../modals/TriggersModalRatings';
import {fetchAllReportingDates} from 'containers/fundingVehicle/actions';
import collateralMapList from 'ki-common/options/collateralMapList';
import stringHelpers from 'ki-common/utils/stringHelpers';
import DebtFormulasListRating from '../../../../components/ColumnPicker/forms/components/DebtFormulasListRatings';

export const triggerTypes = [
	{
		value: 'boolean',
		label: 'Boolean',
	},
	{
		value: 'numeric',
		label: 'Numeric',
	},
	{
		value: 'entityRating',
		label: 'Ratings',
	},
];

export class TriggersForm extends Component {
	static propTypes = {
		fetchAllReportingDates: PropTypes.func,
		submitMethod: PropTypes.func.isRequired,
		columnList: PropTypes.array.isRequired,
		triggersList: PropTypes.array.isRequired,
		existingTags: PropTypes.array.isRequired,
		columnToEdit: PropTypes.object,
		closeForm: PropTypes.func,
		isAdmin: PropTypes.bool,
		fundingVehicles: PropTypes.array,
		datasetId: PropTypes.string,
	};

	static defaultProps = {
		existingTags: [],
		fundingVehicles: [],
		triggersList: [],
	};

	state = {
		name: '',
		nameError: null,
		tags: [],
		type: null,
		formulas: [],
		reportDates: [],
		isCurable: false,
		notes: '',
	};

	componentDidMount() {
		const {columnToEdit} = this.props;

		if (!_isEmpty(columnToEdit)) {
			this.setState(state => {
				return {
					name: columnToEdit.name || state.name,
					tags: columnToEdit.tags || state.tags,
					type: columnToEdit.type || state.type,
					formulas: columnToEdit.formulas || state.formulas,
					isCurable: columnToEdit.isCurable || state.isCurable,
					notes: columnToEdit.notes || state.notes,
				};
			});
		}
	}

	deriveUsedFundingVehiclesFromFormulas = (formulas = []) => {
		return formulas.map(f => f.fundingVehicle);
	};

	isDuplicateNameError = () => {
		if (_get(this, 'props.columnToEdit.name') === this.state.name) {
			return;
		}

		const assetCols = this.props.columnList.filter(col => !!col.formulas && col.columnType === 'asset');
		// Check if the name already exists in assetCols
		return assetCols.find(col => _get(col, 'displayName').trim() === this.state.displayName.trim());
	};

	setName = val => {
		let nameError = null;

		if (!val.trim().length) {
			nameError = "Name can't be blank";
		}

		if (!val.match(stringHelpers.alphanumericRegex)) {
			nameError = 'Name must be Alphanumeric!';
		}

		this.setState({
			name: val,
			nameError: nameError,
		});
	};

	handleConfirm = newFormula => {
		this.setState(state => {
			return {
				formulas: !Number.isInteger(this.state.selectedFormulaIndex)
					? [newFormula, ...this.state.formulas]
					: state.formulas.reduce(
							(acc, formula, idx) => [
								...acc,
								idx === this.state.selectedFormulaIndex ? newFormula : formula,
							],
							[]
					  ),
			};
		});
	};

	onSubmit = () => {
		const {columnToEdit} = this.props;
		const columnForSaving = {
			name: this.state.name,
			tags: this.state.tags,
			type: this.state.type,
			notes: this.state.notes,
			isCurable: this.state.isCurable,
			createdBy: columnToEdit ? columnToEdit.createdBy : null,
			dataType: 'boolean',
			formulas: this.state.formulas,
			isGlobal: true, //force global
			isWaterfall: true,
		};
		if (columnToEdit) {
			columnForSaving._id = columnToEdit._id;
		}
		return this.props.submitMethod(columnForSaving).then(() => {
			this.props.closeForm();
		});
	};

	prePopulateFormulaTable = formula => {
		const formattedRows = [];
		let rowIndex = 0;
		let rowToPopulate = {
			id: 0,
			logic: '',
			value: undefined,
			closingGroup: [],
			openingGroup: [],
		};
		// Reformat formula to be a nested array
		for (let i = 0; i < formula.length; i++) {
			const formulaItem = formula[i];

			if (['and', 'or'].includes(formulaItem.value)) {
				rowIndex++;
				formattedRows.push(rowToPopulate);
				rowToPopulate = {
					id: rowIndex,
					logic: {label: formulaItem.value.toUpperCase(), value: formulaItem.value},
					value: undefined,
					closingGroup: [],
					openingGroup: [],
				};
			}

			if (formulaItem.type === 'column') {
				const foundColumn = this.props.columnList
					.concat(
						collateralMapList.map(item => ({
							_id: item.name,
							displayName: item.displayName,
							columnName: item.displayName,
							dataType: 'numeric',
							columnType: 'waterfall',
						}))
					)
					.concat(this.props.triggersList)
					.find(column => {
						if (['debt', 'debtInputFV', 'debtInputTranche', 'waterfall'].includes(column.columnType)) {
							return (column._id || column.columnName) === formulaItem.value;
						}
						return column._id === formulaItem.value;
					});
				if (
					foundColumn &&
					['debt', 'debtInputFV', 'debtInputTranche', 'waterfall'].includes(foundColumn.columnType)
				) {
					foundColumn.columnName = foundColumn.columnName || foundColumn.name;
				}

				rowToPopulate.column = foundColumn;
				rowToPopulate.type = {label: 'Column', value: 'column'};
			}

			if (formulaItem.value === '(') {
				rowToPopulate.openingGroup.push({
					type: 'operator',
					value: formulaItem.value,
				});
			}

			if (formulaItem.value === ')') {
				rowToPopulate.closingGroup.push({
					type: 'operator',
					value: formulaItem.value,
				});
			}
		}

		if (rowToPopulate.column) {
			formattedRows.push(rowToPopulate);
		}

		return formattedRows;
	};

	render() {
		const {isAdmin} = this.props;
		const {name, nameError} = this.state;

		const saveDisabled = !this.state.name.trim().length || !this.state.formulas.length;

		return (
			<Fragment>
				<form className="column-selector-form inline-column-form">
					<div style={{flex: '1 1', overflowY: 'auto'}}>
						<section className="calculation-name-tags">
							<KiInput
								readOnly={!isAdmin}
								label="Name"
								value={name}
								onChange={val => this.setName(val)}
								error={nameError}
							/>
							<span className="form-instruction">Tags</span>
							<KiCreatable
								isDisabled={!isAdmin}
								classNamePrefix="aut-select"
								isMulti={true}
								options={this.props.existingTags.map(t => ({
									value: t,
									label: t,
								}))}
								value={this.state.tags.map(t => ({
									value: t,
									label: t,
								}))}
								onChange={val => this.setState({tags: val.map(t => t.value)})}
								placeholder="Add some tags"
								readonly={!isAdmin}
							/>
							<span className="form-instruction">Type</span>
							<Select
								isDisabled={!isAdmin || _get(this.props, 'columnToEdit.type')}
								classNamePrefix="aut-select"
								value={triggerTypes.find(opt => opt.value === this.state.type)}
								isClearable={false}
								options={triggerTypes}
								onChange={selected =>
									this.setState({
										type: selected.value,
										formulas: selected.value !== this.state.type ? [] : this.state.formulas,
									})
								}
							/>
							<KiInput
								label={'Notes'}
								readOnly={!isAdmin}
								value={this.state.notes}
								onChange={val => this.setState({notes: val})}
							/>
							<KiCheckbox
								name="curable"
								checked={this.state.isCurable}
								label="Curable"
								onChange={val => this.setState({isCurable: val})}
							/>
						</section>
						{this.state.type === 'boolean' && (
							<DebtFormulasList
								isAdmin={isAdmin}
								formulas={_get(this.state, 'formulas', [])}
								triggersList={this.props.triggersList}
								columns={
									this.state.isWaterfall
										? this.props.columnList.filter(col => col.isWaterfall === true).concat(
												collateralMapList.map(item => ({
													_id: item.name,
													displayName: item.displayName,
													columnName: item.displayName,
													dataType: 'numeric',
													columnType: 'waterfall',
												}))
										  )
										: this.props.columnList
								}
								fundingVehicles={this.props.fundingVehicles}
								onEdit={(formula, idx) => {
									this.setState({
										selectedFormula: formula,
										selectedFormulaIndex: Number.isInteger(idx) ? idx : null,
									});
								}}
								onDelete={idx =>
									this.setState({
										selectedFormula: null,
										selectedFormulaIndex: null,
										formulas: this.state.formulas.filter((x, fidx) => idx !== fidx),
									})
								}
							/>
						)}
						{this.state.type === 'numeric' && (
							<DebtFormulasListNumeric
								isAdmin={isAdmin}
								formulas={_get(this.state, 'formulas', [])}
								fundingVehicles={this.props.fundingVehicles}
								onEdit={(formula, idx) => {
									this.setState({
										selectedFormula: formula,
										selectedFormulaIndex: Number.isInteger(idx) ? idx : null,
									});
								}}
								onDelete={idx =>
									this.setState({
										selectedFormula: null,
										selectedFormulaIndex: null,
										formulas: this.state.formulas.filter((x, fidx) => idx !== fidx),
									})
								}
							/>
						)}
						{this.state.type === 'entityRating' && (
							<DebtFormulasListRating
								isAdmin={isAdmin}
								formulas={_get(this.state, 'formulas', [])}
								fundingVehicles={this.props.fundingVehicles}
								onEdit={(formula, idx) => {
									this.setState({
										selectedFormula: formula,
										selectedFormulaIndex: Number.isInteger(idx) ? idx : null,
									});
								}}
								onDelete={idx =>
									this.setState({
										selectedFormula: null,
										selectedFormulaIndex: null,
										formulas: this.state.formulas.filter((x, fidx) => idx !== fidx),
									})
								}
							/>
						)}
					</div>
					<section className="format-and-save">
						<div className="inline-column-form-buttons">
							<KiButton flat primary onClick={() => this.props.closeForm()}>
								Cancel
							</KiButton>
							<KiButton
								disabled={!isAdmin || saveDisabled}
								raised
								primary
								onClick={() => this.onSubmit()}
							>
								Save
							</KiButton>
						</div>
					</section>
				</form>
				{this.state.selectedFormula &&
					this.state.type === 'boolean' && (
						<TriggersModal
							handleConfirm={newFormula =>
								this.handleConfirm(
									newFormula,
									Number.isInteger(this.state.selectedFormulaIndex)
										? this.state.selectedFormulaIndex
										: null
								)
							}
							triggersList={this.props.triggersList}
							active={!!this.state.selectedFormula}
							closeForm={() => this.setState({selectedFormula: null})}
							toggleVisibility={() => this.setState({selectedFormula: null})}
							selectedFormula={this.state.selectedFormula}
							onSelectFormula={formula => this.setState({selectedFormula: formula})}
							fundingVehicles={this.props.fundingVehicles}
							usedFundingVehicles={this.deriveUsedFundingVehiclesFromFormulas(this.state.formulas)}
							columns={this.props.columnList}
							columnToEdit={this.props.columnToEdit}
							prePopulateFormulaTable={this.prePopulateFormulaTable}
							isWaterfall={this.state.isWaterfall}
							fetchAllReportingDates={this.props.fetchAllReportingDates}
						/>
					)}
				{this.state.selectedFormula &&
					this.state.type === 'numeric' && (
						<TriggersModalNumeric
							handleConfirm={newFormula =>
								this.handleConfirm(
									newFormula,
									Number.isInteger(this.state.selectedFormulaIndex)
										? this.state.selectedFormulaIndex
										: null
								)
							}
							active={!!this.state.selectedFormula}
							closeForm={() => this.setState({selectedFormula: null})}
							toggleVisibility={() => this.setState({selectedFormula: null})}
							selectedFormula={this.state.selectedFormula}
							onSelectFormula={formula => this.setState({selectedFormula: formula})}
							fundingVehicles={this.props.fundingVehicles}
							usedFundingVehicles={this.deriveUsedFundingVehiclesFromFormulas(this.state.formulas)}
							columns={this.props.columnList.filter(c => c.dataType === 'numeric')}
							columnToEdit={this.props.columnToEdit}
							isWaterfall={this.state.isWaterfall}
						/>
					)}
				{this.state.selectedFormula &&
					this.state.type === 'entityRating' && (
						<TriggersModalRatings
							handleConfirm={newFormula =>
								this.handleConfirm(
									newFormula,
									Number.isInteger(this.state.selectedFormulaIndex)
										? this.state.selectedFormulaIndex
										: null
								)
							}
							active={!!this.state.selectedFormula}
							closeForm={() => this.setState({selectedFormula: null})}
							toggleVisibility={() => this.setState({selectedFormula: null})}
							selectedFormula={this.state.selectedFormula}
							onSelectFormula={formula => this.setState({selectedFormula: formula})}
							fundingVehicles={this.props.fundingVehicles}
							usedFundingVehicles={this.deriveUsedFundingVehiclesFromFormulas(this.state.formulas)}
							columns={this.props.columnList.filter(c => c.dataType === 'numeric')}
							columnToEdit={this.props.columnToEdit}
							isWaterfall={this.state.isWaterfall}
							datasetId={this.props.datasetId}
						/>
					)}
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	fundingVehicle: state.fundingVehicle.selected,
});

const mapDispatchToProps = () => ({
	fetchAllReportingDates,
});

export default connect(mapStateToProps, mapDispatchToProps())(TriggersForm);
