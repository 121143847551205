import React, {Component} from 'react';
import PropTypes from 'prop-types';
import flyoutManageViewStyles from 'components/FlyoutManageViews/FlyoutManageViews.theme.scss';
import classNames from 'classnames';

export class ViewListItem extends Component {
	static propTypes = {
		history: PropTypes.object,
		view: PropTypes.object,
		currentViewId: PropTypes.string,
		isAdmin: PropTypes.bool,
		deleteView: PropTypes.func,
		closeContextSidebarPanel: PropTypes.func,
	};

	renderViewLink = (view, isAdmin, actionName, iconName, onClick) => {
		const isDisabled = !isAdmin && view.isGlobal;
		return isDisabled ? (
			<div className="list-icon-btn">
				<i title={actionName} alt={`${actionName} View`} className={`material-icons disabled`}>
					{iconName}
				</i>
			</div>
		) : (
			<div className="list-icon-btn">
				<i title={actionName} alt={`${actionName} View`} className="material-icons" onClick={onClick}>
					{iconName}
				</i>
			</div>
		);
	};

	render() {
		const {view, currentViewId, isAdmin, deleteView} = this.props;
		const isActive = view._id.toString() === currentViewId.toString();

		return (
			<div className={flyoutManageViewStyles.row}>
				<div className={flyoutManageViewStyles.rowInfo}>
					<div className={classNames({'list-icon-btn': true, 'active-text': isActive, 'no-rollover': true})}>
						<i title={view.isGlobal ? 'Global' : 'User'} className="material-icons">
							{view.isGlobal ? 'language' : 'person'}
						</i>
					</div>
					<div
						className={classNames({'list-icon-btn': true, 'active-text': isActive, 'link-text': true})}
						onClick={() => {
							this.props.history.push(`/eligibilityAnalysis/${view._id}`);
							this.props.closeContextSidebarPanel();
						}}
					>
						{view.name}
					</div>
					{view.isDefault && (
						<div
							className={classNames({
								'list-icon-btn': true,
								'active-text': isActive,
								'no-rollover': true,
							})}
						>
							<i title="Default" className="material-icons">
								home
							</i>
						</div>
					)}
					{view.isFavorite && (
						<div
							className={classNames({
								'list-icon-btn': true,
								'active-text': isActive,
								'no-rollover': true,
							})}
						>
							<i title="Favorite" className="material-icons">
								star
							</i>
						</div>
					)}
				</div>
				<div className={flyoutManageViewStyles.rowActions}>
					{this.renderViewLink(view, isAdmin, 'Delete', 'delete', () => {
						deleteView(view);
					})}
				</div>
			</div>
		);
	}
}

export default ViewListItem;
