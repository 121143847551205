//eslint-disable-next-line no-unused-vars
import {apiUrl, _checkAuth, _handleResponse, _getHeaders, _checkStatus} from 'api/apiUtils';

// TODO _checkAuth, _checkStatus
export function fetchEAViewById(id) {
	return fetch(`${apiUrl}/eligibilityViews/${id}`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
}

export function fetchEAViewsByDatasetId(datasetId) {
	return fetch(`${apiUrl}/eligibilityViews/dataset/${datasetId}`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
}

export function fetchEAViewsByDatasetIdUserId(datasetId, userId) {
	return fetch(`${apiUrl}/eligibilityViews/dataset/${datasetId}/user/${userId}`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
}

export function fetchEAViewsByDatasetIdGlobal(datasetId) {
	return fetch(`${apiUrl}/eligibilityViews/dataset/${datasetId}/global`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
}

export function fetchEADefaultView(datasetId) {
	return fetch(`${apiUrl}/eligibilityViews/dataset/${datasetId}/default`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
}

export function createEligibilityView(eligibilityAnalysis) {
	return fetch(`${apiUrl}/eligibilityViews/`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify(eligibilityAnalysis),
	}).then(_handleResponse);
}

export function updateEligibilityView(eligibilityAnalysis) {
	return fetch(`${apiUrl}/eligibilityViews`, {
		credentials: 'include',
		method: 'PATCH',
		headers: _getHeaders('PATCH'),
		body: JSON.stringify(eligibilityAnalysis),
	}).then(_handleResponse);
}

export function deleteEligibilityView(id) {
	return fetch(`${apiUrl}/eligibilityViews/${id}`, {
		method: 'DELETE',
		credentials: 'include',
		headers: _getHeaders(),
		body: JSON.stringify({}),
	}).then(_handleResponse);
}

export function fetchEAReportData(eligibilityAnalysis) {
	return fetch(`${apiUrl}/eligibilityViews/explore`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify(eligibilityAnalysis),
	}).then(_handleResponse);
}

export default {
	fetchEAViewById,
	fetchEAViewsByDatasetId,
	fetchEAViewsByDatasetIdUserId,
	fetchEAViewsByDatasetIdGlobal,
	fetchEADefaultView,
	createEligibilityView,
	updateEligibilityView,
	deleteEligibilityView,
	fetchEAReportData,
};
