// Globals
import React, {useState, useEffect, useContext} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import moment from 'moment';
import {AbsTable} from '@moodysanalytics/cs-structured-ux-common';
// Project imports
import KiFontIcon from 'components/KiFontIcon';
import KiConfirmModal from 'components/KiConfirmModal';

// Local imports
import FundingAnalysisContext from '../fundingAnalysisContext';
import {fetchModels, deleteModel} from 'api/fundingAnalysisApi';
import './tableStyles.scss';

/**
 * [FundingModelsList description]
 */
function FundingModelsList() {
	const fundingAnalysisContext = useContext(FundingAnalysisContext);
	const history = useHistory();
	const dataset = useSelector(state => state.datasetList.selected);
	const isAdmin = useSelector(state => state.user.groups.includes('SystemAdmins'));
	const [modelToDelete, setModelToDelete] = useState({});
	const [isDeleteConfirmActive, setIsDeleteConfirmActive] = useState(false);
	const [tableLoading, setTableLoading] = useState(false);

	const fetchModelData = async () => {
		setTableLoading(true);
		const modelData = await fetchModels(dataset.datasetId);
		fundingAnalysisContext.setDataItem('allModels', modelData);
		setTableLoading(false);
	};
	// On Mount
	useEffect(
		() => {
			fetchModelData();
		},
		[dataset]
	);

	const deleteFundingModel = async () => {
		setTableLoading(true);
		try {
			await deleteModel(modelToDelete.original._id);
		} catch (err) {
			fundingAnalysisContext.addError(`Error deleting model: ${err.message}`);
		}
		await fetchModelData();
		setTableLoading(false);
		setIsDeleteConfirmActive(false);
	};
	const confirmDeleteFundingModel = model => {
		setModelToDelete(model);
		setIsDeleteConfirmActive(true);
	};

	return (
		<React.Fragment>
			<AbsTable
				data={fundingAnalysisContext.allModels}
				isLoading={tableLoading}
				showNoDataMessage={!tableLoading && fundingAnalysisContext.allModels?.length === 0}
				columns={[
					{Header: 'Model Name', accessor: 'name', id: 1},
					{Header: 'Created By', accessor: 'createdBy', id: 2},
					{Header: 'Type', accessor: 'type', id: 3},
					{
						Header: 'Last Modified',
						accessor: 'updatedAt',
						id: 4,
						isSorted: true,
						Cell: p => moment(p.value).format('YYYY-MM-DD'),
					},
					{
						id: 'settings-button',
						// Disable eslint because of because of https://github.com/yannickcr/eslint-plugin-react/issues/2404 (check if solved)
						/* eslint-disable react/prop-types */
						Cell: function CellItem({row}) {
							return (
								<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
									<KiFontIcon
										className="list-icon-btn"
										value="settings"
										title="Settings"
										onClick={() =>
											history.push(
												`/fundingAnalysis/${dataset.datasetId}/models/${
													row.original._id
												}/detail`
											)
										}
									/>
									{isAdmin && (
										<KiFontIcon
											className="list-icon-btn"
											value="delete"
											title="Delete"
											onClick={() => confirmDeleteFundingModel(row)}
										/>
									)}
								</div>
							);
						},
						/* eslint-enable react/prop-types */
					},
				]}
			/>

			<KiConfirmModal
				header="Delete Model"
				message={`Are you sure you want to delete Model: ${modelToDelete?.original?.name}?`}
				acceptFunc={() => deleteFundingModel()}
				rejectFunc={() => setIsDeleteConfirmActive(false)}
				acceptLabel="Delete"
				rejectLabel="Cancel"
				active={isDeleteConfirmActive}
			/>
		</React.Fragment>
	);
}

export default FundingModelsList;
