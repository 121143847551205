// Globals
import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import Select from 'react-select';
import _ from 'lodash';

// Website imports
import FlyoutConfirmPanel from '../FlyoutConfirmPanel';
import {USER_GROUPS, userGroupsIntersect} from '../../utils/userGroupsUtil';
import {fetchAccounts} from '../../containers/accounts/actions';
import KiButton from 'components/KiButton';
import {closeContextSidebarPanel} from 'state/actions/App';

// Local imports
import ViewListItem from './ViewListItem';
import styles from './FlyoutManageViewList.theme.scss';

// Remove return function
const customViewSort = () => {
	return (a, b) => {
		// eslint-disable-line
		// If is default then it is greatest
		if (a.isDefault && !b.isDefault) {
			return -1; // a is first
		}
		if (!a.isDefault && b.isDefault) {
			return 1; // b is first
		}

		// Favorites are ranked over non favorites
		if (a.isFavorite && !b.isFavorite) {
			return -1; // a is first
		}

		if (!a.isFavorite && b.isFavorite) {
			return 1; // b is first
		}

		// User bookmarks before global bookmarks
		if (a.isGlobal && !b.isGlobal) {
			return -1; // a is first
		}

		if (!a.isGlobal && b.isGlobal) {
			return 1; // b is first
		}

		if (a.isFavorite && b.isFavorite) {
			// Favorites are ordered by chronological order, most recent at top
			const valueOne = new Date(a.createDate);
			const valueTwo = new Date(b.createDate);
			if (valueOne === valueTwo) {
				return 0;
			}
			return valueOne < valueTwo ? -1 : 1;
		} else {
			// Alphabetical order
			const valueOne = a.name.toLowerCase();
			const valueTwo = b.name.toLowerCase();
			if (valueOne === valueTwo) {
				return 0;
			}
			return valueOne < valueTwo ? -1 : 1;
		}
	};
};
// TODO test general errors vs prevent delete errors
export const FlyoutManageViewList = ({
	allViewsList,
	currentView, // Bookmark object, should have _id and name
	generalError, // Generalized errors
	deleteError, // Specific to errors preventing deletion
	onClickCallback, // Should accept bookmark
	onDeleteCallback, // Should re-fetch view list if deleting self (reload page)
	onCopyCallback, // Should accept bookmark
}) => {
	const dispatch = useDispatch();

	const user = useSelector(state => state.user);
	const userList = useSelector(state => state.accounts.data);

	const isAdmin = userGroupsIntersect(user.groups, [USER_GROUPS.SYSTEM_ADMINS]);

	const [selectedUserId, setSelectedUserId] = useState(isAdmin ? 'global' : user.userId);
	const [searchTerm, setSearchTerm] = useState('');
	const [filteredBookmarks, setFilteredBookmarks] = useState([]);
	const [searchedBookmarks, setSearchedBookmarks] = useState([]);
	const [viewToDelete, setViewToDelete] = useState(null);
	const [showGeneralError, setShowGeneralError] = useState(false);
	const [showDeleteError, setShowDeleteError] = useState(false);

	useEffect(() => {
		if (!userList.length && isAdmin) {
			dispatch(fetchAccounts());
		}
	}, []);

	useEffect(
		() => {
			const filterQuery = bookmark => {
				switch (selectedUserId) {
					case '':
						return !bookmark.isGlobal;
					case 'global':
						return bookmark.isGlobal;
					default:
						if (!isAdmin) {
							//show both global and owned views
							return bookmark.createdBy === selectedUserId || bookmark.isGlobal;
						} else {
							//show only selected user views
							return bookmark.createdBy === selectedUserId;
						}
				}
			};

			// Assumes a list of bookmarks that have the following triats
			// - all belonging to the correct dataset
			// - attributes isDefault, isFavorite, createdBy, isGlobal
			const filteredBookmarks = allViewsList.filter(bookmark => filterQuery(bookmark)).sort(customViewSort());
			setFilteredBookmarks(filteredBookmarks);
		},
		[allViewsList, selectedUserId]
	);

	useEffect(
		() => {
			setShowGeneralError(generalError);
		},
		[generalError]
	);

	useEffect(
		() => {
			setShowDeleteError(deleteError);
		},
		[deleteError]
	);

	const confirmDeleteBookmark = () => {
		onDeleteCallback(viewToDelete);
		setViewToDelete(null);
	};

	const cancelDeleteBookmark = () => {
		setViewToDelete(null);
	};

	const closeGeneralError = () => {
		setShowGeneralError(false);
	};

	const closeDeleteError = () => {
		setShowDeleteError(false);
	};

	const userListOptions = _.sortBy(userList, u => `${u.lastName}${u.firstName}`.toLowerCase()).map(u => {
		return {value: u.userId, label: `${u.firstName} ${u.lastName}`};
	});
	userListOptions.unshift({value: 'global', label: 'Global Only'});
	userListOptions.unshift({value: '', label: 'All Users'});

	const searchBookmarks = () => {
		const searchTermCaseless = searchTerm.toUpperCase();
		const searchRes = filteredBookmarks
			.filter(bookmark => {
				const tags = _.get(bookmark, 'tags', []);
				return (
					bookmark.name.toUpperCase().includes(searchTermCaseless.toUpperCase()) ||
					tags.some(element => element.toUpperCase() === searchTermCaseless)
				);
			})
			.sort(customViewSort());
		setSearchedBookmarks(searchRes);
	};

	// Parse the even and set the searchTerm
	const onSearch = event => {
		const searchTerm = _.get(event, 'target.value', '');
		setSearchTerm(searchTerm);
	};

	// If the search term changes re-search the list
	useEffect(
		() => {
			searchBookmarks();
		},
		[searchTerm]
	);

	// If the main list changes re-search the list
	useEffect(
		() => {
			searchBookmarks();
		},
		[filteredBookmarks]
	);

	if (viewToDelete) {
		return (
			<FlyoutConfirmPanel
				header={'Delete'}
				message={`Are you sure you want to delete the following view? ${_.get(
					viewToDelete,
					'name',
					'<unknown>'
				)}`}
				acceptFunc={confirmDeleteBookmark}
				rejectFunc={cancelDeleteBookmark}
				acceptLabel={'DELETE'}
				rejectLabel={'CANCEL'}
			/>
		);
	}

	if (showGeneralError && generalError) {
		<FlyoutConfirmPanel
			header={'Error'}
			message={<span>The following error occurred. Please contact support.</span>}
			rejectFunc={closeGeneralError}
			rejectLabel={'CLOSE'}
			hideAccept={true}
			reasonList={[]}
		/>;
	}

	if (showDeleteError && deleteError) {
		return (
			<FlyoutConfirmPanel
				header={'Delete'}
				message={<span>The view cannot be deleted because it is associated with the following:</span>}
				rejectFunc={closeDeleteError}
				rejectLabel={'CLOSE'}
				hideAccept={true}
				reasonList={deleteError.data.dependencies}
			/>
		);
	}

	const renderViewItems = () => {
		if (!searchedBookmarks.length || searchedBookmarks.length == 0) {
			return (
				<section className={styles.listContainer}>
					<p>No Views found</p>
				</section>
			);
		}
		return (
			<section className={styles.listContainer}>
				{searchedBookmarks.map(bookmark => {
					return (
						<ViewListItem
							key={_.uniqueId()}
							bookmark={bookmark}
							currentViewId={currentView._id}
							user={user}
							isAdmin={isAdmin}
							clickBookmark={bookmark => {
								onClickCallback(bookmark);
							}}
							copyBookmark={bookmark => {
								onCopyCallback(bookmark);
							}}
							deleteBookmark={bookmark => {
								setViewToDelete(bookmark);
							}}
						/>
					);
				})}
			</section>
		);
	};

	//<header className="flyout-panel-title">MANAGE VIEWS</header>
	return (
		<div className="context-sidebar-panel-flex">
			<section className={styles.flyoutPanelSearch}>
				{isAdmin && (
					<div className={styles.flyoutPanelSearchUser}>
						<p>Filter</p>
						<Select
							classNamePrefix="aut-select"
							className={styles.picker}
							closeOnSelect={true}
							hint="Select a User"
							isClearable={false}
							options={userListOptions}
							onChange={o => setSelectedUserId(o.value)}
							value={userListOptions.find(o => o.value === selectedUserId)}
						/>
					</div>
				)}
				<p>Search a View</p>
				<input placeholder="Name or Tag" value={searchTerm} onChange={onSearch} />
			</section>
			{renderViewItems()}
			<footer className={styles.editViewFooter}>
				<KiButton primary flat onClick={() => dispatch(closeContextSidebarPanel())}>
					Close
				</KiButton>
			</footer>
		</div>
	);
};

FlyoutManageViewList.propTypes = {
	allViewsList: PropTypes.array,
	currentView: PropTypes.object,
	generalError: PropTypes.object,
	deleteError: PropTypes.object,
	onClickCallback: PropTypes.func,
	onDeleteCallback: PropTypes.func,
	onCopyCallback: PropTypes.func,
};

FlyoutManageViewList.defaultProps = {};

export default FlyoutManageViewList;
