import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styles from './creditSupports.theme.scss';
import {Route, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import _ from 'lodash';
import classNames from 'classnames/bind';
import KiMenuItem from 'components/KiMenu/KiMenuItem';
import KiMenu from 'components/KiMenu';
import KiDnDList from 'components/KiDnDList';
import CreditSupportsForm, {SUPPORT_TYPE_OPTIONS} from './creditSupportsForm';
// import DebtDeleteModal from './debtDeleteModal';
import {addFundingVehicleSetting, updateFundingVehicleSetting} from '../actions';
const cx = classNames.bind(styles);
import '../index.scss';
import CreditSupportsDeleteModal from './creditSupportsDeleteModal';

export class CreditSupports extends Component {
	static propTypes = {
		creditSupports: PropTypes.array,
		history: PropTypes.object,
		match: PropTypes.object,
		dataset: PropTypes.object,
		fundingVehicle: PropTypes.object,
		waterfallCalcColumns: PropTypes.arrayOf(PropTypes.object),
		updateFundingVehicleSetting: PropTypes.func,
		addFundingVehicleSetting: PropTypes.func,
	};

	static defaultProps = {
		creditSupports: [],
		waterfallCalcColumns: [],
		match: {
			params: {},
		},
	};

	state = {
		creditSupports: [],
	};

	componentDidMount() {
		const fvPropSupports = _.cloneDeep(
			this.props.creditSupports.filter(
				cs =>
					this.props.fundingVehicle &&
					this.props.fundingVehicle._id &&
					cs.fundingVehicleId.toString() === this.props.fundingVehicle._id.toString()
			)
		);
		this.setState({
			creditSupports: _.orderBy(fvPropSupports, ['supportType', 'supportNumber'], ['asc', 'asc']),
		});
	}

	componentDidUpdate() {
		const stateCreditSupports = this.state.creditSupports;
		const fvPropSupports = _.cloneDeep(
			this.props.creditSupports.filter(
				cs =>
					this.props.fundingVehicle &&
					this.props.fundingVehicle._id &&
					cs.fundingVehicleId.toString() === this.props.fundingVehicle._id.toString()
			)
		);
		const sortedFvPropSupports = _.orderBy(fvPropSupports, ['supportType', 'supportNumber'], ['asc', 'asc']);
		sortedFvPropSupports.forEach((creditSupport, index) => {
			if (!_.isEqual(creditSupport, stateCreditSupports[index])) {
				this.setState({
					creditSupports: sortedFvPropSupports,
				});
			}
		});
	}

	renderCreditSupportItem = creditSupport => (
		<div
			key={creditSupport._id}
			className={cx('listItem', {
				[styles.listItemSelected]: this.props.match.params.creditSupportId === creditSupport._id,
			})}
			onMouseUp={() =>
				this.props.history.push(
					`/datasets/${this.props.dataset.datasetId}/fundingVehicles/${
						this.props.match.params.id
					}/capitalStructure/creditSupports/${creditSupport._id}`
				)
			}
		>
			{creditSupport.name}
		</div>
	);

	onCreditSupportsReordered = (creditSupportsDnd, sourceIndex, destIndex) => {
		const creditSupports = _.cloneDeep(creditSupportsDnd).map(cs => {
			return cs.content;
		});
		const targetClassId = creditSupports[destIndex].supportType;
		let targetClassCount = 0;

		creditSupports.forEach(cs => {
			if (cs.supportType === targetClassId) {
				targetClassCount++;
				cs.supportNumber = targetClassCount;
				cs.nameId = `S_${_.get(SUPPORT_TYPE_OPTIONS.find(x => x.value === cs.supportType), 'label', '').replace(
					/\s/gi,
					''
				)}_${targetClassCount}`;
				// TODO add ability to update many settings at once
				this.props.updateFundingVehicleSetting({_id: cs._id, ...cs});
			}
		});
		this.setState({creditSupports: _.orderBy(creditSupports, ['supportType', 'supportNumber'], ['asc', 'asc'])});
	};

	handleSaveCreditSupport(creditSupport) {
		if (creditSupport._id && creditSupport._id !== 'create') {
			return this.props.updateFundingVehicleSetting(creditSupport).then(() => {
				this.props.history.push(
					`/datasets/${this.props.dataset.datasetId}/fundingVehicles/${
						this.props.match.params.id
					}/capitalStructure/creditSupports/create`
				);
			});
		} else {
			return this.props.addFundingVehicleSetting(creditSupport).then(() => {
				this.props.history.push(
					`/datasets/${this.props.dataset.datasetId}/fundingVehicles/${
						this.props.match.params.id
					}/capitalStructure/creditSupports/create`
				);
			});
		}
	}

	isCreditSupportNumberTaken(creditSupports, proposedName) {
		return (
			creditSupports.filter(item => {
				return item.nameId === proposedName;
			}).length > 0
		);
	}

	findNonTakenName(numOfCreditSupportsForType, supportType, supportNumber) {
		numOfCreditSupportsForType = numOfCreditSupportsForType === 0 ? 1 : numOfCreditSupportsForType;
		if (
			this.isCreditSupportNumberTaken(this.state.creditSupports, `S_${supportType}_${numOfCreditSupportsForType}`)
		) {
			numOfCreditSupportsForType += 1;
			return this.findNonTakenName(numOfCreditSupportsForType, supportType, supportNumber);
		} else {
			return numOfCreditSupportsForType;
		}
	}

	getCreditSupportNumber = (_id, supportType = 'Reserve') => {
		const savedCreditSupport = this.props.creditSupports.find(cs => cs._id === _id);
		const numOfCreditSupportsForType = this.state.creditSupports.filter(
			creditSupport => creditSupport.supportType === supportType
		).length;

		if (savedCreditSupport && savedCreditSupport.supportType === supportType) {
			return savedCreditSupport.supportNumber;
		}

		return this.findNonTakenName(numOfCreditSupportsForType, supportType, numOfCreditSupportsForType);
	};

	render() {
		const {
			history,
			match = {
				params: {},
			},
		} = this.props;

		const {creditSupports} = this.state;

		return (
			<div className={styles.root}>
				<div className={styles.listActions}>
					<i
						className="material-icons"
						onMouseUp={() => {
							history.push(
								`/datasets/${this.props.dataset.datasetId}/fundingVehicles/${
									match.params.id
								}/capitalStructure/creditSupports/create`
							);
						}}
					>
						add_circle
					</i>

					<KiMenu icon="more_vert" position="topRight">
						<KiMenuItem
							icon="delete"
							disabled={!creditSupports.length}
							label="Delete Accounts..."
							onClick={() => this.setState({deleteModalActive: true})}
						/>
					</KiMenu>
				</div>
				<div className={styles.list} style={{minHeight: `${(creditSupports.length + 1) * 47}px`}}>
					{!creditSupports.length && <div className={styles.noRecordsFound}>No Accounts Found</div>}
					<KiDnDList
						items={creditSupports.map((creditSupport, index) => ({
							id: index,
							content: creditSupport,
							idx: index,
						}))}
						onReorder={this.onCreditSupportsReordered}
						contentRenderFunc={this.renderCreditSupportItem}
						listClassName={'debt-list-dnd'}
						itemClassName={'debtItemDnd'}
					/>
				</div>
				<div className={styles.detail}>
					<Route
						path={
							'/datasets/:datasetId/fundingVehicles/:id/capitalStructure/creditSupports/:creditSupportId'
						}
						render={() => {
							if (
								this.props.match.params.creditSupportId &&
								this.props.match.params.creditSupportId !== 'create' &&
								!creditSupports.find(d => d._id === this.props.match.params.creditSupportId)
							) {
								return (
									<Redirect
										to={`/datasets/${this.props.dataset.datasetId}/fundingVehicles/${
											match.params.id
										}/capitalStructure/creditSupports`}
									/>
								);
							}
							const {
								_id: supportId,
								accrualMethod,
								name,
								rateAmount,
								rateType,
								supportType,
								limit,
								insurance,
								target,
								nameId,
								floatingIndex,
								isAsset,
								margin,
							} =
								creditSupports.find(d => d._id === this.props.match.params.creditSupportId) || {};
							return (
								<CreditSupportsForm
									onSave={creditSupport => this.handleSaveCreditSupport(creditSupport)}
									fundingVehicleId={_.get(this.props.fundingVehicle, '_id')}
									datasetId={_.get(this.props.dataset, 'datasetId')}
									supportId={supportId}
									accrualMethod={accrualMethod}
									name={name}
									rateAmount={rateAmount}
									floatingIndex={floatingIndex}
									rateType={rateType}
									supportType={supportType}
									limit={limit}
									target={target}
									insurance={insurance}
									isAsset={isAsset}
									nameId={nameId}
									availableTargets={this.props.waterfallCalcColumns}
									getCreditSupportNumber={this.getCreditSupportNumber}
									isNameUnique={newName =>
										name === newName || !creditSupports.find(cs => cs.name === newName)
									}
									history={this.props.history}
									margin={margin}
								/>
							);
						}}
					/>
				</div>
				<CreditSupportsDeleteModal
					active={this.state.deleteModalActive}
					creditSupports={this.state.creditSupports}
					onClose={() => this.setState({deleteModalActive: false})}
				/>
			</div>
		);
	}
}

const mapStateToProps = () => ({
	/*
	waterfallCalcColumns: _.sortBy(
		state.datasetList.selected.columns.filter(col => col.isWaterfall && col.dataType !== 'boolean'),
		col => col.displayName.toLowerCase()
	),
	*/
});

const mapDispatchToProps = {
	updateFundingVehicleSetting,
	addFundingVehicleSetting,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CreditSupports);
