import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import './KiCardExplorer.scss';
import ReactTable from 'react-table-6';
import _get from 'lodash/get';
import KiDataCardChart from '../KiDataCardChart';
import KiCardExplorerFilters from './KiCardExplorerFilters';
import {dateToShortDate} from 'ki-common/utils/dateHelpers';
import {formatData, defaultData, getSummaryColumn, fetchData, getDisplayTypes} from './KiCardExplorerUtils';
import {getFormatStringType} from 'ki-common/utils/displayFormatter';
import {buildTitle} from 'ki-common/utils/explorerUtils';

function KiCardExplorer(props) {
	const {datasetColumns, view, card, fundingVehicles, scenarioList, fundingVehicleId, scenarioId} = props;
	let timeSeriesSummaryColumn;
	const displayTypes = getDisplayTypes();

	// set up state
	const [statementDate, setStatementDate] = useState(
		view.explorerData.isFixedDate ? view.explorerData.statementDate : dateToShortDate(new Date())
	);
	const [displayType, setDisplayType] = useState(
		displayTypes.find(type => type.value === (card.settings.chartType || 'data'))
	);
	const [fundingVehicle, setFundingVehicle] = useState(
		fundingVehicles.find(
			fv => fv._id === ((card.settings.fundingVehicle ? card.settings.fundingVehicle : fundingVehicleId) || null)
		)
	);
	const [scenarioOption, setScenarioOption] = useState(
		scenarioList.find(s => s.value === ((card.settings.scenarioId ? card.settings.scenarioId : scenarioId) || null))
	);
	const [columnType, setColumnType] = useState({
		label: 'Amounts',
		value: 'number',
	});
	const [summaryColumn, setSummaryColumn] = useState();
	const [data, setData] = useState(defaultData);
	const [dateContextMessage, setDateContextMessage] = useState('');
	const [noDataMessage, setNoDataMessage] = useState('');
	const [reactTableLoading, setReactTableLoading] = useState(false);
	const [error, setError] = useState('');
	const [pagingSortingSettings, setPagingSortingSettings] = useState({
		pageSize: 10,
		pageNumber: 1,
		sort: [
			{
				id: '',
				desc: '',
			},
		],
	});

	if (_get(props, 'view.explorerData.timeseries')) {
		timeSeriesSummaryColumn = view.explorerData.timeseries.column;
	}

	const activeScenarioId = _get(
		scenarioOption,
		'value',
		view?.explorerData.snapshotType === 'encumbrance' ? 'lastApproved' : 'assetSnapshot'
	);

	// handle display changes and refetch data
	useEffect(
		() => {
			if (!card._id) {
				setError('Card ID required');
				return;
			}
			setError('');
			setReactTableLoading(true);
			setDateContextMessage('');
			setNoDataMessage('');
			fetchData(
				datasetColumns,
				view,
				card,
				statementDate,
				fundingVehicle,
				pagingSortingSettings,
				activeScenarioId
			)
				.then(data => {
					setData(data || defaultData);
					// set new summary column
					if (data.columns.length && !card.settings.transpose) {
						setSummaryColumn(
							getSummaryColumn(
								card.settings.chartType,
								data,
								view.explorerData.tableType,
								{
									label: 'Amounts',
									value: 'number',
								},
								timeSeriesSummaryColumn,
								_get(view.explorerData, 'timeseries.column._id')
							)
						);
					}
					if (data && data.dateContextInfo.length) {
						setDateContextMessage(
							`Data results for ${data.dateContextInfo[0].calculatedDate} using ${
								data.dateContextInfo[0].name
							} date context`
						);
					}
					if (!data.columns.length) {
						setNoDataMessage(
							`No ${view.snapshotType === 'blended' ? 'blended' : ''} data for ${
								data.dateContextInfo[0].calculatedDate
							} is available`
						);
					}
					setReactTableLoading(false);
				})
				.catch(err => {
					setReactTableLoading(false);
					setError(err.message);
				});
		},
		[card, displayType, fundingVehicle, statementDate, pagingSortingSettings, scenarioOption]
	);

	if (error) {
		return <div className="dataCardError">{`Error fetching data: ${error}`}</div>;
	}

	switch (displayType.value || 'data') {
		case 'data':
			return (
				<div className="table-container predefined-card-table" style={{overflow: 'auto'}}>
					<div>
						<KiCardExplorerFilters
							data={data}
							view={view}
							card={card}
							displayType={displayType}
							fundingVehicle={fundingVehicle}
							fundingVehicles={fundingVehicles}
							statementDate={statementDate}
							setDisplayType={setDisplayType}
							setFundingVehicle={setFundingVehicle}
							setStatementDate={setStatementDate}
							summaryColumn={summaryColumn}
							setSummaryColumn={setSummaryColumn}
							columnType={columnType}
							setColumnType={setColumnType}
							scenarioList={props.scenarioList}
							setScenarioOption={setScenarioOption}
							scenarioId={activeScenarioId}
						/>
						<div className="statusBar">{dateContextMessage}</div>
						<ReactTable
							manual
							defaultSorted={[
								{
									id: _get(card, 'settings.sort.id', ''),
									desc: _get(card, 'settings.sort.desc', ''),
								},
							]}
							minRows={0}
							loading={reactTableLoading}
							data={formatData(data.rows, data.columns).transformedRows}
							columns={formatData(data.rows, data.columns).transformedColumns}
							className="-highlight data-explorer-table data-explorer-card"
							defaultPageSize={20}
							noDataText={noDataMessage}
							showPagination={!card.settings.numberOfRows}
							page={pagingSortingSettings.pageNumber - 1}
							pages={Math.ceil(data.totalNumberOfRows / pagingSortingSettings.pageSize)}
							pageSize={pagingSortingSettings.pageSize}
							onPageChange={val =>
								setPagingSortingSettings({...pagingSortingSettings, pageNumber: val + 1})
							}
							onPageSizeChange={val =>
								setPagingSortingSettings({...pagingSortingSettings, pageSize: val})
							}
							onSortedChange={val => setPagingSortingSettings({...pagingSortingSettings, sort: val})}
						/>
					</div>
				</div>
			);
		case 'pie':
		case 'column':
		case 'line':
		case '3d-column':
		case '3d-pie':
		case '3d-donut':
		case 'semi-circle-donut':
		case 'pie-gradient':
		case 'pie-monochrome':
		case 'stacked-column':
		case 'area':
		case 'line-data-labels':
			return (
				<div className="table-container predefined-card-table" style={{overflow: 'auto'}}>
					<KiCardExplorerFilters
						data={data}
						view={view}
						card={card}
						displayType={displayType}
						fundingVehicle={fundingVehicle}
						fundingVehicles={fundingVehicles}
						statementDate={statementDate}
						setDisplayType={setDisplayType}
						setFundingVehicle={setFundingVehicle}
						setStatementDate={setStatementDate}
						summaryColumn={summaryColumn}
						setSummaryColumn={setSummaryColumn}
						columnType={columnType}
						setColumnType={setColumnType}
					/>
					<KiDataCardChart
						categories={data.rows.map(data => {
							return data.meta.bucket.value || `${data.meta.bucket.min} - ${data.meta.bucket.max}`;
						})}
						series={data.columns
							.slice(1)
							.map((col, index) => {
								if (
									getFormatStringType(col.displayFormat) !== columnType.value &&
									displayType.value !== 'pie' &&
									_get(view.explorerData, 'timeseries.column._id') !== 'all'
								) {
									return null;
								}
								return {
									id: col.id,
									name: buildTitle(col.displayName, col.calculation, col.calculateColum),
									data: data.rows.map(row => row.data[index + 1]),
								};
							})
							.filter(col => {
								if (summaryColumn && view.explorerData.tableType !== 'timeSeries') {
									return (
										col !== null && (col.id === summaryColumn._id || col.id === summaryColumn.id)
									);
								}
								return col !== null;
							})}
						chartTitle={card.name}
						chartType={displayType.value}
						xTitle={''}
						yTitle={''}
						height={'500'}
						loading={reactTableLoading}
					/>
				</div>
			);
	}
}

KiCardExplorer.propTypes = {
	datasetColumns: PropTypes.array.isRequired,
	card: PropTypes.object.isRequired,
	view: PropTypes.object.isRequired,
	fundingVehicles: PropTypes.array,
	scenarioList: PropTypes.array,
	fundingVehicleId: PropTypes.string,
	scenarioId: PropTypes.string,
};

KiCardExplorer.defaultProps = {
	datasetColumns: [],
	card: {},
	fundingVehicles: [],
	scenarioList: [],
};

export default KiCardExplorer;
