// Globals
import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import _isEqual from 'lodash/isEqual';
import _get from 'lodash/get';
import _debounce from 'lodash/debounce';
import _ from 'lodash';

// Project imports
import {determineScenarioType, getDefaultColumnIds} from 'ki-common/utils/explorerUtils';
import {dateToShortDate, shortDateToDate} from 'ki-common/utils/dateHelpers';

// Website components
import KiAppBar from 'components/KiAppBar';
import KiProgressBar from 'components/KiProgressBar';
import ContextSidebar from 'components/ContextSidebar';
import FlyoutCalculations from 'components/FlyoutCalculations';
import FlyoutFilters from 'components/FlyoutFilters';
import FlyoutManageViews from 'components/FlyoutManageViews';
import ContextIcons from 'components/ContextSidebar/icons';
import KiDatePicker from 'components/KiDatePicker';
import EditView from 'components/EditView';
import KiFontIcon from 'components/KiFontIcon';
import KiConfirmModal from 'components/KiConfirmModal';
import {KiIconMaxRecords, KiIconCard, KiIconCsv} from 'components/KiIcons';
import FlyoutDates from 'components/FlyoutDates';
import FlyoutCardList from 'components/FlyoutCardList';
import KiButton from 'components/KiButton';

// Website imports
import {dataBookmarksApi, explorerApi, datasetDatesApi} from 'api';
import {fetchBreachesBookmark, fetchExcessBookmark} from 'api/dataBookmarksApi';
import {fetchBlendedDatasets} from 'api/blendedDatasetsApi';
import {openContextSidebarPanel} from 'state/actions/App';
import {openColumnModal, closeColumnModal} from 'state/actions/ColumnModal';
import {fetchDataset, requeryColumnList} from 'containers/datasetList/actions';
import {showSnackbar} from 'state/actions/Snackbar';
import {fetchViewsByDataset} from 'components/FlyoutManageViews/actions';
import {_getHeaders, apiUrl} from 'api/apiUtils';

// Local imports
import TimeSeriesColumnSelector from './components/TimeSeriesColumnSelector';
import TimeSeriesPeriodSelector from './components/TimeSeriesPeriodSelector';
import TimeSeriesDisplayPeriodSelector from './components/TimeSeriesDisplayPeriodSelector';
import Breadcrumbs from './components/ExplorerBreadcrumbs';
import ExplorerTable from './components/ExplorerTable';
import CohortSelector from 'components/CohortSelector';
import GroupBySelector from 'components/GroupBySelector';
import QuickFilter from './components/QuickFilter';
import MaxRecords from './components/MaxRecords';
import styles from './dataExplorer.theme.scss';

import {
	setSortColumn,
	setStatementDate,
	setDateContext,
	setIsFixedDate,
	setSnapshotType,
	setPageNumber,
	setPageSize,
	setTableType,
	setCohortColumn,
	setGroupBy,
	setMaxRecords,
	setBreadcrumb,
	clearBreadcrumbs,
	updateBreadcrumb,
	deleteBreadcrumb,
	setBucket,
	setGranularity,
	clearData,
	setTimeSeriesColumn,
	setTimeSeriesRange,
	setTimeSeriesPeriod,
	setQuickFilterScenario,
	setQuickFilterScenarioType,
	setQuickFilterFundingVehicle,
	setQuickFilterPool,
	setQuickFilterHypoFundingVehicle,
	setQuickFilterHypoPool,
	setColumns,
	setFilters,
	fetchExplorerData,
	drillIntoAsset,
	drillIntoBucket,
	drillIntoTimeSeries,
	saveBookmark,
	copyBookmark,
	setRequestFromBookmark,
} from './actions';

export class DataExplorer extends Component {
	static propTypes = {
		app: PropTypes.object.isRequired,
		dataset: PropTypes.object,
		dataExplorer: PropTypes.object,
		columnList: PropTypes.array,
		fetchDataset: PropTypes.func,
		requeryColumnList: PropTypes.func,
		drillIntoAsset: PropTypes.func,
		drillIntoBucket: PropTypes.func,
		drillIntoTimeSeries: PropTypes.func,
		setSortColumn: PropTypes.func,
		setStatementDate: PropTypes.func,
		setDateContext: PropTypes.func,
		setIsFixedDate: PropTypes.func,
		setSnapshotType: PropTypes.func,
		setPageNumber: PropTypes.func,
		setPageSize: PropTypes.func,
		setCohortColumn: PropTypes.func,
		setGroupBy: PropTypes.func,
		setMaxRecords: PropTypes.func,
		setBreadcrumb: PropTypes.func,
		updateBreadcrumb: PropTypes.func,
		deleteBreadcrumb: PropTypes.func,
		setGranularity: PropTypes.func,
		clearData: PropTypes.func,
		setTimeSeriesColumn: PropTypes.func,
		setTimeSeriesRange: PropTypes.func,
		setTimeSeriesPeriod: PropTypes.func,
		setQuickFilterScenario: PropTypes.func,
		setQuickFilterScenarioType: PropTypes.func,
		setQuickFilterFundingVehicle: PropTypes.func,
		setQuickFilterPool: PropTypes.func,
		setQuickFilterHypoFundingVehicle: PropTypes.func,
		setQuickFilterHypoPool: PropTypes.func,
		setColumns: PropTypes.func,
		setFilters: PropTypes.func,
		fetchExplorerData: PropTypes.func,
		history: PropTypes.object.isRequired,
		match: PropTypes.object.isRequired,
		saveBookmark: PropTypes.func,
		setRequestFromBookmark: PropTypes.func,
		userBookmarks: PropTypes.array,
		fetchViewsByDataset: PropTypes.func,
		user: PropTypes.object,
		openContextSidebarPanel: PropTypes.func,
	};

	static defaultProps = {
		blendedDatasets: [],
	};

	constructor(props) {
		super(props);
		const datasetId = this.props.match.params.datasetId;
		const queryParams = queryString.parse(location.search);
		const bookmarkId = queryParams.bookmarkId;
		fetchBlendedDatasets(datasetId).then(blendedDatasets => {
			this.setState({blendedDatasets});
		});
		this.state = {
			key: `${datasetId}-${bookmarkId}`,
			bookmarkId: bookmarkId,
			defaultBookmark: null,
			quickFilterLists: [],
			bookmark: null,
			saveDialogOpen: false,
			viewHasChanges: false,
			filtersHaveChanges: false,
			showResetViewDialog: false,
			showUnsavedOdataDialog: false,
			showUnsavedCardDialog: false,
			showMaxRecordsDialog: false,
			showBlendedFile: false,
			showCardEditor: false,
			queryParams: queryParams,
			requestedStatementDate: props.dataExplorer.statementDate || dateToShortDate(new Date()),
		};
	}

	componentDidMount = () => {
		const datasetId = this.props.match.params.datasetId;
		const queryParams = queryString.parse(location.search);
		const bookmarkId = queryParams.bookmarkId;
		this.setState({queryParams: queryParams});
		this.initialize(datasetId, bookmarkId);
		document.addEventListener('mousedown', this.handleClickOutsideSaveDialog);
		document.addEventListener('mousedown', this.handleClickOutsideMaxRecordsDialog);
	};

	componentDidUpdate = prevProps => {
		if (!_isEqual(prevProps, this.props)) {
			// check to see if the datasetId or bookmarkId have changed
			const datasetId = prevProps.match.params.datasetId;
			const queryParams = queryString.parse(location.search);
			const bookmarkId = queryParams.bookmarkId;
			const random = queryString.parse(location.search).r; // need to allow refresh on same bookmark so pass a random value if you need to
			const key = `${datasetId}-${bookmarkId}${random ? `-${random}` : ''}`;
			if (this.state.key !== key) {
				// we need to reinitialize
				this.setState(
					{
						defaultBookmark: null,
						quickFilterLists: [],
						bookmark: null,
						saveDialogOpen: false,
						key: key,
						queryParams: queryParams,
					},
					this.initialize(datasetId, bookmarkId)
				);
			} else if (this.state.bookmark && this.props.dataExplorer.data !== prevProps.dataExplorer.data) {
				this.copyExplorerToBookmark();
			}
		}
	};

	componentWillUnmount = () => {
		this.props.clearData();
		document.removeEventListener('mousedown', this.handleClickOutsideSaveDialog);
		document.removeEventListener('mousedown', this.handleClickOutsideMaxRecordsDialog);
	};

	copyExplorerToBookmark = () => {
		const bookmark = _.cloneDeep(this.state.bookmark);
		const explorerDataClone = _.cloneDeep(this.props.dataExplorer);
		delete explorerDataClone.data;
		delete explorerDataClone.isLoading;
		delete explorerDataClone.error;
		delete explorerDataClone.calculatedDate;
		delete explorerDataClone.dateContextName;
		bookmark.explorerData = explorerDataClone;
		this.setState({bookmark, filtersHaveChanges: false});
	};

	initialize = (datasetId, bookmarkId) => {
		const queryParams = queryString.parse(location.search);
		this.props.clearData(); // in cases of bookmark or dataset change need to clear it out
		document.title = `${this.props.app.kiVersion} - Data Exploration`;
		this.setState({viewHasChanges: false});
		if (datasetId) {
			// step one: load the dataset so you have the necessary columns for lists etc
			this.props.fetchViewsByDataset(
				this.props.user.userId,
				datasetId,
				this.props.user.groups.includes('SystemAdmins')
			); //needed for validations
			Promise.all([
				this.props.fetchDataset(datasetId),
				this.props.requeryColumnList(datasetId, {
					sources: {
						includeAssetColumns: true,
						includeCohortColumns: true,
						includeAggregateColumns: true,
						includeAssetCalculations: true,
						includeBusinessCalculations: true,
						includeDebtCalculations: true,
						includeFundingVehicle: true,
						includeBorrowingBase: true,
						includeWaterfallCalculations: true,
						includeDateColumns: true,
					},
					filter: {},
					filters: {},
					options: {
						embedColumns: true,
					},
				}),
			]).then(() => {
				// step two: handle any bookmarks that may be present
				datasetDatesApi.fetchPortfolioDates(datasetId).then(dateContextList => {
					this.setState({dateContextList: dateContextList}, () => {
						if (this.state.queryParams.fundingView === 'true') {
							const {queryParams} = this.state;
							if (queryParams.exclusionType === 'comp_test_name') {
								return fetchExcessBookmark(
									this.props.dataset,
									queryParams.fundingSnapshotDate,
									queryParams.dateContext,
									queryParams.dataColumnId,
									queryParams.groupBy,
									queryParams.fvId,
									queryParams.scenarioType,
									null,
									queryParams.scenarioId,
									queryParams.tableType
								).then(bookmark => {
									return this.loadBookmark(bookmark);
								});
							}
							if (queryParams.exclusionType === 'br_ex_name') {
								return fetchBreachesBookmark(
									this.props.dataset,
									queryParams.fundingSnapshotDate,
									queryParams.dateContext,
									queryParams.dataColumnId,
									queryParams.groupBy,
									queryParams.fvId,
									queryParams.scenarioType,
									null,
									queryParams.scenarioId,
									queryParams.tableType
								).then(bookmark => {
									return this.loadBookmark(bookmark);
								});
							}
						} else {
							return dataBookmarksApi
								.fetchBookmark(bookmarkId || null, this.props.dataset, this.props.user.userId)
								.then(bookmark => {
									// step three: set the state
									// on initial load set date to today
									if (
										!bookmark.explorerData.isFixedDate &&
										!queryParams.snapshotDate &&
										!bookmark.explorerData.isFundingLink
									)
										bookmark.explorerData.statementDate = dateToShortDate(new Date());
									return this.loadBookmark(bookmark);
								});
						}
					});
				});
			});
		}
	};

	loadBookmark = bookmark => {
		const queryParams = queryString.parse(location.search);
		// if we are fixed date, use it... otherwise use today initially
		if (bookmark.explorerData.isFixedDate) {
			this.props.setStatementDate(bookmark.explorerData.statementDate);
			this.setState(() => ({requestedStatementDate: bookmark.explorerData.statementDate}));
		} else if (queryParams.snapshotDate) {
			// some older part of ki wants to use a snapshot date... bump it by one
			const snapshotAsDate = shortDateToDate(queryParams.snapshotDate);
			snapshotAsDate.setDate(snapshotAsDate.getDate() + 1);
			const snapshotDatePlusOne = dateToShortDate(snapshotAsDate);
			this.props.setStatementDate(snapshotDatePlusOne);
			this.setState(() => ({requestedStatementDate: snapshotDatePlusOne}));
			bookmark.explorerData.statementDate = snapshotDatePlusOne;
		} else {
			this.props.setStatementDate(dateToShortDate(new Date()));
			this.setState(() => ({
				requestedStatementDate: bookmark.explorerData.statementDate || dateToShortDate(new Date()),
			}));
		}

		this.setState({
			bookmark: bookmark,
			filtersHaveChanges: false,
		});
		this.props.setRequestFromBookmark(bookmark);
		this.setQueryParamsFilters(queryParams);
		this.props.setDateContext(bookmark.explorerData.dateContext);
		this.props.setIsFixedDate(bookmark.explorerData.isFixedDate);
		const scenarioId =
			bookmark.explorerData.quickFilters.scenarioId || this.props.dataExplorer.quickFilters.scenarioId;
		this.props.setQuickFilterScenarioType(determineScenarioType(scenarioId));
		// step five: get the explore data
		this.props.fetchExplorerData(this.state.dateContextList).then(() => {
			// step four: load up the quick filter lists
			explorerApi
				.fetchQuickFilters(
					this.props.dataExplorer.datasetId,
					this.props.dataExplorer.quickFilters.scenarioId,
					this.props.dataExplorer.quickFilters.fundingVehicleId,
					this.props.dataExplorer.statementDate,
					this.props.dataExplorer.quickFilters.scenarioType,
					this.props.dataExplorer.dateContext,
					this.state.dateContextList,
					this.props.dataExplorer.isFixedDate,
					this.props.dataset.snapshots
				)
				.then(quickFilterLists => {
					const blendedFile = this.state.blendedDatasets.find(
						x => x.statementDate === this.props.dataExplorer.statementDate
					);
					const showBlendedFile = blendedFile && blendedFile.status === 'active';
					this.setState({
						quickFilterLists: quickFilterLists,
					});
					this.setState({
						defaultBookmark: bookmark,
					});
					this.setState({
						showBlendedFile: showBlendedFile,
					});
				});
		});
	};

	setQueryParamsFilters = queryParams => {
		// assign props, giving preference to the querystring
		const queryFundingVehicle = queryParams.fundingVehicleId;
		const queryHypoFundingVehicle = queryParams.hypoFundingVehicleId;
		const queryPool = queryParams.poolId;
		const queryHypoPool = queryParams.hypoPoolId;
		const queryScenario = queryParams.scenarioId || 'assetSnapshot';
		const snapshotType = queryParams.snapshotType;

		if (queryParams.statementDate) {
			this.props.setStatementDate(queryParams.statementDate);
		}

		if (queryFundingVehicle) {
			this.props.setQuickFilterFundingVehicle(queryFundingVehicle);
		}
		if (queryPool) {
			this.props.setQuickFilterPool(queryPool);
			this.setDefaultFundingVehicleForPool(queryPool);
		}
		if (queryHypoPool) {
			this.props.setQuickFilterHypoPool(queryHypoPool);
			this.setDefaultFundingVehicleForPool(queryHypoPool);
			this.props.setQuickFilterPool(queryHypoPool);
			this.props.setQuickFilterHypoFundingVehicle(queryHypoFundingVehicle); // explore from solver wants hypo
			this.props.setQuickFilterScenario(queryScenario);
		}
		if (queryScenario && !this.props.dataExplorer.quickFilters.scenarioId) {
			this.props.setQuickFilterScenario(queryScenario);
		}
		if (snapshotType) {
			this.props.setSnapshotType(snapshotType === 'blended');
		}
	};

	handleBreadcrumbDelete = breadcrumb => {
		this.props.deleteBreadcrumb(breadcrumb);
		this.props.fetchExplorerData(this.state.dateContextList);
		this.setState({viewHasChanges: true});
	};

	handleBreadcrumbChange = breadcrumb => {
		this.props.updateBreadcrumb(breadcrumb);
		this.setState({viewHasChanges: true, filtersHaveChanges: true});
	};

	handleGranularityUpdate = granularity => {
		this.props.setGranularity(granularity);
		this.props.fetchExplorerData(this.state.dateContextList);
		this.setState({viewHasChanges: true});
	};

	handleSetCohortColumn = columnId => {
		this.props.setCohortColumn(columnId);
		this.setState({viewHasChanges: true, filtersHaveChanges: true});
	};

	handleSetGroupByColumn = columnId => {
		const defaultColumnIds = getDefaultColumnIds(
			this.props.dataset.snapshots,
			this.props.columnList,
			this.props.dataExplorer.snapshotDate
		);
		if (!columnId || defaultColumnIds.cohortColumnId === columnId) {
			// you are changing to asset id, which is not grouped
			this.props.setGroupBy('');
			this.handleSetCohortColumn(defaultColumnIds.balanceCohortColumnId);
		} else {
			this.props.setGroupBy(columnId);
			this.handleSetCohortColumn(defaultColumnIds.balanceAggregateColumnId);
		}
		this.setState({viewHasChanges: true, filtersHaveChanges: true});
	};

	handleStatementDateChange = selected => {
		const statementDate = dateToShortDate(selected);
		this.props.setStatementDate(statementDate);
		explorerApi
			.fetchQuickFilters(
				this.props.dataExplorer.datasetId,
				null,
				null,
				statementDate,
				this.props.dataExplorer.quickFilters.scenarioType,
				this.props.dataExplorer.dateContext,
				this.state.dateContextList,
				this.props.dataExplorer.isFixedDate
			)
			.then(quickFilterLists => {
				this.setState({quickFilterLists: quickFilterLists});
				this.setState({viewHasChanges: true, filtersHaveChanges: true});
			});
	};

	debouncedHandleStatementDateChange = _debounce(this.handleStatementDateChange, 1000);

	handleSetPageNumber = page => {
		this.props.setPageNumber(page);
		this.props.fetchExplorerData(this.state.dateContextList);
		this.setState({viewHasChanges: true});
	};

	handleSetPageSize = size => {
		this.props.setPageSize(size);
		this.props.fetchExplorerData(this.state.dateContextList);
		this.setState({viewHasChanges: true});
	};

	handleSetSortColumn = (columnName, calculation, order) => {
		this.props.setSortColumn(columnName, calculation, order);
		this.props.fetchExplorerData(this.state.dateContextList);
		this.setState({viewHasChanges: true});
	};

	handleSetTimeSeriesColumn = columnName => {
		this.props.setTimeSeriesColumn({id: columnName || 'all', allColumns: this.props.columnList});
		this.setState({viewHasChanges: true, filtersHaveChanges: true});
	};

	handleSetTimeSeriesRange = range => {
		this.props.setTimeSeriesRange(range);
		this.setState({viewHasChanges: true, filtersHaveChanges: true});
	};

	handleSetTimeSeriesPeriod = period => {
		this.props.setTimeSeriesPeriod(period);
		this.setState({viewHasChanges: true, filtersHaveChanges: true});
	};

	handleSetQuickFilterScenario = value => {
		const scenarioType = determineScenarioType(value);
		this.props.setQuickFilterScenario(value);
		this.props.setQuickFilterScenarioType(scenarioType);
		this.props.setQuickFilterFundingVehicle('');
		this.props.setQuickFilterPool('');
		this.props.setQuickFilterHypoFundingVehicle('');
		this.props.setQuickFilterHypoPool('');
		explorerApi
			.fetchQuickFilters(
				this.props.dataExplorer.datasetId,
				value,
				null,
				this.props.dataExplorer.statementDate,
				scenarioType,
				this.props.dataExplorer.dateContext,
				this.state.dateContextList,
				this.props.dataExplorer.isFixedDate
			)
			.then(quickFilterLists => {
				this.setState({quickFilterLists: quickFilterLists});
				this.setState({viewHasChanges: true, filtersHaveChanges: true});
			});
	};

	handleSetQuickFilterFundingVehicle = value => {
		this.props.setQuickFilterFundingVehicle(value);
		this.props.setQuickFilterPool('');
		explorerApi
			.fetchQuickFilters(
				this.props.dataExplorer.datasetId,
				this.props.dataExplorer.quickFilters.scenarioId,
				value,
				this.props.dataExplorer.statementDate,
				this.props.dataExplorer.quickFilters.scenarioType,
				this.props.dataExplorer.dateContext,
				this.state.dateContextList,
				this.props.dataExplorer.isFixedDate
			)
			.then(quickFilterLists => {
				this.setState({quickFilterLists: quickFilterLists});
				this.setState({viewHasChanges: true, filtersHaveChanges: true});
			});
	};

	_getPoolByValue = value =>
		_get(this.state, 'quickFilterLists.poolList', []).find(pool => pool.value === value) || {};

	setDefaultFundingVehicleForPool = poolId => {
		if (!this.props.dataExplorer.quickFilters.fundingVehicleId) {
			const {fundingVehicleId} = this._getPoolByValue(poolId);
			this.props.setQuickFilterFundingVehicle(fundingVehicleId);
		}
	};

	_isHypoPool = pool => !!(pool.isHypo || pool.label.match(/\((hypo|committed|closed)\)/i)); // left option to check label for any change that the poolList may not have the isHypo field.

	handleSetQuickFilterPool = value => {
		this.props.setQuickFilterPool(value);
		const pool = this._getPoolByValue(value);
		const isHypoPool = this._isHypoPool(pool);
		this.props.setQuickFilterHypoPool((isHypoPool && value) || '');
		this.setState({viewHasChanges: true, filtersHaveChanges: true});
		this.setDefaultFundingVehicleForPool(value);
		if (pool.fundingVehicleId) {
			this.setState(state => ({
				quickFilterLists: {
					...state.quickFilterLists,
					poolList: state.quickFilterLists.poolList.filter(
						item => !item.fundingVehicleId || item.fundingVehicleId === pool.fundingVehicleId
					),
				},
			}));
		}
	};

	handleApplyFilters = () => {
		this.props.fetchExplorerData(this.state.dateContextList);
		this.setState(() => ({requestedStatementDate: this.props.dataExplorer.statementDate}));
	};

	saveBookmark = bookmark => {
		this.toggleSaveDialog();
		this.props.saveBookmark(bookmark).then(result => {
			this.setState({bookmark: result});
			this.setState({viewHasChanges: false});
			this.props.fetchViewsByDataset(
				this.props.user.userId,
				bookmark.datasetId,
				this.props.user.groups.includes('SystemAdmins')
			);
		});
	};

	setViewHasChanges = val => {
		this.setState({viewHasChanges: val});
	};

	copyBookmark = bookmark => {
		const myCopy = Object.assign({}, bookmark);
		delete myCopy._id;
		this.saveBookmark(myCopy);
	};

	buildBookmarkName = bookmark => {
		const iconTitle = bookmark.createdBy ? 'person' : 'language';
		return (
			<h1>
				&gt;{' '}
				<KiFontIcon className="pager-icon">
					<i className="material-icons no-rollover">{iconTitle}</i>
				</KiFontIcon>{' '}
				{this.state.bookmark.name}{' '}
				{this.state.viewHasChanges ? <span className={styles.saveWarning}>*</span> : ''}
			</h1>
		);
	};

	applySettings = (columns, filters) => {
		const assetColumns = columns.filter(c => c.columnType === 'asset');
		const summaryColumns = columns.filter(c => c.columnType === 'aggregate');
		this.props.setColumns(assetColumns, 'asset');
		this.props.setColumns(summaryColumns, 'aggregate');
		this.props.setFilters(filters);
		this.props.fetchExplorerData(this.state.dateContextList);
		this.setState({viewHasChanges: true});
	};

	toggleSaveDialog = () => {
		this.setState({saveDialogOpen: !this.state.saveDialogOpen});
	};

	toggleMaxRecordsDialog = () => {
		this.setState({showMaxRecordsDialog: !this.state.showMaxRecordsDialog});
	};

	handleSetMaxRecords = value => {
		this.props.setMaxRecords(value);
		this.toggleMaxRecordsDialog();
		this.setState({viewHasChanges: true});
		this.props.fetchExplorerData(this.state.dateContextList);
	};

	toggleShowUnsavedOdataDialog = () => {
		this.setState({
			showUnsavedOdataDialog: !this.state.showUnsavedOdataDialog,
		});
	};

	reloadView = viewId => {
		dataBookmarksApi.fetchBookmark(viewId || null, this.props.dataset, this.props.user.userId).then(bookmark => {
			this.loadBookmark(bookmark);
		});
	};

	reloadCurrentView = () => {
		this.props.fetchExplorerData(this.state.dateContextList);
	};

	handleClickOutsideSaveDialog = event => {
		if (this.state.saveDialogOpen) {
			const classList = event.target.classList || [];
			if (
				this.saveDialogRef &&
				!this.saveDialogRef.contains(event.target) &&
				!classList.toString().includes('saveDialog') &&
				!classList.contains('Select-option')
			) {
				this.setState({saveDialogOpen: false});
			}
		}
	};

	handleClickOutsideMaxRecordsDialog = event => {
		if (this.state.showMaxRecordsDialog) {
			if (this.maxRecordsDialogRef && !this.maxRecordsDialogRef.contains(event.target)) {
				this.toggleMaxRecordsDialog();
			}
		}
	};

	setSaveDialogRef = node => {
		this.saveDialogRef = node;
	};

	setMaxRecordsDialogRef = node => {
		this.maxRecordsDialogRef = node;
	};

	getCalculatedDateMessage = () => {
		if (
			this.props.dataExplorer.calculatedDate &&
			this.props.dataExplorer.calculatedDate <= this.state.requestedStatementDate
		) {
			return `Data results for ${this.props.dataExplorer.calculatedDate} using ${
				this.props.dataExplorer.dateContextName
			} date context`;
		} else if (this.props.dataExplorer.calculatedDate) {
			return `There is no ${this.props.dataExplorer.snapshotType === 'blended' ? 'blended' : ''} data found for ${
				this.state.requestedStatementDate
			} using the ${this.props.dataExplorer.dateContextName} data context`;
		}
		return '';
	};

	onExportCurrentView = () => {
		const tempBookmark = {
			name: this.state.bookmark.name,
			datasetId: this.state.bookmark.datasetId,
			tags: [],
			isDefault: false,
			isFavorite: false,
			isStratification: this.state.bookmark.dataTransferViewId === 'encumbranceStratification',
			isGlobal: false,
			isReportOnly: true,
			explorerData: Object.assign({}, this.state.bookmark.explorerData),
		};

		const webFilename = encodeURIComponent(tempBookmark.name);
		const statementDate = _get(tempBookmark, 'explorerData.statementDate', '');
		return fetch(`${apiUrl}/bookmarks/exportCSV?filename=${webFilename}&statementDate=${statementDate}`, {
			credentials: 'include',
			method: 'POST',
			headers: _getHeaders('POST'),
			body: JSON.stringify(tempBookmark),
		})
			.then(res => {
				return res.blob();
			})
			.then(data => {
				const link = document.createElement('a');
				link.href = window.URL.createObjectURL(data);
				link.download = `${tempBookmark.name}_${new Date().getTime()}.csv`;
				link.click();
			});
	};

	render() {
		// eslint-disable-line
		if (
			this.props.columnList.length === 0 ||
			this.props.dataExplorer.columns.length === 0 ||
			!this.state.bookmark
		) {
			return (
				<div className="container-wrapper">
					<div className="data-explorer-container container-body">
						<header>
							<KiAppBar className="top-bar">
								<div className="top-bar-breadcrumb">
									<h1 className="link" onClick={() => this.props.history.push('/datasets')}>
										Datasets{' '}
									</h1>
									<h1>{`> ${this.props.dataset && this.props.dataset.name}`} </h1>
								</div>
							</KiAppBar>
						</header>
						<div className="ki-panel">
							<KiProgressBar />
						</div>
					</div>
				</div>
			);
		}
		//const datasetHasBlendedData = () => {
		//if (this.props.dataExplorer.tableType === 'timeSeries') return false;
		// TODO STATEMENT DATE in future sprint we need to know if certain dates are blended so leaving this commented code in place
		// if (this.props.dataExplorer.tableType === 'timeSeries') return false;
		// const currentSnapshotDate = _get(this.props, 'dataExplorer.snapshotDate');
		// if (currentSnapshotDate) {
		// 	return (
		// 		!!currentSnapshotDate &&
		// 		!!this.props.blendedDatasets.find(
		// 			x => x.snapshotDate === currentSnapshotDate && x.status === 'active'
		// 		)
		// 	);
		// }
		// return false;
		//};
		const isAdmin = this.props.user.groups.findIndex(g => g === 'SystemAdmins') >= 0;

		return (
			<div className="container-wrapper">
				<div className="data-explorer-container container-body">
					<header>
						<KiAppBar className="top-bar">
							<div className="top-bar-breadcrumb">
								<h1 className="link" onClick={() => this.props.history.push('/datasets')}>
									Datasets{' '}
								</h1>
								<h1>{`> ${this.props.dataset && this.props.dataset.name}`} </h1>
								{this.buildBookmarkName(this.state.bookmark)}
							</div>
							<div className={`${styles.maxRecords}`} ref={this.setMaxRecordsDialogRef}>
								<div
									title={
										this.props.dataExplorer.maxRecords
											? `Top ${this.props.dataExplorer.maxRecords} rows shown`
											: `Set number of rows`
									}
									className={`${styles.maxRecordsIcon} ${
										this.props.dataExplorer.maxRecords ? styles.activeIcon : ''
									}`}
									onClick={this.toggleMaxRecordsDialog}
								>
									<KiIconMaxRecords />
								</div>
								<MaxRecords
									maxRecords={this.props.dataExplorer.maxRecords}
									save={this.handleSetMaxRecords}
									cancel={this.toggleMaxRecordsDialog}
									visible={this.state.showMaxRecordsDialog}
								/>
							</div>
							<div
								className={styles.filterIndicator}
								onClick={() => this.props.openContextSidebarPanel('Edit View', {tabIndex: 4})}
								alt={`${this.props.dataExplorer.filters.length} Filters Applied`}
								title={`${this.props.dataExplorer.filters.length} Filters Applied`}
							>
								<div className={styles.svgWrapper}>
									<ContextIcons.FiltersIcon />
								</div>
								<div className={styles.filterCount}>{this.props.dataExplorer.filters.length}</div>
							</div>
							<div className={`${styles.saveIcon}`}>
								<div
									className={`material-icons ${styles.downloadCsvIcon}`}
									alt="Download CSV"
									title="Download CSV"
									onClick={() => this.onExportCurrentView()}
								>
									<KiIconCsv />
								</div>
								<i
									className={`material-icons ${styles.explorerIcon}`}
									title="Click to save the view."
									onClick={this.toggleSaveDialog}
								>
									save
								</i>
								<div
									className={`${styles.saveDialog} ${
										this.state.saveDialogOpen ? styles.visible : styles.hidden
									}`}
									ref={this.setSaveDialogRef}
								>
									<EditView
										view={this.state.bookmark}
										save={this.saveBookmark}
										copy={this.copyBookmark}
										cancel={this.toggleSaveDialog}
										intitialName={
											!this.state.bookmark.createdBy && !isAdmin
												? `${this.state.bookmark.name} copy`
												: `${this.state.bookmark.name}`
										}
										userBookmarks={this.props.userBookmarks}
										userId={this.props.user.userId}
										isCopy={!this.state.bookmark.createdBy && !isAdmin}
										isAdmin={isAdmin}
									/>
								</div>
							</div>
						</KiAppBar>
					</header>
					<div
						className="ki-panel"
						style={{
							overflow: 'hidden',
							display: 'flex',
							flexFlow: 'column',
							flex: 1,
							padding: 0,
							marginBottom: 0,
						}}
					>
						<div className="quick-filter-container">
							<section className="quick-filter-row first-row">
								<div className="filters">
									<div className="cohort">
										<span className="theme-label">Cohort</span>
										<CohortSelector
											selectedColumn={
												_get(this.props, 'dataExplorer.timeseries.column._id') === 'all'
													? this.props.columnList.find(
															col =>
																_get(col, 'columnName') === 'kiSnapshotDate' &&
																_get(col, 'columnType') === 'cohort'
													  )._id
													: this.props.dataExplorer.columns[0]._id
											}
											columns={this.props.columnList}
											targetFunction={this.handleSetCohortColumn}
											isGroupBy={!!this.props.dataExplorer.groupBy}
											disabled={_get(this.props, 'dataExplorer.timeseries.column._id') === 'all'}
										/>
									</div>
									<div className="cohort">
										<span className="theme-label">Group By</span>
										<GroupBySelector
											selectedColumn={this.props.dataExplorer.groupBy}
											columns={this.props.columnList}
											targetFunction={this.handleSetGroupByColumn}
											assetCohortColumnId={
												getDefaultColumnIds(
													this.props.dataset.snapshots,
													this.props.columnList,
													this.props.dataExplorer.snapshotDate
												).assetCohortColumnId
											}
										/>
									</div>
									<div className="cohort">
										<span className="theme-label">Statement Date</span>
										<KiDatePicker
											onChange={this.debouncedHandleStatementDateChange}
											value={this.props.dataExplorer.statementDate}
											className={styles.explorerDatePicker}
										/>
									</div>
									<div className="cohort">
										<span className="theme-label">Scenario</span>
										<QuickFilter
											selectedValue={this.props.dataExplorer.quickFilters.scenarioId}
											targetFunction={this.handleSetQuickFilterScenario}
											options={this.state.quickFilterLists.scenarioList}
										/>
									</div>

									<QuickFilter
										title="Funding Vehicle"
										className="quick-filter-title"
										selectedValue={this.props.dataExplorer.quickFilters.fundingVehicleId}
										targetFunction={this.handleSetQuickFilterFundingVehicle}
										options={this.state.quickFilterLists.fundingVehicleList}
									/>
									<QuickFilter
										title="Pool"
										selectedValue={this.props.dataExplorer.quickFilters.poolId}
										targetFunction={this.handleSetQuickFilterPool}
										options={this.state.quickFilterLists.poolList}
									/>
									<div className="quick-filter-action">
										<KiButton
											label="Apply"
											primary
											disabled={
												!this.state.filtersHaveChanges || this.props.dataExplorer.isLoading
											}
											onClick={this.handleApplyFilters}
										/>
									</div>
								</div>
							</section>
							<section className="quick-filter-row">
								<Breadcrumbs
									data={this.props.dataExplorer.breadcrumbs}
									onDelete={this.handleBreadcrumbDelete}
									onChange={this.handleBreadcrumbChange}
								/>
								{this.props.dataExplorer.tableType === 'timeSeries' && [
									<TimeSeriesColumnSelector
										key={1}
										dataset={this.props.dataset}
										explorerRequest={this.props.dataExplorer}
										targetFunction={this.handleSetTimeSeriesColumn}
									/>,
									<TimeSeriesDisplayPeriodSelector
										key={2}
										dataset={this.props.dataset}
										explorerRequest={this.props.dataExplorer}
										targetFunction={this.handleSetTimeSeriesRange}
									/>,
									<TimeSeriesPeriodSelector
										key={3}
										dataset={this.props.dataset}
										explorerRequest={this.props.dataExplorer}
										targetFunction={this.handleSetTimeSeriesPeriod}
										explorerData={this.props.dataExplorer.data}
									/>,
								]}
							</section>
						</div>
						{!this.props.dataExplorer.isLoading && (
							<div className={styles.statusBar}>{this.getCalculatedDateMessage()}</div>
						)}
						<ExplorerTable
							data={this.props.dataExplorer.data || {}}
							dataset={this.props.dataset}
							columnList={this.props.columnList}
							dataError={this.props.dataExplorer.error}
							drillIntoAsset={this.props.drillIntoAsset}
							drillIntoBucket={this.props.drillIntoBucket}
							drillIntoTimeSeries={this.props.drillIntoTimeSeries}
							fetchExplorerData={this.props.fetchExplorerData}
							setBreadcrumb={this.props.setBreadcrumb}
							// updateCohortBucket={this.props.updateCohortBucket}
							setSortColumn={this.handleSetSortColumn}
							sortColumn={this.props.dataExplorer.sortColumn}
							setGranularity={this.props.setGranularity}
							handleGranularityUpdate={this.handleGranularityUpdate}
							setTimeSeriesColumn={this.props.setTimeSeriesColumn}
							setTimeSeriesRange={this.props.setTimeSeriesRange}
							setTimeSeriesPeriod={this.props.setTimeSeriesPeriod}
							timeSeriesGranularity={this.props.dataset.timeSeriesGranularity}
							datasetId={this.props.dataset.datasetId}
							bookmarkId={this.state.bookmark._id}
							assetCountColumn={this.props.columnList.find(c => c.columnName === 'kiAssetCount')}
							isLoading={this.props.dataExplorer.isLoading}
							totalRowCount={
								(this.props.dataExplorer.data && this.props.dataExplorer.data.totalNumberOfRows) || 0
							}
							activePage={(this.props.dataExplorer.data && this.props.dataExplorer.data.pageNumber) || 0}
							pageSize={this.props.dataExplorer.data && this.props.dataExplorer.data.pageSize}
							onPageNumberChange={this.handleSetPageNumber}
							onPageSizeChange={this.handleSetPageSize}
							bands={this.props.dataExplorer.bands}
							setViewHasChanges={this.setViewHasChanges}
							maxRecords={this.props.dataExplorer.maxRecords}
							groupBy={this.props.dataExplorer.groupBy}
							statementDate={this.props.dataExplorer.statementDate}
							startInclusive={this.props.dataExplorer.startInclusive}
							timeSeriesColumn={_get(this.props, 'dataExplorer.timeseries.column')}
							snapshotType={this.props.dataExplorer.snapshotType}
							calculatedDate={this.props.dataExplorer.calculatedDate}
							dateContextName={this.props.dataExplorer.dateContextName}
							requestedStatementDate={this.state.requestedStatementDate}
						/>
					</div>
				</div>
				<KiConfirmModal
					header="Unsaved Changes"
					message={<span>Odata is not current due to unsaved changes. Please save your changes first.</span>}
					acceptFunc={this.toggleShowUnsavedOdataDialog}
					acceptLabel="Ok"
					rejectFunc={this.toggleShowUnsavedOdataDialog}
					active={this.state.showUnsavedOdataDialog}
				/>
				<KiConfirmModal
					header="Unsaved View Changes"
					message={
						<span
						>{`This view's Card is not current due to unsaved changes. Please save your view first.`}</span>
					}
					acceptFunc={() => this.setState({showUnsavedCardDialog: false})}
					acceptLabel="Ok"
					active={this.state.showUnsavedCardDialog}
				/>
				<ContextSidebar
					items={[
						{
							name: 'Edit View',
							icon: <ContextIcons.MaterialIcon name="settings" />,
							element: (
								<FlyoutManageViews
									reloadView={this.reloadView}
									currentViewId={this.state.bookmark._id}
									user={this.props.user}
									applyView={this.loadBookmark}
									setViewHasChanges={this.setViewHasChanges}
									currentView={this.state.bookmark}
									explorerView={this.props.dataExplorer}
								/>
							),
						},
						{
							name: 'Views',
							icon: <ContextIcons.ViewsIcon />,
							element: (
								<FlyoutManageViews
									reloadView={this.reloadView}
									currentViewId={this.state.bookmark._id}
									user={this.props.user}
									applyView={this.loadBookmark}
									setViewHasChanges={this.setViewHasChanges}
									explorerView={this.props.dataExplorer}
								/>
							),
						},
						{
							name: 'Filters',
							icon: <ContextIcons.FiltersIcon />,
							element: (
								<FlyoutFilters
									datasetId={this.props.dataset.datasetId}
									user={this.props.user}
									bookmarkId={this.state.bookmarkId}
								/>
							),
						},
						{
							name: 'Calculations',
							icon: <ContextIcons.CalculationsIcon />,
							element: (
								<FlyoutCalculations
									user={this.props.user}
									reloadView={this.reloadCurrentView}
									currentView={this.state.bookmark}
								/>
							),
						},
						{
							name: 'Manage Dates',
							icon: <ContextIcons.MaterialIcon name="date_range" />,
							element: <FlyoutDates />,
						},
						{
							name: 'Manage Cards',
							icon: <KiIconCard />,
							element: (
								<FlyoutCardList
									datasetId={this.props.dataset.datasetId}
									bookmark={this.state.bookmark}
									explorerViewType="data"
								/>
							),
						},
					]}
				/>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		app: state.app,
		dataset: state.datasetList.selected,
		columnList: state.datasetList.columnList,
		dataExplorer: state.dataExplorer,
		dateContextList: state.dateContextList,
		active: state.columnModal.active,
		userBookmarks: state.dataBookmarks.userBookmarks,
		user: state.user,
	};
};

const mapDispatchToProps = () => ({
	openColumnModal,
	closeColumnModal,
	fetchDataset,
	requeryColumnList,
	setSortColumn,
	setStatementDate,
	setDateContext,
	setIsFixedDate,
	setSnapshotType,
	setPageNumber,
	setPageSize,
	setTableType,
	setCohortColumn,
	setGroupBy,
	setMaxRecords,
	setBreadcrumb,
	clearBreadcrumbs,
	updateBreadcrumb,
	deleteBreadcrumb,
	setBucket,
	setGranularity,
	clearData,
	setTimeSeriesColumn,
	setTimeSeriesRange,
	setTimeSeriesPeriod,
	setQuickFilterScenario,
	setQuickFilterScenarioType,
	setQuickFilterFundingVehicle,
	setQuickFilterPool,
	setQuickFilterHypoFundingVehicle,
	setQuickFilterHypoPool,
	setColumns,
	setFilters,
	fetchExplorerData,
	drillIntoAsset,
	drillIntoBucket,
	drillIntoTimeSeries,
	saveBookmark,
	copyBookmark,
	setRequestFromBookmark,
	showSnackbar,
	fetchViewsByDataset,
	openContextSidebarPanel,
});

export default connect(
	mapStateToProps,
	mapDispatchToProps()
)(DataExplorer);
