import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './CsvLink.theme.scss';
import {apiUrl} from 'api/apiUtils';
import {KiIconCsv} from 'components/KiIcons';

export class CsvLink extends Component {
	static propTypes = {
		viewId: PropTypes.string,
		filename: PropTypes.string,
		viewType: PropTypes.string,
		dataPairs: PropTypes.array,
		statementDate: PropTypes.string,
		scenarioType: PropTypes.string,
		snapshotType: PropTypes.string,
	};

	static defaultProps = {
		filename: 'KiView',
		viewType: 'bookmarks',
		dataPairs: [],
	};

	generateReportLink = () => {
		const {viewId, filename, viewType, dataPairs, statementDate, scenarioType, snapshotType} = this.props;

		const url = `${apiUrl}/${viewType}/exportCSV/${viewId}`;
		let queryString = `${url}?filename=${encodeURIComponent(filename)}&statementDate=${statementDate}`;
		dataPairs.forEach(param => {
			if (param.key && param.value) {
				queryString = `${queryString}&${encodeURIComponent(param.key)}=${encodeURIComponent(param.value)}`;
			}
		});
		if (scenarioType) {
			queryString += `&scenarioType=${scenarioType}`;
		}
		if (snapshotType) {
			queryString += `&snapshotType=${snapshotType}`;
		}
		return queryString;
	};

	render() {
		return (
			<a className={styles.iconLink} href={this.generateReportLink()} title={'Download CSV'}>
				<KiIconCsv />
			</a>
		);
	}
}

export default connect(null, {})(CsvLink);
