// Globals
import PropTypes from 'prop-types';
import React from 'react';
import {format} from 'ki-common/utils/displayFormatter';
import _get from 'lodash/get';

export const ExplorerTableCell = props => {
	const {data, onClick, displayFormat} = props;

	const clickTarget = onClick ? onClick : () => null;
	const style = props.style || {};
	if (onClick) {
		style.textDecoration = 'underline';
		style.color = 'blue';
		style.cursor = 'pointer';
	}
	return (
		<div className="table-cell" style={style} onClick={clickTarget}>
			{format(data, displayFormat)}
		</div>
	);
};

export const ExplorerTableCellRT = props => {
	const {
		cell,
		tableType,
		assetDrillIndex,
		handleDrillableCellClick,
		handleBucketClick,
		isLastRow,
		isSingleRowTable,
		idx,
		groupBy,
	} = props;

	// const columnMetaData = _get(columns, `[${idx}]`, {});
	const columnMetaData = cell.column;
	const meta = cell.original.meta;
	let displayFormat = _get(columnMetaData, 'displayFormat', '');
	const bucketMin = _get(meta, 'bucket.min');
	const bucketMax = _get(meta, 'bucket.max');
	const cellIndex = _get(cell, 'index');
	let onClick = null;

	if (tableType !== 'asset' && !groupBy) {
		// Asset drilldown
		if (idx === assetDrillIndex) {
			onClick = () => {
				handleDrillableCellClick(cell.value, meta, cellIndex);
			};
		}
		// Checking for first column (cohort) and that row count is positive
		if (idx === 0 && (columnMetaData.dataType === 'numeric' || columnMetaData.dataType === 'date')) {
			// Cohort bucket select
			if (
				(!bucketMin && !bucketMax) ||
				tableType === 'timeSeries' ||
				columnMetaData.bands.type === 'custom-odd'
			) {
				onClick = null;
			} else {
				onClick = () => {
					handleBucketClick(cell.value, meta, cellIndex, idx);
				};
			}
		}
	}

	let cellContent = cell.value;
	if (cellContent && columnMetaData.columnType === 'cohort') {
		// Force string on non date cohorts and dates that are non daily/monthly (in other words where the BE has already formatted)
		if (!columnMetaData.dataType === 'date' || !['daily', 'monthly'].includes(columnMetaData.granularity)) {
			displayFormat = 'string';
		}

		// Format cell data
		if (meta.bucket.value) {
			cellContent = `${format(meta.bucket.value, displayFormat)}`;
		}

		// Format last bucketed row
		if (isLastRow && !isSingleRowTable && !columnMetaData.dataType === 'string') {
			cellContent = `${format(bucketMin, displayFormat)} + and above`;
		}

		if (!isLastRow && isSingleRowTable) {
			cellContent = `${format(bucketMin, displayFormat)} ${String.fromCharCode(0x2014)} ${format(
				bucketMax,
				displayFormat
			)}`;
		}

		// Other buckets
		if (bucketMin === bucketMax && (bucketMin && bucketMax) && !isLastRow) {
			// BE fills in missing odd bucket by sending equal values for min/max
			cellContent = `${format(bucketMax, displayFormat)} and below`;
		}
	}

	return <ExplorerTableCell data={cellContent} onClick={onClick} displayFormat={displayFormat} />;
};
ExplorerTableCellRT.propTypes = {
	cell: PropTypes.object.isRequired,
	tableType: PropTypes.string.isRequired,
	handleDrillableCellClick: PropTypes.func.isRequired,
	handleBucketClick: PropTypes.func.isRequired,
	idx: PropTypes.number.isRequired,
	isLastRow: PropTypes.bool,
	isSingleRowTable: PropTypes.bool,
	assetDrillIndex: PropTypes.number,
	groupBy: PropTypes.any,
};

ExplorerTableCell.propTypes = {
	data: PropTypes.any,
	onClick: PropTypes.any,
	displayFormat: PropTypes.string,
	style: PropTypes.object,
};

ExplorerTableCellRT.displayName = 'ExplorerTableCellRT';

export default ExplorerTableCell;
