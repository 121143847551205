// Globals
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table-6';
import classNames from 'classnames/bind';
import _ from 'lodash';

// Project imports
import KiInput from 'components/KiInput';
import KiModal2 from 'components/KiModal2';
import columnUtils from 'ki-common/utils/columnUtils';

// Local imports
import styles from './KiColumnSelectModal.theme.scss';

const cx = classNames.bind(styles);

/*
modalColumns
{
	showEntityType: true/false,
	showEntityName: true/false
}
 */

function KiColumnSelectModal({
	isMultiselect,
	label,
	columnList,
	value, // Single ID or array of ID's
	onSelect,
	modalColumns,
}) {
	const [isOpen, setOpen] = useState(false);
	const [data, setData] = useState([]);

	useEffect(
		() => {
			// We have to do the transformations ahead of render time because otherwise the date shown
			// on the table does not match the data used by the filter
			const dataList = columnList
				.map(row => {
					return Object.assign({}, row, {
						rt_calculation: row.calculation
							? columnUtils.generateDisplayCalc(row.calculation, row.aggregateLevel)
							: '',
						rt_columnType: columnUtils.getColumnTypeDisplayName(row.columnType, row.isWaterfall),
						rt_dataType: columnUtils.getDataTypeDisplayName(row.dataType),
						rt_entityType: columnUtils.getEntityDisplayName(row.entityType),
					});
				})
				.sort((a, b) => {
					// Sorts the columns by selected values first for the initial render
					const aSelected = Array.isArray(value) ? value.includes(a._id) : value === a._id;
					const bSelected = Array.isArray(value) ? value.includes(b._id) : value === b._id;
					if (aSelected && !bSelected) {
						return -1;
					}

					if (!aSelected && bSelected) {
						return 1;
					}

					// If the selection state of both is the same use the name

					const aName = _.toLower(a.displayName);
					const bName = _.toLower(b.displayName);
					if (aName > bName) {
						return 1;
					}
					if (aName < bName) {
						return -1;
					}
					return 0;
				});
			setData(dataList);
		},
		[columnList]
	);

	const getColsFromIds = ids => {
		if (!ids) return null;
		if (isMultiselect) {
			return ids.map(id => columnList.find(col => `${col._id}` === id));
		}
		return columnList.find(col => `${col._id}` === ids);
	};

	// Column _id OR Array of _ids
	const [selected, setSelected] = useState(value);
	useEffect(
		() => {
			if (value) {
				setSelected(value);
			} else {
				setSelected(isMultiselect ? [] : '');
			}
		},
		[value]
	);

	const handleRowClick = id => {
		if (isMultiselect) {
			const newSelected = selected.includes(id) ? _.filter(selected, xid => xid != id) : _.concat(selected, [id]);
			setSelected(newSelected);
		} else {
			selected === id ? setSelected('') : setSelected(id);
		}
	};

	const handleOpenModal = () => {
		setOpen(true);
	};

	const handleConfirmClick = () => {
		const output = getColsFromIds(selected);
		onSelect(output);
		setOpen(false);
	};

	const getInputDisplay = () => {
		const cols = getColsFromIds(selected);
		if (isMultiselect) {
			return cols && cols.length > 0 ? `${cols.length} columns selected` : '';
		} else {
			return cols ? cols.detailedDisplayName : '';
		}
	};

	const simpleTextFilter = (filter, row) => {
		const test = _.get(filter, 'value', '').toLowerCase();
		const rowValue = _.get(row, filter.id, '').toLowerCase();
		return rowValue.includes(test);
	};

	const getColumnConfiguration = () => {
		const configColumns = [
			{
				Header: 'Name',
				accessor: 'displayName',
				minWidth: 220,
				filterMethod: simpleTextFilter,
			},
			{
				Header: 'Calculation',
				accessor: 'calculation',
				minWidth: 100,
				Cell: row => row.value,
				filterMethod: simpleTextFilter,
			},
			{
				Header: 'Column Type',
				accessor: 'rt_columnType',
				minWidth: 160,
				Cell: row => row.value,
				filterMethod: simpleTextFilter,
			},
			{
				Header: 'Data Type',
				accessor: 'rt_dataType',
				minWidth: 100,
				Cell: row => row.value,
				filterMethod: simpleTextFilter,
			},
		];
		if (modalColumns.showEntityName) {
			configColumns.unshift({
				Header: 'Entity Name',
				minWidth: 160,
				accessor: 'entityName',
				Cell: row => row.value,
				filterMethod: simpleTextFilter,
			});
		}
		if (modalColumns.showEntityType) {
			configColumns.unshift({
				Header: 'Entity Type',
				minWidth: 160,
				accessor: 'rt_entityType',
				Cell: row => row.value,
				filterMethod: simpleTextFilter,
			});
		}
		return configColumns;
	};

	return (
		<React.Fragment>
			<KiInput
				label={value ? label : ''}
				placeholder={value ? '' : 'Click here to select column'}
				value={getInputDisplay()}
				onClick={handleOpenModal}
			/>
			<KiModal2
				active={isOpen}
				actions={[
					{label: 'Cancel', onClick: () => setOpen(false)},
					{label: `Select Column${isMultiselect ? 's' : ''}`, onClick: handleConfirmClick},
				]}
				panelStyles={{minHeight: '75%', maxHeight: '75%', minWidth: '80%', maxWidth: '80%'}}
				header={`Pick Column${isMultiselect ? 's' : ''}`}
			>
				<ReactTable
					style={{overflow: 'auto'}}
					className={cx({'-striped': true, '-highlight': true})}
					sortable={true}
					showPagination={false}
					filterable={true}
					minRows={0}
					data={data}
					columns={getColumnConfiguration()}
					defaultPageSize={500}
					NoDataComponent={() => (
						<div
							className="rt-noData"
							style={{
								position: 'fixed',
							}}
						>
							No columns found
						</div>
					)}
					getTrProps={(state, rowInfo) => {
						const isSelected = Array.isArray(selected)
							? selected.includes(rowInfo.original._id)
							: selected === rowInfo.original._id;
						return {
							onClick: () => {
								handleRowClick(rowInfo.original._id);
							},
							className: cx({[`${styles.selected}`]: isSelected, [`${styles.clickable}`]: true}),
						};
					}}
					getTheadTrProps={() => {
						return {style: {overflowY: 'scroll'}};
					}}
					getTheadFilterTrProps={() => {
						return {style: {overflowY: 'scroll'}};
					}}
					getTbodyProps={() => {
						return {style: {overflowY: 'scroll'}};
					}}
				/>
			</KiModal2>
		</React.Fragment>
	);
}

KiColumnSelectModal.propTypes = {
	isMultiselect: PropTypes.bool,
	label: PropTypes.string,
	columnList: PropTypes.array,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	onSelect: PropTypes.func.isRequired,
	modalColumns: PropTypes.shape({
		showEntityType: PropTypes.bool,
		showEntityName: PropTypes.bool,
	}),
};

KiColumnSelectModal.defaultProps = {
	isMultiselect: false,
	label: 'Select Column',
	columnList: [],
	value: [],
	modalColumns: {
		showEntityType: false,
		showEntityName: false,
	},
};

export default KiColumnSelectModal;
