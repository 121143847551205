import {combineReducers} from 'redux';
import User from 'containers/login/reducer';
import AppReducer from './App';
import Calendars from 'containers/calendars/reducer';
import Snackbar from './Snackbar';
import Submission, {CategorySchemas} from '../../containers/submission/reducer';
import Submissions from 'containers/submissions/reducer';
import Accounts from 'containers/accounts/reducer';
import ColumnModal from './ColumnModal';
import DatasetList from 'containers/datasetList/reducer';
import dataExplorer from 'containers/dataExplorerOld/reducer';
import fundingVehicle from 'containers/fundingVehicle/reducer';
import fundingVehicleList from 'containers/fundingVehicleList/reducer';
import dashboards from 'containers/dashboards/reducer';
import dataBookmarks from 'components/FlyoutManageViews/reducer';
import notificationList from 'containers/notificationList/reducer';
import viewForm from 'components/FlyoutManageViews/components/ViewForms/reducer';
import businessFunctions from 'containers/businessFunctions/reducer';
import associatedDataList from 'containers/associatedDataList/reducer';
import associatedDataSchema from 'containers/associatedData/components/Schema/reducer';
import debtExploration from 'containers/debt/reducer';
import eligibilityAnalysis from 'containers/eligibilityAnalysis/reducer';
import datasetDates from 'components/FlyoutDates/reducer';
import debtInputMaintenance from 'containers/debtInputMaintenance/reducer';
import datasetMappings from 'containers/mappings/reducer';
import waterfall from 'containers/dealStructures/reducer';
import ledgerExploration from 'containers/ledgers/reducer';
import ledgerAccountConfiguration from 'containers/ledgers/components/configure/Account/reducer';
import ledgerTemplateConfiguration from 'containers/ledgers/components/configure/Template/reducer';
import forecasting from 'containers/forecasting/reducer';

const rootReducer = combineReducers({
	app: AppReducer,
	categorySchemas: CategorySchemas,
	calendars: Calendars,
	submission: Submission,
	submissions: Submissions,
	snackbar: Snackbar,
	user: User,
	accounts: Accounts,
	columnModal: ColumnModal,
	datasetList: DatasetList,
	dataExplorer,
	fundingVehicle: fundingVehicle,
	fundingVehicleList: fundingVehicleList,
	dashboards,
	dataBookmarks,
	notificationList,
	viewForm,
	businessFunctions,
	associatedDataList,
	associatedDataSchema,
	debtExploration,
	eligibilityAnalysis,
	datasetDates,
	debtInputMaintenance,
	datasetMappings,
	waterfall,
	ledgerExploration,
	ledgerAccountConfiguration,
	ledgerTemplateConfiguration,
	forecasting,
});

export default rootReducer;
